import React from 'react';

import {MuiThemeProvider} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Email from '@material-ui/icons/Email';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
// ------------------------------------------------------------------------------------------------------------------ //
// Theme
// ------------------------------------------------------------------------------------------------------------------ //
import theme from './../../../theme/theme';
// ------------------------------------------------------------------------------------------------------------------ //
// initialize i18n
// ------------------------------------------------------------------------------------------------------------------ //
import {Trans} from "react-i18next";
import i18n from "./../../../libs/i18n";

import ja from './locale/ja';
import en from './locale/en';
// ------------------------------------------------------------------------------------------------------------------ //
// import Validator
// ------------------------------------------------------------------------------------------------------------------ //
import ValidationForm from './../../../libs/ValidationForm';
import {Auth} from "aws-amplify";
import {ErrorMessageProtocol} from "../../base/ErrorMessage/ErrorMessage";
import StorageImage from "../../../libs/StorageImage";

const style = {
    root: {
        [theme.breakpoints.up('sm')]: {
            height: '90vh',
        },
    },
    imageGrid: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '25vh',
            width: '100%',
        },
    },
    image: {
        height: '100%',
        width: '100%',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    social_buttons: {
        marginTop: 30
    },
    container: {
        marginTop: 30,
        marginBottom: 30,
        maxWidth: '80%',
    }
};

i18n.addResourceBundle("en", "translation", en.translation);
i18n.addResourceBundle("ja", "translation", ja.translation);


// ------------------------------------------------------------------------------------------------------------------ //
// component
// ------------------------------------------------------------------------------------------------------------------ //
class ResetPasswordForm extends ValidationForm
{
    constructor(props)
    {
        super();

        if (props.email)
        {
            this.state = {
                error_status: {},
                error_message: {},
                isSubmitEnabled: false,
                isInputNewPassword: false,
                email: props.default.email,
                code: '',
                password: '',
            };
        }
        else
        {
            this.state = {
                error_status: {},
                error_message: {},
                isSubmitEnabled: false,
                isInputNewPassword: false,
                email: "",
                code: '',
                password: '',
            };
        }

        this.handleFormBlur = this.handleFormBlur.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);

        this.FORM = {
            'auth[email]': {
                id: "auth[email]",
                ref: React.createRef(),
                validation: {
                    required: {
                        message: "input_required"
                    },
                    email: {
                        message: "invalid_email_format"
                    }
                }
            },
        }
    }

    handleFormBlur(event)
    {
        if (event.target.type == "checkbox")
        {
            this.merge([this.validate(event.target.name, event.target.checked)]);
        }
        else
        {
            this.merge([this.validate(event.target.name, event.target.value)]);
        }
    }

    handleFormSubmit(event)
    {
        event.preventDefault();
        if (this.state.isInputNewPassword)
            this.forgotPasswordSubmit();
        else
            this.forgotPassword();
    }

    forgotPassword()
    {
        Auth.forgotPassword(this.state.email)
            .then(() =>
            {
                // location.href = this.props.action
                this.setState({isInputNewPassword: true});
            })
            .catch(err =>
            {
                ErrorMessageProtocol.next("error : " + err.message)
            });
    }

    forgotPasswordSubmit()
    {
        Auth.forgotPasswordSubmit(this.state.email, this.state.code, this.state.password)
            .then(() =>
            {
                location.href = this.props.action
            })
            .catch(err =>
            {
                ErrorMessageProtocol.next("error : " + err.message)
            });
    }

    render()
    {
        let action = this.props.action

        if (!this.state.isInputNewPassword)
        {
            return (
                <MuiThemeProvider theme={theme}>
                    <Grid container component="main" className={this.props.classes.root}>
                        <CssBaseline/>
                        <Grid
                            item
                            sm={12}
                            md={8}
                            component={Paper}
                            className={this.props.classes.imageGrid}
                        >
                            <StorageImage
                                params={this.state.customer ? this.state.customer.service_logo : null}
                                default={'/images/sharekan.jpg'}
                                style={{height: '100%', width: '100%', objectFit: 'cover'}}
                            />
                        </Grid>
                        <Grid item sm={12} md={4} component={Paper} square>

                            <Container component="main" maxWidth="xs" className={this.props.classes.container}>
                                <Avatar className={this.props.classes.avatar}>
                                    <Email/>
                                </Avatar>

                                <Typography component="h1" variant="h5">
                                    {i18n.t("forgot_password")}
                                </Typography>

                                <div className={this.props.classes.instruction}>登録時に使用したメールアドレスを入力してください。パスワード再設定をご案内するメールを受け取ることができます。</div>

                                <form className={this.props.classes.form}
                                      action={action}
                                      method="POST"
                                      onSubmit={this.handleFormSubmit}
                                      noValidate>
                                    <input name="utf8" type="hidden" value="✓"/>
                                    <input type="hidden" name="authenticity_token" value={this.props.authenticity_token}/>

                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="auth[email]"
                                        name="auth[email]"
                                        label={i18n.t("email_address")}

                                        autoComplete="email"
                                        autoFocus

                                        value={this.state.email}
                                        onChange={e => this.setState({email: e.target.value})}
                                        onBlur={this.handleFormBlur}
                                        error={this.state.error_status[this.FORM["auth[email]"].id]}
                                        helperText={this.state.error_message[this.FORM["auth[email]"].id]}
                                        inputRef={this.FORM["auth[email]"].ref}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={this.props.classes.submit}
                                        style={{marginTop: '20px'}}
                                        disabled={!this.state.isSubmitEnabled}>
                                        <Trans i18nKey={"send_mail"}/>
                                    </Button>
                                    <Grid container>
                                        <Grid item xs>
                                        </Grid>
                                        <Grid item>
                                            <Link href={this.props.sign_up_path} variant="body2">
                                                {i18n.t("sign_up")}
                                            </Link>
                                        </Grid>
                                    </Grid>

                                </form>
                            </Container>
                        </Grid>
                    </Grid>
                </MuiThemeProvider>
            )
        }
        else
        {
            return (
                <MuiThemeProvider theme={theme}>
                    <Grid container component="main" className={this.props.classes.root}>
                        <CssBaseline/>
                        <Grid item xs={false} sm={4} md={7} className={this.props.classes.image}/>
                        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>

                            <Container component="main" maxWidth="xs" className={this.props.classes.container}>
                                <Avatar className={this.props.classes.avatar}>
                                    <Email/>
                                </Avatar>

                                <Typography component="h1" variant="h5">
                                    {i18n.t("forgot_password")}
                                </Typography>

                                <div className={this.props.classes.instruction}>メールアドレス宛に確認コードを送信しました。<br/>確認コードと新しく設定するパスワードを入力してください。</div>

                                <form className={this.props.classes.form}
                                      action={action}
                                      method="POST"
                                      onSubmit={this.handleFormSubmit}
                                      noValidate>
                                    <input name="utf8" type="hidden" value="✓"/>
                                    <input type="hidden" name="authenticity_token" value={this.props.authenticity_token}/>

                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="code"
                                        label={i18n.t('code')}
                                        type="code"
                                        id="code"
                                        autoComplete="current-password"

                                        onChange={e => this.setState({code: e.target.value})}
                                        onBlur={this.handleFormBlur}
                                    />

                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label={i18n.t('password')}
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"

                                        onChange={e => this.setState({password: e.target.value})}
                                        onBlur={this.handleFormBlur}
                                    />

                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={this.props.classes.submit}
                                        style={{marginTop: '20px'}}
                                        disabled={!this.state.isSubmitEnabled}>
                                        <Trans i18nKey={"change_password"}/>
                                    </Button>
                                    <Grid container>
                                        <Grid item xs>
                                        </Grid>
                                        <Grid item>
                                            <Link href={this.props.sign_up_path} variant="body2">
                                                {i18n.t("sign_up")}
                                            </Link>
                                        </Grid>
                                    </Grid>

                                </form>
                            </Container>
                        </Grid>
                    </Grid>
                </MuiThemeProvider>
            )
        }

    }
}

export default withStyles(style)(ResetPasswordForm);
