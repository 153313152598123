let ja = {
    "translation" : {
        "mail_password_registration" : "入力",
        "or" : "または",
        "social_account_registation" : "ソーシャルアカウントで登録",
        "confirmation" : "確認",
        "registration_done" : "登録完了"
    }
};

export default ja;