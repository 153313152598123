let en = {
    "translation" : {
        "welcome": "Welcome",
        "mailaddress" : "Mail address",
        "password" : "Password",
        "password_confirmation" : "Password confirmation",
        "agreement" : "agreement",
        "signup" : "Registration"

    }
};

export default en;
