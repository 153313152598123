import React from 'react';

import {MuiThemeProvider} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autorenew from '@material-ui/icons/Autorenew';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// ------------------------------------------------------------------------------------------------------------------ //
// Theme
// ------------------------------------------------------------------------------------------------------------------ //
import theme from './../../../theme/theme';
// ------------------------------------------------------------------------------------------------------------------ //
// initialize i18n
// ------------------------------------------------------------------------------------------------------------------ //
import {Trans} from "react-i18next";
import i18n from "./../../../libs/i18n";

import ja from './locale/ja';
import en from './locale/en';
// ------------------------------------------------------------------------------------------------------------------ //
// import Validator
// ------------------------------------------------------------------------------------------------------------------ //
import ValidationForm from './../../../libs/ValidationForm';
import StorageImage from "../../../libs/StorageImage";

const style = {
    root: {
        [theme.breakpoints.up('sm')]: {
            height: '90vh',
        },
    },
    imageGrid: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '25vh',
            width: '100%',
        },
    },
    image: {
        height: '100%',
        width: '100%',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    social_buttons: {
        marginTop: 30
    },
    container: {
        marginTop: 30,
        marginBottom: 30,
        maxWidth: '80%',
    }
};

i18n.addResourceBundle("en", "translation", en.translation);
i18n.addResourceBundle("ja", "translation", ja.translation);


// ------------------------------------------------------------------------------------------------------------------ //
// component
// ------------------------------------------------------------------------------------------------------------------ //
class ChangePasswordForm extends ValidationForm
{
    constructor(props)
    {
        super();

        if( props.email )
        {
            this.state = {
                error_status: {},
                error_message: {},
                isSubmitEnabled: false,
                email: props.default.email
            };
        }
        else
        {
            this.state = {
                error_status: {},
                error_message: {},
                isSubmitEnabled: false,
                email: ""
            };
        }

        this.handleFormBlur = this.handleFormBlur.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);

        this.FORM = {
            'auth[password]': {
                id: "auth[password]",
                ref: React.createRef(),
                validation: {
                    required: {
                        message: "input_required"
                    },
                    length: {
                        morethan: 8,
                        message: "required_morethan_length_8"
                    },
                    password_confirmation: {
                        target: "password_confirmation",
                        message: "password_confirmation_failed"
                    }
                }
            },
            'auth[password_confirmation]': {
                id: "auth[password_confirmation]",
                ref: React.createRef(),
                validation: {
                    required: {
                        message: "input_required"
                    },
                    length: {
                        morethan: 8,
                        message: "required_morethan_length_8"
                    },
                    password_confirmation: {
                        target: "password",
                        message: "password_confirmation_failed"
                    }
                }
            },
        }
    }

    handleFormBlur(event)
    {
        if( event.target.type == "checkbox" )
        {
            this.merge( [ this.validate(event.target.name, event.target.checked) ] );
        }
        else
        {
            this.merge( [ this.validate(event.target.name, event.target.value) ] );
        }
    }

    handleFormSubmit(event)
    {
        if( !this.beforeSubmit() )
        {
            event.preventDefault();
        }
    }

    render()
    {
        let action = "/auth/password";

        return (
            <MuiThemeProvider theme={theme}>
                <Grid container component="main" className={this.props.classes.root}>
                    <CssBaseline />
                    <Grid
                        item
                        sm={12}
                        md={8}
                        component={Paper}
                        className={this.props.classes.imageGrid}
                    >
                        <StorageImage
                            params={this.state.customer ? this.state.customer.service_logo : null}
                            default={'/images/sharekan.jpg'}
                            style={{height: '100%', width: '100%', objectFit: 'cover'}}
                        />
                    </Grid>
                    <Grid item sm={12} md={4} component={Paper} square>

                        <Container component="main" maxWidth="xs" className={this.props.classes.container}>
                            <Avatar className={this.props.classes.avatar}>
                                <Autorenew />
                            </Avatar>

                            <Typography component="h1" variant="h5">
                                {i18n.t("change_password")}
                            </Typography>

                            <div className={this.props.classes.instruction}>{i18n.t("change_password_instruction")}</div>

                            <form className={this.props.classes.form}
                                  action={action}
                                  method="POST"
                                  onSubmit={this.handleFormSubmit}
                                  noValidate>
                                <input name="_method" type="hidden" value="put" />
                                <input name="utf8" type="hidden" value="✓"/>
                                <input type="hidden" name="authenticity_token" value={this.props.authenticity_token} />
                                <input type="hidden" name="auth[reset_password_token]" value={this.props.reset_password_token}/>

                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth

                                    id="auth[password]"
                                    name="auth[password]"
                                    label={i18n.t('password')}
                                    type="password"

                                    autoComplete="current-password"

                                    onChange={ e => this.setState({ password: e.target.value }) }
                                    onBlur={this.handleFormBlur}
                                    error= {this.state.error_status[this.FORM["auth[password]"].id]}
                                    helperText={this.state.error_message[this.FORM["auth[password]"].id]}
                                    inputRef={this.FORM["auth[password]"].ref}
                                />

                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth

                                    id="auth[password_confirmation]"
                                    name="auth[password_confirmation]"
                                    label={i18n.t('password_confirmation')}
                                    type="password"

                                    autoComplete="current-password"
                                    onChange={ e => this.setState({ password_confirmation: e.target.value }) }
                                    onBlur={this.handleFormBlur}
                                    error= {this.state.error_status[this.FORM["auth[password_confirmation]"].id]}
                                    helperText={this.state.error_message[this.FORM["auth[password_confirmation]"].id]}
                                    inputRef={this.FORM["auth[password_confirmation]"].ref}
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={this.props.classes.submit}
                                    style={{marginTop: '20px'}}
                                    disabled={!this.state.isSubmitEnabled} >
                                    <Trans i18nKey={"execute_change_passoword"} />
                                </Button>
                            </form>

                        </Container>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(style)(ChangePasswordForm);
