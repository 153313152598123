let ja = {
    "translation" : {
        "title": "確認コード",
        "code" : "確認コード",
        "submit" : "認証する",

        "input_required": "入力は必須です。",
        "agreement_required": "ご利用には利用規約の同意が必要です。",
        "invalid_email_format": "メールアドレスの形式ではありません。",
        "required_morethan_length_8": "パスワードは８文字以上が必要です。",
        "password_confirmation_failed": "入力された２つのパスワードが一致しません。"
    }
};

export default ja;
