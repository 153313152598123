import React from 'react';

import {MuiThemeProvider} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline'

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
// ------------------------------------------------------------------------------------------------------------------ //
// Theme
// ------------------------------------------------------------------------------------------------------------------ //
import theme from './../../theme/theme';
// ------------------------------------------------------------------------------------------------------------------ //
// initialize i18n
// ------------------------------------------------------------------------------------------------------------------ //
import i18n from "./../../libs/i18n";

import ja from './locale/ja';
import en from './locale/en';

const style = {
    root: {},
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
};

i18n.addResourceBundle("en", "translation", en.translation);
i18n.addResourceBundle("ja", "translation", ja.translation);

// ------------------------------------------------------------------------------------------------------------------ //
// component
// ------------------------------------------------------------------------------------------------------------------ //
class RegistrationStep extends React.Component
{
    constructor(props)
    {
        super();
    }


    render()
    {
        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline/>
                <div className={this.props.classes.root}>
                    <Stepper activeStep={this.props.step} alternativeLabel>
                        <Step key="1">
                            <StepLabel>{i18n.t("mail_password_registration")}</StepLabel>
                        </Step>
                        <Step key="2">
                            <StepLabel>{i18n.t("confirmation")}</StepLabel>
                        </Step>
                        <Step key="3">
                            <StepLabel>{i18n.t("registration_done")}</StepLabel>
                        </Step>
                    </Stepper>
                </div>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(style)(RegistrationStep);
