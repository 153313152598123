/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createReservation = /* GraphQL */ `
  mutation CreateReservation($input: CreateReservationInput!) {
    createReservation(input: $input) {
      id
      customer_id
      order_id
      edit_account_id
    }
  }
`;
export const updateReservation = /* GraphQL */ `
  mutation UpdateReservation($input: UpdateReservationInput!) {
    updateReservation(input: $input) {
      id
      customer_id
      order_id
      edit_account_id
    }
  }
`;
export const deleteReservation = /* GraphQL */ `
  mutation DeleteReservation($input: DeleteReservationInput!) {
    deleteReservation(input: $input) {
      id
      customer_id
      order_id
      edit_account_id
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      id
      customer_id
      channel
      sender_type
      sender_id
      body
      message_type
      image_base64
      send_at
      createdAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage($input: UpdateMessageInput!) {
    updateMessage(input: $input) {
      id
      customer_id
      channel
      sender_type
      sender_id
      body
      message_type
      image_base64
      send_at
      createdAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage($input: DeleteMessageInput!) {
    deleteMessage(input: $input) {
      id
      customer_id
      channel
      sender_type
      sender_id
      body
      message_type
      image_base64
      send_at
      createdAt
    }
  }
`;
