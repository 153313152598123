let ja = {
    "translation" : {
        "notified_title" : "パスワード変更の案内を送信しました",
        "notified_instruction" : "入力されたメールアドレス宛てに、パスワード変更の案内を送信しました。メール記載の案内にしたがってパスワードの変更を行うことができます。",

        "changed_title" : "パスワード変更の完了",
        "changed_instruction" : "パスワード変更が完了しました。引き続きサービスをご利用ください。"
    }
};

export default ja;
