import React from 'react';

import {MuiThemeProvider} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline'
import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Navigation';

import * as SharekanStatic from '../../SharekanStatic'
// ------------------------------------------------------------------------------------------------------------------ //
// Theme
// ------------------------------------------------------------------------------------------------------------------ //
import theme from './../../../theme/theme';
// ------------------------------------------------------------------------------------------------------------------ //
// initialize i18n
// ------------------------------------------------------------------------------------------------------------------ //
import i18n from "./../../../libs/i18n";

import ja from './locale/ja';
import en from './locale/en';
import {API, Auth} from "aws-amplify";
import Toolkit from "../../../libs/Toolkit";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const style = {
    reserveButton: {
        marginTop: "20px",
        textAlign: "center"
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
};

i18n.addResourceBundle("en", "translation", en.translation);
i18n.addResourceBundle("ja", "translation", ja.translation);

// ------------------------------------------------------------------------------------------------------------------ //
// component
// ------------------------------------------------------------------------------------------------------------------ //
class ReserveButton extends React.Component
{
    constructor(props)
    {
        super();
        this.state = {
            onClick: props.onClick,
            loading: false,
            email: "",
        };
    }

    signIn(form)
    {
        let cookie_email = Toolkit.getCookie(SharekanStatic.COOKIE_KEY_LOGIN_EMAIL);
        let cookie_password = Toolkit.getCookie(SharekanStatic.COOKIE_KEY_LOGIN_PASSWORD);
        if (this.props.authenticity_token && cookie_email && cookie_password)
        {
            this.setState({loading: true});
            document.getElementById('email').setAttribute('value', cookie_email);
            document.getElementById('password').setAttribute('value', cookie_password);
            Auth.signIn(cookie_email, cookie_password)
                .then(val =>
                {
                    console.log(val);
                    this.checkToken(form);
                })
                .catch(err =>
                {
                    console.log("error : " + err);
                    form.submit();
                });
        }
        else
        {
            location.href = this.props.signin_path;
        }
    }

    checkToken(form)
    {
        Auth.currentSession()
            .then(session =>
            {
                const request = {
                    headers: {
                        Authorization: session.getIdToken().getJwtToken()
                    }
                };

                API.get('sharekanGateway', '/check', request)
                    .then(val =>
                    {
                        console.log(val);
                        document.getElementById('token').setAttribute('value', session.getIdToken().getJwtToken());
                        form.submit()
                    })
                    .catch(err =>
                    {
                        console.log("error : " + err);
                        form.submit();
                    });
            })
            .catch(err =>
            {
                console.log("error : " + err);
                form.submit();
            });
    }

    render()
    {
        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline/>
                <div className={this.props.classes.reserveButton}>
                    <Fab variant="extended"
                         color="primary"
                         aria-label="add"
                         className={this.props.classes.margin}
                         onClick={e =>
                         {
                             if(this.state.onClick)
                                 this.state.onClick();

                             if (this.props.signin_path)
                             {
                                 this.signIn(document.getElementById('auth_form'));
                             }
                             else
                             {
                                 location.href = '/';
                             }
                         }}
                    >
                        <NavigationIcon className={this.props.classes.extendedIcon}/>
                        {i18n.t(this.props.message)}
                    </Fab>
                    <form id={"auth_form"}
                          action={this.props.signin_path}
                          method="POST"
                          noValidate>
                        <input name="utf8" type="hidden" value="✓"/>
                        <input type="hidden" name="authenticity_token" value={this.props.authenticity_token}/>
                        <input type="hidden" id="email" name="email"/>
                        <input type="hidden" id="password" name="password"/>
                        <input type="hidden" id="token" name="token"/>
                    </form>
                    <Backdrop open={this.state.loading} style={{zIndex: 10000}}>
                        <CircularProgress size={96} color="inherit"/>
                    </Backdrop>
                </div>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(style)(ReserveButton);
