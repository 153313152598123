import React from 'react';

import {MuiThemeProvider} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline'
// ------------------------------------------------------------------------------------------------------------------ //
// Theme
// ------------------------------------------------------------------------------------------------------------------ //
import theme from './../../../theme/theme';
// ------------------------------------------------------------------------------------------------------------------ //
// initialize i18n
// ------------------------------------------------------------------------------------------------------------------ //
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const style = {
};

// ------------------------------------------------------------------------------------------------------------------ //
// component
// ------------------------------------------------------------------------------------------------------------------ //
class BasicFooter extends React.Component
{
    constructor(props)
    {
        super();
    }

    render()
    {
        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <Box style={{margin: '35px 0'}}>
                    <Typography variant="body2" color="textSecondary" align="center">
                        {'(C) Sharekan'}
                    </Typography>
                </Box>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(style)(BasicFooter);
