let ja = {
    "translation" : {
        "welcome": "ご利用登録",
        "mailaddress" : "メールアドレス",
        "password" : "パスワード",
        "password_confirmation" : "パスワード入力確認",
        "agreement" : "利用規約に同意する",
        "signup" : "登録",
        "resend" : "確認コードを再送する",

        "input_required": "入力は必須です。",
        "agreement_required": "ご利用には利用規約及びプライバシーポリシーの同意が必要です。",
        "invalid_email_format": "メールアドレスの形式ではありません。",
        "required_morethan_length_8": "パスワードは８文字以上が必要です。",
        "password_confirmation_failed": "入力された２つのパスワードが一致しません。"
    }
};

export default ja;
