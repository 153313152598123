import i18n from "./i18n";

export default class Validator
{
    constructor( myself )
    {
        this.myself = myself;
        this.is_valid = true;
        this.error_message = "";
    }

    result()
    {
        let error_status = this.is_valid;
        let error_message = this.error_message;

        this.myself = null;

        return { error_status, error_message };
    }

    /**
     * 入力必須
     * @param field
     * @param value
     * @returns {Validator}
     */
    checkRequired( field, value )
    {
        console.log("[checkRequired] field="+field+", value="+value);

        let is_valid = true;
        let message = "";

        if( this.myself.FORM[field].validation.required!==undefined )
        {
            if(value===undefined || !value)
            {
                is_valid = false;
                message = i18n.t(this.myself.FORM[field].validation.required.message)
            }
        }

        this.is_valid &= is_valid;
        this.error_message += message;

        return this;
    }

    /**
     * メールアドレスフォーマット制限
     * @param field
     * @param value
     * @returns {Validator}
     */
    checkEmail( field, value )
    {
        let is_valid = true;
        let message = "";

        if( this.myself.FORM[field].validation.email!==undefined )
        {
            if( !/^[A-Za-z0-9]{1}[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/.test(value) )
            {
                is_valid = false;
                message = i18n.t(this.myself.FORM[field].validation.email.message);
            }
        }

        this.is_valid &= is_valid;
        this.error_message += message;

        return this;
    }

    /**
     * 入力長制限
     * @param field
     * @param value
     * @returns {Validator}
     */
    checkLength( field, value )
    {
        let is_valid = true;
        let message = "";

        if( this.myself.FORM[field].validation.length!==undefined )
        {
            if( !value || value.length < this.myself.FORM[field].validation.length.morethan )
            {
                is_valid = false;
                message = i18n.t(this.myself.FORM[field].validation.length.message);
            }
        }

        this.is_valid &= is_valid;
        this.error_message += message;

        return this;
    }

    /**
     * パスワード入力一致確認
     * @param field
     * @param value
     * @returns {Validator}
     */
    checkPasswordConfirmation( field, value )
    {
        let is_valid = true;
        let message = "";

        if( this.myself.FORM[field].validation.password_confirmation!==undefined )
        {
            let targetName = this.myself.FORM[field].validation.password_confirmation.target;
            let targetValue = this.myself.state[targetName];

            if( value !== targetValue )
            {
                is_valid = false;
                message = i18n.t(this.myself.FORM[field].validation.password_confirmation.message);
            }
        }

        this.is_valid &= is_valid;
        this.error_message += message;

        return this;
    }

}