import React from "react";
import Validator from "./Validator";

export default class ValidationForm extends React.Component
{
    merge( result_list )
    {
        let append_error_status = {};
        let append_error_message = {};
        for( let resultEntry of result_list )
        {
            append_error_status[resultEntry.field] = !resultEntry.error_status;
            append_error_message[resultEntry.field] = resultEntry.error_message;
            console.log("[merge] - resultEntry="+JSON.stringify(resultEntry) );
            console.log("[merge] - resultEntry.error_status="+resultEntry.error_status+", resultEntry.error_message="+resultEntry.error_message );
        }

        this.setState({
            error_status: {
                ...this.state.error_status,
                ...append_error_status
            },
            error_message: {
                ...this.state.error_message,
                ...append_error_message
            },
        }, () =>
        {
            this.setState({
                isSubmitEnabled : !this.hasError()
            });

            console.log("[merge]    > result "+JSON.stringify( this.state ) );
            console.log("[merge]    > isSubmitEnabled "+this.state.isSubmitEnabled );

        });

    }

    hasError()
    {
        // 未バリデーションあり
        for( let form in this.FORM )
        {
            let formItem = this.FORM[form];

            if( Object.values(formItem.validation).length >0 /* バリデーション設定が存在する？ */ )
            {
                console.log("[hasError] check_status="+formItem.id+", error_status="+this.state.error_status[formItem.id] );
                if( this.state.error_status[formItem.id]===undefined )
                {
                    console.log("[hasError]    > not yet validate : "+formItem.id );
                    return true;
                }
            }
        }

        // 未バリデーションなし→エラーチェック
        let hasError = false;
        for( let error in this.state.error_status )
        {
            console.log("[hasError]       >  ["+error+"]="+this.state.error_status[error]);

            hasError |= this.state.error_status[error]
        }

        console.log("[hasError]       >  result = "+hasError);

        return hasError;
    }


    beforeSubmit()
    {
        console.log("[beforeSubmit] ---------------------------------------------------------------------------------");
        console.log("[beforeSubmit] "+JSON.stringify(this.state));

        let results = [];
        for( let form in this.FORM )
        {
            let formItem = this.FORM[form];
            let value = formItem.ref.current.value;

            if(value !== undefined)
            {
                console.log("[beforeSubmit] form="+form+", id="+formItem.id+", value="+value+", ref="+formItem.ref.current.valueOf());

                results.push( this.validate( formItem.id, value ) );
            }
        }
        this.merge( results );

        let isNoErrors = Object.values(this.state.error_status).every(error => !error);
        console.log("[beforeSubmit] isNoErrors="+isNoErrors);

        return isNoErrors;
    }

    validate(field, value)
    {
        var validate_result = new Validator( this )
            .checkRequired( field, value )
            .checkEmail( field, value )
            .checkLength( field, value )
            .checkPasswordConfirmation( field, value )
            .result();

        return {
            field: field,
            value: value,
            error_status: validate_result.error_status,
            error_message: validate_result.error_message
        };
    }

}
