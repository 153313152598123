import React from 'react';

import {MuiThemeProvider} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import InfoIcon from '@material-ui/icons/Info'

import {green, red} from '@material-ui/core/colors';

import CssBaseline from '@material-ui/core/CssBaseline'
// ------------------------------------------------------------------------------------------------------------------ //
// Theme
// ------------------------------------------------------------------------------------------------------------------ //
import theme from './../../../theme/theme';
// ------------------------------------------------------------------------------------------------------------------ //
// initialize i18n
// ------------------------------------------------------------------------------------------------------------------ //
import i18n from "./../../../libs/i18n";

import ja from './locale/ja';
import en from './locale/en';

const style = {
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        padding: theme.spacing(0, 3),
    },
    icon_area: {
        textAlign: "center",
        marginBottom: 10,
    },
    icon_checkcircle: {
        color: green[500],
    },
    icon_info: {
        color: red[500],
    },
    paper: {
        maxWidth: 600,
        marginTop: 30,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },
};

i18n.addResourceBundle("en", "translation", en.translation);
i18n.addResourceBundle("ja", "translation", ja.translation);

// ------------------------------------------------------------------------------------------------------------------ //
// component
// ------------------------------------------------------------------------------------------------------------------ //
class SimpleMessageTile extends React.Component
{
    constructor(props)
    {
        super();
    }

    render()
    {
        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                    <div className={this.props.classes.root}>
                        <Paper className={this.props.classes.paper}>
                            <div className={this.props.classes.icon_area} >
                                { this.props.icon === "CheckCircleIcon"
                                    ? <CheckCircleIcon color="primary" className={this.props.classes.icon_checkcircle} fontSize={"large"}/>
                                    : <InfoIcon color="primary" className={this.props.classes.icon_info} fontSize={"large"}/> }
                            </div>

                            <Grid container wrap="nowrap" spacing={2}>
                                <Grid item xs>
                                    <Typography>{i18n.t(this.props.message)}</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </div>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(style)(SimpleMessageTile);
