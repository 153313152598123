import React from 'react';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {MuiThemeProvider} from '@material-ui/core/styles';

import axios from 'axios';
import {csrfToken} from 'rails-ujs';

import {ErrorMessageProtocol} from "../../base/ErrorMessage/ErrorMessage";


import Amplify, {Auth} from "aws-amplify";
import config from "../../../../../src/aws-exports";
// ------------------------------------------------------------------------------------------------------------------ //
// Theme
// ------------------------------------------------------------------------------------------------------------------ //
import theme from './../../../theme/theme';
// ------------------------------------------------------------------------------------------------------------------ //
// initialize i18n
// ------------------------------------------------------------------------------------------------------------------ //
import {Trans} from "react-i18next";
import i18n from "./../../../libs/i18n";

import ja from './locale/ja';
import en from './locale/en';
// ------------------------------------------------------------------------------------------------------------------ //
// import Validator
// ------------------------------------------------------------------------------------------------------------------ //
import ValidationForm from './../../../libs/ValidationForm';
import CircularProgress from "@material-ui/core/CircularProgress";

Amplify.configure(config);

i18n.addResourceBundle("en", "translation", en.translation);
i18n.addResourceBundle("ja", "translation", ja.translation);

// ------------------------------------------------------------------------------------------------------------------ //
// component
// ------------------------------------------------------------------------------------------------------------------ //
export default class ConfirmCodeForm extends ValidationForm
{
    constructor(props)
    {
        super();
        this.state = {
            error_status: {},
            error_message: {},
            isSubmitEnabled: false,
            code: "",
            email: props.default.email,
        };

        this.handleFormBlur = this.handleFormBlur.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);

        axios.defaults.headers.common['X-CSRF-Token'] = csrfToken();

        this.FORM = {
            code: {
                id: "code",
                ref: React.createRef(),
                validation: {
                    required: {
                        message: "input_required"
                    },
                }
            }
        };
    }

    handleFormBlur(event)
    {
        this.merge([this.validate(event.target.name, event.target.value)]);
    }

    handleFormSubmit(event)
    {
        event.preventDefault();
        if (this.beforeSubmit())
            this.confirmSignUp();
    }

    confirmSignUp()
    {
        this.setState({loading: true});
        Auth.confirmSignUp(this.state.email, this.state.code)
            .then(() =>
            {
                location.href = this.props.action
            })
            .catch(err =>
            {
                console.log(err);
                if (err.code === 'NotAuthorizedException')
                {
                    ErrorMessageProtocol.next("既に登録済みのメールアドレスです。");
                }
                else if(err.code === 'CodeMismatchException')
                {
                    ErrorMessageProtocol.next("確認コードが間違っています。");
                }
                else
                {
                    ErrorMessageProtocol.next("不明なエラー : " + err.message);
                }
                this.setState({loading: false});
            });
    }

    render()
    {
        return (
            <MuiThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline/>
                    <Typography component="h1"
                                variant="h5"
                                style={{marginTop: '20px'}}>
                        <Trans i18nKey="title"></Trans>
                    </Typography>
                    <form
                        method="POST"
                        onSubmit={this.handleFormSubmit}
                        noValidate>
                        <input type="hidden" name="authenticity_token" value={this.props.authenticity_token}/>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            type="text"
                            fullWidth
                            id="code"
                            label={i18n.t('code')}
                            name="code"
                            autoFocus

                            value={this.state.code}
                            onChange={e =>
                            {
                                this.setState({code: e.target.value});
                                this.handleFormBlur(e);
                            }}
                            error={this.state.error_status[this.FORM.code.id]}
                            helperText={this.state.error_message[this.FORM.code.id]}
                            inputRef={this.FORM.code.ref}
                        />
                        <Button type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{marginTop: '20px'}}
                                disabled={!this.state.isSubmitEnabled || this.state.loading}>
                            <Trans i18nKey="submit"></Trans>
                            {this.state.loading && <CircularProgress size={24}/>}
                        </Button>
                    </form>
                </Container>
            </MuiThemeProvider>
        );
    }
}
