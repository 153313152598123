let ja = {
    "translation" : {
        "forgot_password" : "パスワードの再設定",
        "email_address": "メールアドレス",
        "send_mail": "パスワード再設定メールを送る",
        "change_password": "パスワードを変更",
        "code": "確認コード",
        "password": "パスワード",
    }
};

export default ja;
