import React from 'react';
import Toolkit from "./Toolkit";

// ------------------------------------------------------------------------------------------------------------------ //
// component
// ------------------------------------------------------------------------------------------------------------------ //
class StorageImage extends React.Component
{

    constructor(props)
    {
        super();

        this.state = {
            params: this.parseParams(props.params),
            style: props.style,
            alt: props.alt,
            default: props.default ? props.default : '',

            onLoad: props.onLoad,
        };
    }

    componentDidMount()
    {
        Toolkit.getStorageContent(this.state.params, _src => this.setState({src: _src ? _src : this.state.default}));
    }

    componentWillReceiveProps(nextProps)
    {
        let params = this.parseParams(nextProps.params);

        if (params && params.key)
        {
            if (
                !this.state.params ||
                this.state.params.key !== params.key
            )
            {
                Toolkit.getStorageContent(params, _src => this.setState({src: _src ? _src : this.state.default}));
            }
        }

        this.setState({
            params: params,
            style: nextProps.style,
            alt: nextProps.alt,
            default: nextProps.default ? nextProps.default : '',

            onLoad: nextProps.onLoad,
        });
    }

    parseParams(params)
    {
        let p = params;
        if (typeof params === 'string')
            try
            {
                p = JSON.parse(params);
            } catch (e)
            {
                p = null;
            }

        return p;
    }


    // -------------------------------------------------------------------------------------------------------------- //
    // render
    // -------------------------------------------------------------------------------------------------------------- //
    render()
    {
        return (
            <img
                src={this.state.src}
                alt={this.state.alt}
                style={this.state.style}
                onLoad={this.state.onLoad}
            />
        );
    }
}

export default StorageImage;
