/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:5fc173df-fe1b-4eae-8751-f0978c47c795",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_5IdqOOgre",
    "aws_user_pools_web_client_id": "7eurc5uc4gmm5gi0nmhcoool0q",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://r4hn7dxl7zdhlglzo3vcpbij4i.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "sharekanGateway",
            "endpoint": "https://nvtykd6ht0.execute-api.ap-northeast-1.amazonaws.com/prod",
            "region": "ap-northeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "sharekan958fb700f36f4321b73b6cd166009f14-prod",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
