import React from 'react';

import {MuiThemeProvider} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider'

import * as SharekanStatic from '../../SharekanStatic'

import Amplify, {API, Auth} from "aws-amplify";
import config from "../../../../../src/aws-exports";
// ------------------------------------------------------------------------------------------------------------------ //
// Theme
// ------------------------------------------------------------------------------------------------------------------ //
import theme from './../../../theme/theme';
// ------------------------------------------------------------------------------------------------------------------ //
// initialize i18n
// ------------------------------------------------------------------------------------------------------------------ //
import {Trans} from "react-i18next";
import i18n from "./../../../libs/i18n";

import ja from './locale/ja';
import en from './locale/en';
// ------------------------------------------------------------------------------------------------------------------ //
// import Validator
// ------------------------------------------------------------------------------------------------------------------ //
import ValidationForm from './../../../libs/ValidationForm';
import {FacebookLoginButton, GoogleLoginButton, TwitterLoginButton} from "react-social-login-buttons";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolkit from "../../../libs/Toolkit";
import StorageImage from "../../../libs/StorageImage";

Amplify.configure(config);

const style = theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            height: '90vh',
        },
    },
    imageGrid: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '25vh',
            width: '100%',
        },
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    social_buttons: {
        marginTop: 30
    },
    container: {
        marginTop: 30,
        marginBottom: 30,
        maxWidth: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '80%',
        },
    }
});

i18n.addResourceBundle("en", "translation", en.translation);
i18n.addResourceBundle("ja", "translation", ja.translation);

// ------------------------------------------------------------------------------------------------------------------ //
// component
// ------------------------------------------------------------------------------------------------------------------ //
class SignInForm extends ValidationForm
{
    constructor(props)
    {
        super();
        this.FORM = {
            email: {
                id: "email",
                ref: React.createRef(),
                validation: {
                    required: {
                        message: "input_required"
                    },
                    email: {
                        message: "invalid_email_format"
                    }
                }
            },
            password: {
                id: "password",
                ref: React.createRef(),
                validation: {
                    required: {
                        message: "input_required"
                    }
                }
            },
            remember_me: {
                id: "remember_me",
                ref: React.createRef(),
                validation: {}
            },

        };

        this.state = {
            error_status: {},
            error_message: {},
            isSubmitEnabled: false,
            loading: false,
            email: "",
            code: props.code,
            customer: null,
        };

        console.log("default email : " + props.email);
        if (props.email)
        {
            this.state = {
                ...this.state,
                email: props.email
            };
        }

        let cookie_email = Toolkit.getCookie(SharekanStatic.COOKIE_KEY_LOGIN_EMAIL);
        console.log(SharekanStatic.COOKIE_KEY_LOGIN_EMAIL + " : " + cookie_email);
        if (cookie_email)
        {
            this.state = {
                ...this.state,
                email: cookie_email
            };
        }

        let cookie_remember = Toolkit.getCookie(SharekanStatic.COOKIE_KEY_LOGIN_REMEMBER);
        console.log(SharekanStatic.COOKIE_KEY_LOGIN_REMEMBER + " : " + cookie_remember);
        if (cookie_remember)
        {
            this.state = {
                ...this.state,
                remember_me: true
            };
        }

        if (props.customer)
        {
            if (props.customer.service_name) history.replaceState('', '', '/' + props.customer.service_name);

            this.state = {
                ...this.state,
                customer: props.customer
            };
        }

        // Toolkit.setCookie(SharekanStatic.COOKIE_KEY_OMNIAUTH_PATH, props.is_frontend ? 'frontend_sign_in' : 'backend_sign_in');
        sessionStorage.setItem(SharekanStatic.COOKIE_KEY_OMNIAUTH_PATH, props.is_frontend ? 'frontend_sign_in' : 'backend_sign_in');

        this.handleFormBlur = this.handleFormBlur.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    componentDidMount()
    {
        if (this.state.code)
        {
            this.signInBySocial(this.state.code)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.state.update_input)
        {
            let validates = [];
            for (let key in this.FORM)
                validates.push(this.validate(key, this.state[key]));
            this.merge(validates);
            this.setState({
                update_input: false
            });
        }
    }

    handleFormBlur(event)
    {
        // if (event.target.type === "checkbox")
        // {
        //     this.merge([this.validate(event.target.name, event.target.checked)]);
        // }
        // else
        // {
        //     this.merge([this.validate(event.target.name, event.target.value)]);
        // }
    }

    handleFormSubmit(event)
    {
        event.preventDefault();
        if (this.beforeSubmit())
        {
            if (this.state.remember_me)
            {
                Toolkit.setCookie(SharekanStatic.COOKIE_KEY_LOGIN_EMAIL, this.state.email);
                Toolkit.setCookie(SharekanStatic.COOKIE_KEY_LOGIN_REMEMBER, "true");
            }
            else
            {
                Toolkit.deleteCookie(SharekanStatic.COOKIE_KEY_LOGIN_EMAIL);
                Toolkit.deleteCookie(SharekanStatic.COOKIE_KEY_LOGIN_REMEMBER);
            }
            this.signIn(event.target);
        }
    }

    signInBySocial(code)
    {
        let form = document.getElementById('auth_form');
        Toolkit.request(
            this,
            '/api/get_auth_data',
            {
                code: code,
            },
            _res =>
            {
                this.setState({
                    email: _res.email,
                    password: _res.pw,
                });
                document.getElementById('email').setAttribute('value', _res.email);
                document.getElementById('password').setAttribute('value', _res.pw);
                this.signIn(form);
            },
            _err =>
            {
                console.log("error : " + _err);
                form.submit();
            }
        )
    }

    signIn(form)
    {
        this.setState({loading: true});
        Auth.signIn(this.state.email, this.state.password)
            .then(val =>
            {
                console.log(val);
                this.checkToken(form);
            })
            .catch(err =>
            {
                console.log("error : " + err);
                form.submit();
            });
    }

    checkToken(form)
    {
        Auth.currentSession()
            .then(session =>
            {
                const request = {
                    headers: {
                        Authorization: session.getIdToken().getJwtToken(),
                    }
                };

                API.get('sharekanGateway', '/check', request)
                    .then(val =>
                    {
                        console.log(val);

                        let token = document.createElement('input');
                        token.setAttribute('type', 'hidden');
                        token.setAttribute('name', 'token');
                        token.setAttribute('value', session.getIdToken().getJwtToken());
                        form.appendChild(token);
                        form.submit()
                    })
                    .catch(err =>
                    {
                        console.log("error : " + err);
                        form.submit();
                    });
            })
            .catch(err =>
            {
                console.log("error : " + err);
                form.submit();
            });
    }

    render()
    {
        let action = this.props.action;

        return (
            <MuiThemeProvider theme={theme}>
                <Grid container component="main" className={this.props.classes.root}>
                    <CssBaseline/>
                    <Grid
                        item
                        sm={12}
                        md={8}
                        component={Paper}
                        className={this.props.classes.imageGrid}
                    >
                        <StorageImage
                            params={this.state.customer ? this.state.customer.service_logo : null}
                            default={'/images/sharekan.jpg'}
                            style={{height: '100%', width: '100%', objectFit: 'cover'}}
                        />
                    </Grid>
                    <Grid item sm={12} md={4} component={Paper} square>
                        <Container component="main" className={this.props.classes.container}>
                            <Avatar className={this.props.classes.avatar}>
                                <LockOutlinedIcon/>
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                {i18n.t("sign_in")}
                            </Typography>

                            <form id={"auth_form"}
                                  className={this.props.classes.form}
                                  action={action}
                                  method="POST"
                                  onSubmit={this.handleFormSubmit}
                                  noValidate>
                                <input name="utf8" type="hidden" value="✓"/>
                                <input type="hidden" name="authenticity_token" value={this.props.authenticity_token}/>

                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label={i18n.t("email_address")}

                                    autoComplete="email"
                                    autoFocus

                                    value={this.state.email}
                                    onChange={e =>
                                    {
                                        this.setState({email: e.target.value, update_input: true});
                                        this.handleFormBlur(e);
                                    }}
                                    error={this.state.error_status[this.FORM["email"].id]}
                                    helperText={this.state.error_message[this.FORM["email"].id]}
                                    ref={this.FORM["email"].ref}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth

                                    id="password"
                                    name="password"
                                    label={i18n.t("password")}
                                    type="password"

                                    autoComplete="current-password"

                                    onChange={e =>
                                    {
                                        this.setState({password: e.target.value, update_input: true});
                                        this.handleFormBlur(e);
                                    }}
                                    error={this.state.error_status[this.FORM["password"].id]}
                                    helperText={this.state.error_message[this.FORM["password"].id]}
                                    ref={this.FORM["password"].ref}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="remember_me"
                                            value="remember"
                                            color="primary"
                                            checked={this.state.remember_me}

                                            onChange={e =>
                                            {
                                                this.setState({remember_me: e.target.checked})
                                            }}
                                        />
                                    }
                                    label={i18n.t("remember_me")}
                                    ref={this.FORM["remember_me"].ref}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={this.props.classes.submit}
                                    style={{marginTop: '20px'}}
                                    disabled={!this.state.isSubmitEnabled || this.state.loading}>
                                    <Trans i18nKey={"sign_in"}/>
                                    {this.state.loading && <CircularProgress size={24}/>}
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href={this.props.new_password_path} variant="body2">
                                            {i18n.t("fogot_password")}
                                        </Link>
                                    </Grid>
                                    {
                                        this.props.customer &&
                                        <Grid item>
                                            <Link href={this.props.sign_up_path} variant="body2">
                                                {i18n.t("sign_up")}
                                            </Link>
                                        </Grid>
                                    }
                                </Grid>

                                <Divider variant="middle"
                                         style={{marginTop: '10px', marginBottom: '10px'}}
                                />
                            </form>
                            <div className={this.props.classes.social_buttons}>
                                <GoogleLoginButton
                                    onClick={e =>
                                    {
                                        location.href = this.props.google_oauth2_omniauth_authorize_path;
                                    }}
                                    disabled={!this.state.isSubmitEnabled || this.state.loading}
                                />
                                <FacebookLoginButton
                                    onClick={e =>
                                    {
                                        location.href = this.props.facebook_omniauth_authorize_path;
                                    }}
                                    disabled={!this.state.isSubmitEnabled || this.state.loading}
                                />
                                <TwitterLoginButton
                                    onClick={e =>
                                    {
                                        location.href = this.props.twitter_omniauth_authorize_path;
                                    }}
                                    disabled={!this.state.isSubmitEnabled || this.state.loading}
                                />
                            </div>
                        </Container>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(style)(SignInForm);