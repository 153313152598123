// ------------------------------------------------------------------------------------------------------------------ //
// 画面
// ------------------------------------------------------------------------------------------------------------------ //

export const BACKEND_VIEW_ROOT = "/backend";
export const BACKEND_VIEW_HOME = "/backend/home";

export const BACKEND_VIEW_EDIT_EVENT = "/backend/edit_event";
export const BACKEND_VIEW_EDIT_USER = "/backend/edit_user";
export const BACKEND_VIEW_EDIT_ITEM = "/backend/edit_item";

export const BACKEND_VIEW_RESERVATIONS = "/backend/reservations";
export const BACKEND_VIEW_PAYMENTS = "/backend/payments";
export const BACKEND_VIEW_USERS = "/backend/users";
export const BACKEND_VIEW_ITEMS = "/backend/items";
export const BACKEND_VIEW_MAIL_TEMPLATES = "/backend/mail_templates";
export const BACKEND_VIEW_COUPONS = "/backend/coupons";
export const BACKEND_VIEW_REPORTS = "/backend/reports";
export const BACKEND_VIEW_MESSAGE = "/backend/message";
export const BACKEND_VIEW_CONFIG = "/backend/config";

export const BACKEND_VIEW_SIGN_OUT = "/backend/sign_out";

// ------------------------------------------------------------------------------------------------------------------ //
// 定数
// ------------------------------------------------------------------------------------------------------------------ //
export const LEFT_MENU_WIDTH = 300;


// -------------------------------------------------------------------------------------------------------------------//
// API
// -------------------------------------------------------------------------------------------------------------------//

// 予約登録/編集
export const API_EDIT_EVENT               = "API_EDIT_EVENT";
// アイテム登録/編集
export const API_EDIT_ITEM                = "API_EDIT_ITEM";
// ユーザ情報編集
export const API_EDIT_USER_INFORMATION    = "API_EDIT_USER_INFORMATION";
// クーポン登録/編集
export const API_EDIT_COUPON              = "API_EDIT_COUPON";
// クーポン付与
export const API_GRANT_USER_COUPON        = "API_GRANT_USER_COUPON";
// クーポン剥奪
export const API_DROP_USER_COUPON         = "API_DROP_USER_COUPON";

// -------------------------------------------------------------------------------------------------------------------//
// 顧客情報関連
// -------------------------------------------------------------------------------------------------------------------//

// クーポンリスト
export const API_GET_COUPON_LIST          = "API_GET_COUPON_LIST";
// カスタマー設定
export const API_GET_CUSTOMER_CONFIG_LIST = "API_GET_CUSTOMER_CONFIG_LIST";
// 注文問い合わせ
export const API_GET_ORDER_INQUIRY        = "API_GET_ORDER_INQUIRY";

// -------------------------------------------------------------------------------------------------------------------//
// ユーザー情報関連
// -------------------------------------------------------------------------------------------------------------------//

// ユーザー情報取得
export const API_GET_USER_INFORMATION     = "API_GET_USER_INFORMATION";
// ユーザー検索
export const API_GET_USER_LIST            = "API_GET_USER_LIST";
// メッセージチャンネルリスト
export const API_GET_MESSAGE_CHANNEL_LIST = "API_GET_MESSAGE_CHANNEL_LIST";
// メッセージリスト
export const API_GET_MESSAGE              = "API_GET_MESSAGE";
// メッセージ送信
export const API_SEND_MESSAGE             = "API_SEND_MESSAGE";

// 通知リスト
export const API_GET_NOTIFICATION_LIST    = "API_GET_NOTIFICATION_LIST";
// 同一クライアントのアカウントリスト
export const API_GET_ACCOUNT_LIST         = "API_GET_ACCOUNT_LIST";

// -------------------------------------------------------------------------------------------------------------------//
// イベント情報関連
// -------------------------------------------------------------------------------------------------------------------//

// イベント総取得
export const API_GET_EVENT_LIST            = "API_GET_EVENT_LIST";
// イベントの変更履歴の取得
export const API_GET_CHANGE_HISTORY        = "API_GET_CHANGE_HISTORY";
// 予約ステータスの更新
export const API_UPDATE_RESERVATION_STATUS = "API_UPDATE_RESERVATION_STATUS";
// 支払ステータスの更新
export const API_UPDATE_PAYMENT_STATUS     = "API_UPDATE_PAYMENT_STATUS";

// カスタマイズイベント総取得
export const API_GET_CUSTOMIZE_EVENT_LIST            = "API_GET_CUSTOMIZE_EVENT_LIST";

// -------------------------------------------------------------------------------------------------------------------//
// 一覧リスト関連
// -------------------------------------------------------------------------------------------------------------------//

// 請求一覧検索
export const API_GET_BILL_LIST             = "API_GET_BILL_LIST";
// 支払い一覧検索
export const API_GET_PAYMENT_LIST          = "API_GET_PAYMENT_LIST";

// アイテムリスト検索
export const API_GET_ITEM_LIST             = "API_GET_ITEM_LIST";

// -------------------------------------------------------------------------------------------------------------------//
// 統計関連
// -------------------------------------------------------------------------------------------------------------------//
// KPI
export const API_GET_KPI = "API_GET_KPI";

// -------------------------------------------------------------------------------------------------------------------//
// フロント
// -------------------------------------------------------------------------------------------------------------------//
// 予約ステータスの問い合わせ
export const API_GET_CALENDAR_STATUS    = "API_GET_CALENDAR_STATUS";


// -------------------------------------------------------------------------------------------------------------------//
// カスタマー設定
// -------------------------------------------------------------------------------------------------------------------//
export const CUSTOMER_CONFIG_VALUE_UNAVAILABLE               = 0;
export const CUSTOMER_CONFIG_VALUE_AVAILABLE                 = 1;


// -------------------------------------------------------------------------------------------------------------------//
// 決済方法 : 100
// -------------------------------------------------------------------------------------------------------------------//
// PayPal決済の有効・無効
export const CUSTOMER_CONFIG_PAYMENT_METHOD_AVAILABLE_PAYPAL = 100;
// 銀行振込決済の有効・無効
export const CUSTOMER_CONFIG_PAYMENT_METHOD_AVAILABLE_BANK   = 101;
// 現金払い決済の有効・無効
export const CUSTOMER_CONFIG_PAYMENT_METHOD_AVAILABLE_CASH   = 102;
// 銀行口座情報
export const CUSTOMER_CONFIG_BANK_ACCOUNT                    = 103;


// -------------------------------------------------------------------------------------------------------------------//
// 動作設定 : 200
// -------------------------------------------------------------------------------------------------------------------//
// 予約受付時は仮予約とし、正式予約とするには承認を必要とする
export const CUSTOMER_CONFIG_ENABLE_TEMPORARY_RESERVE         = 200;
// 有償で予約を受付する
export const CUSTOMER_CONFIG_ENABLE_PAYMENT                   = 201;
// PayPal設定
export const CUSTOMER_CONFIG_PAYPAL                           = 202;
// PayPalによる支払いが確認されたら、自動で予約承認とする
export const CUSTOMER_CONFIG_ENABLE_PAY_TO_APPROVE            = 203;
// 時間単位で予約を管理する
export const CUSTOMER_CONFIG_RESERVATION_OF_TIME              = 204;
// 貸渡・返却記録フローを利用する
export const CUSTOMER_CONFIG_USE_LENT_RETURN_FLOW             = 206;
// 貸渡・返却は借主が自分で行う
export const CUSTOMER_CONFIG_USER_LENT_RETURN_BY_MYSELF       = 207;
// 配送先情報の入力を求める
export const CUSTOMER_CONFIG_NEED_USER_LENT_INFORMATION       = 208;

// 予約受付開始日前(最小)
export const CUSTOMER_CONFIG_ENABLE_RESERVATION_MIN_DAYS_FROM = 210;
// 予約受付開始日前(最大)
export const CUSTOMER_CONFIG_ENABLE_RESERVATION_MAX_DAYS_FROM = 211;

// １日に同一アイテムの複数回貸出を禁止する
export const CUSTOMER_CONFIG_RESERVE_LIMIT_ONE = 212;

// 営業開始時間
export const CUSTOMER_CONFIG_WORKING_TIME_FROM                = 220;
// 営業終了時間
export const CUSTOMER_CONFIG_WORKING_TIME_TO                  = 221;

// ウィジェット予約状況表示
export const CUSTOMER_CONFIG_WIDGET_RESERVATION_STATUS        = 230;

// フロント用のアナリティクストラッキングID
export const CUSTOMER_CONFIG_GOOGLE_ANALYTICS_TRACKING_ID     = 240;
export const CUSTOMER_CONFIG_GOOGLE_CONVERSION_SEND_TO        = 241;

// 手付金系設定
export const CUSTOMER_CONFIG_ENABLE_DEPOSIT                   = 250;
export const CUSTOMER_CONFIG_ENABLE_DEPOSIT_TYPE              = 251;
export const CUSTOMER_CONFIG_ENABLE_DEPOSIT_VALUE             = 252;
export const CUSTOMER_CONFIG_ENABLE_DEPOSIT_MIN_PRICE         = 253;

export const CUSTOMER_CONFIG_TUTORIAL_RESERVATION             = 901;
export const CUSTOMER_CONFIG_TUTORIAL_COUPON                  = 902;

// （デフォルト値）
// 予約受付開始日前；デフォルト値
export const ENABLE_RESERVATION_DEFAULT_MIN_DAYS_FROM = 0;
export const ENABLE_RESERVATION_DEFAULT_MAX_DAYS_FROM = 60;


export const ACCOUNT_PRIVILEGES_TYPE_RESERVATION = 1;
export const ACCOUNT_PRIVILEGES_TYPE_USER = 2;
export const ACCOUNT_PRIVILEGES_TYPE_ITEM = 3;
export const ACCOUNT_PRIVILEGES_TYPE_COUPON = 4;
export const ACCOUNT_PRIVILEGES_TYPE_REPORT = 5;
export const ACCOUNT_PRIVILEGES_TYPE_MESSAGE = 6;

export const ACCOUNT_PRIVILEGES_TYPES = {
    1: {name: '予約管理', marks: [{value: 0, label: "なし"}, {value: 1, label: "表示"}, {value: 2, label: "表示・登録・編集"}]},
    2: {name: '顧客管理', marks: [{value: 0, label: "なし"}, {value: 1, label: "表示"}, {value: 2, label: "表示・登録・編集"}]},
    3: {name: 'アイテム管理', marks: [{value: 0, label: "なし"}, {value: 1, label: "表示"}, {value: 2, label: "表示・登録・編集"}]},
    4: {name: 'クーポン管理', marks: [{value: 0, label: "なし"}, {value: 1, label: "表示"}, {value: 2, label: "表示・登録・編集"}]},
    5: {name: '利用レポート', marks: [{value: 0, label: "なし"}, {value: 1, label: "表示"}]},
    6: {name: 'メッセージ', marks: [{value: 0, label: "なし"}, {value: 1, label: "表示"}, {value: 2, label: "表示・登録・編集"}]},
};


// -------------------------------------------------------------------------------------------------------------------//
// 予約受付ページの設定：300
// -------------------------------------------------------------------------------------------------------------------//
// 予約受付ページを利用するか？
export const CUSTOMER_CONFIG_USE_RESERVATION_PAGE            = 300;
// 予約受付ページを作成するアイテムID
export const CUSTOMER_CONFIG_RESERVATION_PAGE_ITEMS          = 301;



// -------------------------------------------------------------------------------------------------------------------//
// ステータス等の定数定義
// -------------------------------------------------------------------------------------------------------------------//

// アイテム/オプション
export const ITEM_RESERVATION_TYPE_OPTION            = 0; /* 予約選択不可機材（オプション扱い） */
export const ITEM_RESERVATION_TYPE_ITEM              = 1; /* 予約選択可能機材 */

// 在庫管理(重複を許すか)
export const ITEM_TYPE_DUPLICATE_NG                  = 1;
export const ITEM_TYPE_DUPLICATE_OK                  = 2;

// アイテムの表示・非表示
export const ITEM_DISPLAY_OFF                        = 0;
export const ITEM_DISPLAY_ON                         = 1;

// アイテム利用可否
export const ITEM_AVAILABLE_OFF                      = 0;
export const ITEM_AVAILABLE_ON                       = 1;

// reservation_status

export const RESERVATION_STATUS_NOT_APPROVED         = 0;    // 承認待（仮予約）
export const RESERVATION_STATUS_WAIT_PAYMENT         = 1;    // 支払い待ち
export const RESERVATION_STATUS_APPROVED             = 2;    // 予約承認
export const RESERVATION_STATUS_RESCHEDULING         = 3;    // リスケ依頼中

export const RESERVATION_STATUS_USING                = 100;  // 利用中
export const RESERVATION_STATUS_SUSPENSION           = 190;  // 休止中
export const RESERVATION_STATUS_DONE                 = 200;  // 利用終了
export const RESERVATION_STATUS_NO_SHOW              = 201;  // ノーショー
export const RESERVATION_STATUS_CANCELED             = 900;  // キャンセル
export const RESERVATION_STATUS_DELETED              = 901;  // 削除
export const RESERVATION_STATUS_REJECT               = 902;  // リジェクト


// reservation flag
export const RESERVATION_FLAG_WARNING                = 1;  // 予約の注意


// payment type
export const PAYMENT_TYPE_UNKNOWN                    = 0; //未指定
export const PAYMENT_TYPE_CREDITCARD                 = 1; //クレジットカード
export const PAYMENT_TYPE_BANK                       = 2; //銀行振込
export const PAYMENT_TYPE_CASH                       = 3; //現金
export const PAYMENT_TYPE_CONVENIENCE                = 4; //コンビニ

// payment status
export const PAYMENT_STATUS_NOT_YET                  = -1; // 未請求
export const PAYMENT_STATUS_BILLING                  = 0; // 請求中
export const PAYMENT_STATUS_PROCESSING               = 1; // 決済処理中
export const PAYMENT_STATUS_AUTHORIZE                = 2; // オーソリ
export const PAYMENT_STATUS_PAID                     = 3; // 課金済み
export const PAYMENT_STATUS_REFUNDED                 = 4; // 返金
export const PAYMENT_STATUS_VOIDED                   = 5; // 拒否
export const PAYMENT_STATUS_FAILED                   = 6; // 決済失敗
export const PAYMENT_STATUS_UNKNOWN                  = 9; // 不明
export const PAYMENT_STATUS_DELETED                  = 10; // 削除

// item schedule
export const SCHEDULE_DISAVAILABLE                   = 0;  // 利用不可
export const SCHEDULE_AVAILABLE                      = 1;  // 利用可能
export const SCHEDULE_HOLIDAY                        = 2;  // 貸出・返却不可

// coupon
export const COUPON_NOT_YET_CONSUMED                 = 0;   // 未消費
export const COUPON_CONSUMED                         = 1;   // 消費
export const COUPON_DELETED                          = 9;   // 削除済

export const COUPON_UNAVAILABLE                      = 0;  // 利用不可
export const COUPON_AVAILABLE                        = 1;  // 利用可能

export const COUPON_DISCOUNT_TYPE_PRICE              = 0;  // 金額値引き
export const COUPON_DISCOUNT_TYPE_RATIO              = 1;  // 割合値引き

export const SCHEDULE_FOR_DAY                        = 1;  // 指定曜日
export const SCHEDULE_FOR_DATE                       = 2;  // 指定日
export const SCHEDULE_FOR_RANGE                      = 3;  // 指定範囲

// item price
export const PRICE_APPLY_TYPE_FOR_ALL                = 0;  // 通常料金
export const PRICE_APPLY_TYPE_FOR_DAY                = 1;  // 指定曜日
export const PRICE_APPLY_TYPE_FOR_DATE               = 2;  // 指定日
export const PRICE_APPLY_TYPE_FOR_RANGE              = 3;  // 指定範囲

// price unit
export const PRICE_UNIT_MINUTE                       = 1;  // 分単位課金
export const PRICE_UNIT_HOUR                         = 2;  // 時間単位課金
export const PRICE_UNIT_DAY                          = 3;  // 1日単位課金
export const PRICE_UNIT_DAY_HOUR                     = 4;  // 1日単位、時間単位課金
export const PRICE_UNIT_LENT                         = 5;  // 貸出回数課金
export const PRICE_UNIT_RATIO                        = 6;  // 貸出アイテム額の割合課金

// mail
export const MAIL_STATUS_ALL                         = 0;   // すべて
export const MAIL_STATUS_NOT_YET_READ                = 1;   // 未読
export const MAIL_STATUS_STAR                        = 2;   // 重要

export const MAIL_NOT_READ                           = 0;   // 未読
export const MAIL_READ                               = 1;   // 既読

export const MAIL_FLAG_IMPORTANT                     = 1;   // 重要フラグ

export const MAIL_FROM_MANAGER                       = 0;   // 管理→ユーザー
export const MAIL_FROM_USER                          = 1;   // ユーザー→管理


// order inquiry
// ユーザー -> カスタマー
export const ORDER_INQUIRY_TYPE_ITEM                 = 1;   // アイテムの仕様・状態について
export const ORDER_INQUIRY_TYPE_LENT                 = 2;   // アイテムの貸出方法・受け取りについて
export const ORDER_INQUIRY_TYPE_RETURN               = 3;   // アイテムの返却について
export const ORDER_INQUIRY_TYPE_PAYMENT              = 4;   // 利用料金・お支払いについて
export const ORDER_INQUIRY_TYPE_RESCHEDULE           = 5;   // 時間の変更について
export const ORDER_INQUIRY_TYPE_CHANGE_ITEM          = 6;   // 予約アイテムの変更について
export const ORDER_INQUIRY_TYPE_CANCEL               = 7;   // 注文のキャンセルについて
export const ORDER_INQUIRY_TYPE_CHANGE_DELIVERY_TO   = 8;   // 配送先住所を変更する
// カスタマー -> ユーザー
export const ORDER_INQUIRY_TYPE_RESCHEDULE_FOR_USER  = 15;  // 時間の変更について（カスタマー -> ユーザー）
export const ORDER_INQUIRY_TYPE_CHANGE_ITEM_FOR_USER = 16;  // 予約アイテムの変更について（カスタマー -> ユーザー）
export const ORDER_INQUIRY_TYPE_CANCEL_FOR_USER      = 17;  // 注文のキャンセルについて（カスタマー -> ユーザー）

export const ORDER_INQUIRY_UNSOLVED                  = 0;   // 未解決
export const ORDER_INQUIRY_SOLVED                    = 1;   // 解決

export const ORDER_INQUIRY_RESPONSE_DISAGREE         = -1;   // 非同意
export const ORDER_INQUIRY_RESPONSE_AGREE            = 1;    // 同意

// user identification status
export const USER_IDENTIFICATION_STATUS_NONE         = 0; // 未確認
export const USER_IDENTIFICATION_STATUS_INSPECTING   = 1; // 確認中
export const USER_IDENTIFICATION_STATUS_VALIDATED    = 2; // 確認済
export const USER_IDENTIFICATION_STATUS_DECLINED     = 3; // 却下

// notification
export const NOTIFICATION_TYPE_CUSTOMER              = 1;  // 顧客向け通知
export const NOTIFICATION_TYPE_USER                  = 2;  // ユーザー向け通知

// message type
export const MAIL_MESSAGE_TYPE_TEXT                  = 0; // テキスト
export const MAIL_MESSAGE_TYPE_IMAGE                 = 1; // 画像
export const MAIL_MESSAGE_TYPE_INQUIRY               = 2; // 問い合わせカード

// order status
export const ORDER_STATUS_1_NEW_RESERVATION            = 1;  // 予約時間・アイテム・顧客の登録
export const ORDER_STATUS_2_PAYMENT                    = 2;  // 利用料金の請求
export const ORDER_STATUS_3_APPROVE                    = 3;  // 仮予約の承認
export const ORDER_STATUS_4_LENT                       = 4;  // 貸渡
export const ORDER_STATUS_5_RETURN                     = 5;  // 返却

//クーポン
export const COUPON_USAGE_TYPE_NORMAL = 0;
export const COUPON_USAGE_TYPE_USER   = 1;

//KPI
export const KPI_GROSS_TYPE_LENT_DATE = 0;
export const KPI_GROSS_TYPE_PAID_DATE = 1;

// -------------------------------------------------------------------------------------------------------------------//
// Reduxイベント関連
// -------------------------------------------------------------------------------------------------------------------//
export const GO_HOME                                 = "GO_HOME";

// ------------------------------------------------------------------------------------------------------------------ //
// イベントリストイベント
// ------------------------------------------------------------------------------------------------------------------ //
// left event list
/* イベントの選択クリック */
export const ON_SELECT_EVENT                                     = "ON_SELECT_EVENT";
/* イベントの編集 */
export const ON_CLICK_EDIT_EVENT                                 = "ON_CLICK_EDIT_EVENT";
/* イベントの追加取得と内部リストへのマージ・選択 */
export const ON_MERGE_AND_EDIT_EVENT                           = "ON_MERGE_AND_EDIT_EVENT";
/* カレンダーの範囲変更 */
export const CHANGE_SCHEDULE_RANGE                                        = "CHANGE_SCHEDULE_RANGE";
/* メール送信のオンオフ */
export const SWITCH_SEND_MAIL                                        = "SWITCH_SEND_MAIL";

// ------------------------------------------------------------------------------------------------------------------ //
// App Barクリックイベント
// ------------------------------------------------------------------------------------------------------------------ //
/* リスト開閉ボタンのクリック */
export const BACKEND_APP_BAR_ONCLICK_MENU               = "BACKEND_APP_BAR_ONCLICK_MENU";
/* バックボタンのクリック */
export const BACKEND_APP_BAR_ONCLICK_BACK               = "BACKEND_APP_BAR_ONCLICK_BACK";


// メールボタンのクリック
export const BACKEND_APP_BAR_ONCLICK_MAIL_BUTTON                 = "BACKEND_APP_BAR_ONCLICK_MAIL_BUTTON";

/* メニュー「予約一覧」のクリック */
export const BACKEND_APP_BAR_ONCLICK_RESERVE_MENU                = "BACKEND_APP_BAR_ONCLICK_RESERVE_MENU";
/* メニュー「決済・請求管理」のクリック */
export const BACKEND_APP_BAR_ONCLICK_INVOICE_MENU                = "BACKEND_APP_BAR_ONCLICK_INVOICE_MENU";
/* メニュー「顧客一覧」のクリック */
export const BACKEND_APP_BAR_ONCLICK_CUSTOMER_MENU               = "BACKEND_APP_BAR_ONCLICK_CUSTOMER_MENU";
/* メニュー「アイテム管理」のクリック */
export const BACKEND_APP_BAR_ONCLICK_ITEM_MENU                   = "BACKEND_APP_BAR_ONCLICK_ITEM_MENU";
/* メニュー「メール」のクリック */
export const BACKEND_APP_BAR_ONCLICK_MAIL_TEMPLATE_MENU          = "BACKEND_APP_BAR_ONCLICK_MAIL_TEMPLATE_MENU";
// メニュー「クーポン」のクリック
export const BACKEND_APP_BAR_ONCLICK_COUPON_MENU                 = "BACKEND_APP_BAR_ONCLICK_COUPON_MENU";
// メニュー「統計」のクリック
export const BACKEND_APP_BAR_ONCLICK_STATISTICS_MENU             = "BACKEND_APP_BAR_ONCLICK_STATISTICS_MENU";


// アカウント
// 設定
export const BACKEND_APP_BAR_ONCLICK_ACCOUNT_CONFIG_MENU         = "BACKEND_APP_BAR_ONCLICK_ACCOUNT_CONFIG_MENU";
// ヘルプ
export const BACKEND_APP_BAR_ONCLICK_ACCOUNT_HELP_MENU           = "BACKEND_APP_BAR_ONCLICK_ACCOUNT_HELP_MENU";
// ログアウト
export const BACKEND_APP_BAR_ONCLICK_ACCOUNT_LOGOUT_MENU         = "BACKEND_APP_BAR_ONCLICK_ACCOUNT_LOGOUT_MENU";

// リストビューからスケジュールビューへ戻る
export const LIST_VIEW_BACK_TO_SCHEDULER                         = "LIST_VIEW_BACK_TO_SCHEDULER";

//メッセージ
/* ユーザ選択 */
export const SELECT_MESSAGE_USER                                 = "SELECT_MESSAGE_USER";

// ------------------------------------------------------------------------------------------------------------------ //
// イベントのロード
// ------------------------------------------------------------------------------------------------------------------ //
// load events
/* イベントリストのロード開始 */
export const START_LOAD_EVENTS                                   = "START_LOAD_EVENTS";
/* イベントリストのロード中にエラー発生 */
export const ERROR_LOAD_EVENTS                                   = "ERROR_LOAD_EVENTS";
/* イベントリストのロード完了 */
export const FINISH_LOAD_EVENTS                                  = "FINISH_LOAD_EVENTS";
/* イベントリストの再セット */
export const RESET_EVENTS                                        = "RESET_EVENTS";
/* イベントリストの再セット */
export const REMOVE_EVENT                                        = "REMOVE_EVENT";
// ------------------------------------------------------------------------------------------------------------------ //
// アイテムのロード
// ------------------------------------------------------------------------------------------------------------------ //
/* アイテムリストのロード開始 */
export const START_LOAD_ITEMS                                    = "START_LOAD_ITEMS";
/* アイテムリストのロード中にエラー発生 */
export const ERROR_LOAD_ITEMS                                    = "ERROR_LOAD_ITEMS";
/* アイテムリストのロード完了 */
export const FINISH_LOAD_ITEMS                                   = "FINISH_LOAD_ITEMS";
/* アイテムリストの再セット */
export const RESET_ITEMS                                         = "RESET_ITEMS";
/* メールテンプレートの再セット */
export const RESET_MAIL_TEMPLATES                                = "RESET_MAIL_TEMPLATES";

// ------------------------------------------------------------------------------------------------------------------ //
// スケジューラー
// ------------------------------------------------------------------------------------------------------------------ //
// 表示アイテムの指定
export const BACKEND_EVENT_SCHEDULER_FILTER_ITEM                 = "BACKEND_EVENT_SCHEDULER_FILTER_ITEM";


// ------------------------------------------------------------------------------------------------------------------ //
// 予約編集
// ------------------------------------------------------------------------------------------------------------------ //
// アイテム・オプションの追加
export const BACKEND_EVENT_DESCRIPTION_EDIT_ADD_ITEM             = "BACKEND_EVENT_DESCRIPTION_EDIT_ADD_ITEM";
// 請求追加
export const BACKEND_EVENT_DESCRIPTION_EDIT_ADD_EXTRA            = "BACKEND_EVENT_DESCRIPTION_EDIT_ADD_EXTRA";
// 支払い追加
export const BACKEND_EVENT_DESCRIPTION_EDIT_ADD_PAYMENT          = "BACKEND_EVENT_DESCRIPTION_EDIT_ADD_PAYMENT";

// アイテム・オプションの
export const BACKEND_EVENT_DESCRIPTION_EDIT_REMOVE_ITEM          = "BACKEND_EVENT_DESCRIPTION_EDIT_REMOVE_ITEM";
// イベントの新規作成
export const BACKEND_SCHEDULER_CREATE_NEW_EVENT                  = "BACKEND_SCHEDULER_CREATE_NEW_EVENT";

/* 既存ユーザを選択 */
export const BACKEND_SELECT_EXISTED_USER                         = "BACKEND_SELECT_EXISTED_USER";
/* イベント更新時結果 */
export const ON_EDIT_EVENT_RESULT                                = "ON_EDIT_EVENT_RESULT";

// ------------------------------------------------------------------------------------------------------------------ //
// 編集
// ------------------------------------------------------------------------------------------------------------------ //
// 顧客編集
export const BACKEND_CUSTOMER_LIST_VIEW_SHOW_EDIT_CUSTOMER       = "BACKEND_CUSTOMER_LIST_VIEW_SHOW_EDIT_CUSTOMER";
// アイテム編集
export const BACKEND_ITEM_LIST_VIEW_SHOW_EDIT_ITEM               = "BACKEND_ITEM_LIST_VIEW_SHOW_EDIT_ITEM";
/* クーポン更新受信 */
export const BACKEND_UPDATE_COUPON_LIST                          = "BACKEND_UPDATE_COUPON_LIST";

// サブスクリプション
export const PLAN_TYPE_FREE                                      = 10;
export const PLAN_TYPE_BASIC                                     = 11;
export const PLAN_TYPE_BUSINESS                                  = 12;
export const PLAN_TYPE_ENTERPRISE                                = 13;
export const PLAN_TYPE_ADD_ACCOUNT                               = 21;

export const PLAN_CATEGORY_PLAN                                  = 1;
export const PLAN_CATEGORY_OPTION                                = 2;


// ---------------------------------------------------------------------------------------------------------- //
// リスト系再セット
// ---------------------------------------------------------------------------------------------------------- //
/* セット */
export const RESET_NOTIFICATIONS                                 = "RESET_NOTIFICATIONS";
/* セット */
export const RESET_MESSAGE_CHANNELS                              = "RESET_MESSAGE_CHANNELS";
/* セット */
export const RESET_CHANGE_HISTORIES                              = "RESET_CHANGE_HISTORIES";
/* セット */
export const RESET_CUSTOMIZED_EVENTS                             = "RESET_CUSTOMIZED_EVENTS";
/* セット */
export const RESET_CUSTOMER_INFORMATION                          = "RESET_CUSTOMER_INFORMATION";
/* セット */
export const RESET_ACCOUNT                                       = "RESET_ACCOUNT";
/* セット */
export const RESET_CUSTOMER_CONFIGS                              = "RESET_CUSTOMER_CONFIGS";

// ---------------------------------------------------------------------------------------------------------- //
// チュートリアル
// ---------------------------------------------------------------------------------------------------------- //
export const BACKEND_TUTORIAL                                     = "BACKEND_TUTORIAL";