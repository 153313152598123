export const COOKIE_KEY_LOGIN_EMAIL = "login_email";
export const COOKIE_KEY_LOGIN_PASSWORD = "login_password";
export const COOKIE_KEY_LOGIN_REMEMBER = "login_remember";
export const COOKIE_KEY_NO_NOTIFICATION_SOUND = "no_notification_sound";
export const COOKIE_KEY_OMNIAUTH_PATH = "omniauth_path";

export const CALENDAR_STATUS_CAN_RESERVE = 1;
export const CALENDAR_STATUS_PARTIAL = 2;
export const CALENDAR_STATUS_HIDDEN = 3;
export const CALENDAR_STATUS_HOLIDAY = 4;
export const CALENDAR_STATUS_FULL = 11;
export const CALENDAR_STATUS_DISABLE = 21;