import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import {MuiThemeProvider} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';

import axios from 'axios';
import {csrfToken} from 'rails-ujs';

import {ErrorMessageProtocol} from "../../base/ErrorMessage/ErrorMessage";

import * as SharekanStatic from '../../SharekanStatic'

import Amplify, {Auth} from "aws-amplify";
import config from "../../../../../src/aws-exports";
import {FacebookLoginButton, GoogleLoginButton, TwitterLoginButton} from "react-social-login-buttons";
// ------------------------------------------------------------------------------------------------------------------ //
// Theme
// ------------------------------------------------------------------------------------------------------------------ //
import theme from './../../../theme/theme';
// ------------------------------------------------------------------------------------------------------------------ //
// initialize i18n
// ------------------------------------------------------------------------------------------------------------------ //
import {Trans} from "react-i18next";
import i18n from "./../../../libs/i18n";

import ja from './locale/ja';
import en from './locale/en';
// ------------------------------------------------------------------------------------------------------------------ //
// import Validator
// ------------------------------------------------------------------------------------------------------------------ //
import ValidationForm from './../../../libs/ValidationForm';
import Toolkit from "../../../libs/Toolkit";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import StorageImage from "../../../libs/StorageImage";

Amplify.configure(config);

i18n.addResourceBundle("en", "translation", en.translation);
i18n.addResourceBundle("ja", "translation", ja.translation);

// ------------------------------------------------------------------------------------------------------------------ //
// component
// ------------------------------------------------------------------------------------------------------------------ //
export default class SignUpForm extends ValidationForm
{
    constructor(props)
    {
        super();

        this.FORM = {
            email: {
                id: "email",
                ref: React.createRef(),
                validation: {
                    required: {
                        message: "input_required"
                    },
                    email: {
                        message: "invalid_email_format"
                    }
                }
            },
            password: {
                id: "password",
                ref: React.createRef(),
                validation: {
                    required: {
                        message: "input_required"
                    },
                    length: {
                        morethan: 8,
                        message: "required_morethan_length_8"
                    },
                    password_confirmation: {
                        target: "password_confirmation",
                        message: "password_confirmation_failed"
                    }
                }
            },
            password_confirmation: {
                id: "password_confirmation",
                ref: React.createRef(),
                validation: {
                    required: {
                        message: "input_required"
                    },
                    length: {
                        morethan: 8,
                        message: "required_morethan_length_8"
                    },
                    password_confirmation: {
                        target: "password",
                        message: "password_confirmation_failed"
                    }
                }
            },
            agreement: {
                id: "agreement",
                ref: React.createRef(),
                validation: {
                    required: {
                        message: "agreement_required"
                    }
                }
            },
        };

        this.state = {
            error_status: {},
            error_message: {},
            isSubmitEnabled: false,
            isReSignUp: false,
            isSocialSignUp: false,
            button: "signup",
            email: props.email ? props.email : "",
            code: props.code,
            customer_id: props.customer ? props.customer.id : 0,
            failure: false,

            show_agreement: false,
            show_privacy: false,
        };

        this.handleFormBlur = this.handleFormBlur.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);

        axios.defaults.headers.common['X-CSRF-Token'] = csrfToken();

        if (props.customer)
        {
            if (props.customer.service_name)
                history.replaceState('', '', '/' + props.customer.service_name + '/sign_up');

            this.state = {
                ...this.state,
                customer: props.customer
            };
        }

        // Toolkit.setCookie(SharekanStatic.COOKIE_KEY_OMNIAUTH_PATH, props.is_frontend ? 'frontend_sign_up' : 'backend_sign_up');
        sessionStorage.setItem(SharekanStatic.COOKIE_KEY_OMNIAUTH_PATH, props.is_frontend ? 'frontend_sign_up' : 'backend_sign_up');
    }

    componentDidMount()
    {
        if (this.state.code)
        {
            this.signUpBySocial(this.state.code)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.state.update_input)
        {
            let validates = [];
            for (let key in this.FORM)
                validates.push(this.validate(key, this.state[key]));
            this.merge(validates);
            this.setState({
                update_input: false
            });
        }
    }

    handleFormBlur(event)
    {
        // if (event.target.type === "checkbox")
        // {
        //     this.merge([this.validate(event.target.name, event.target.checked)]);
        // }
        // else
        // {
        //     let validates = [];
        //     if
        //     (
        //         this.FORM[event.target.name].validation.password_confirmation &&
        //         this.FORM[event.target.name].validation.password_confirmation.target
        //     )
        //     {
        //         let target = this.FORM[event.target.name].validation.password_confirmation.target;
        //         let value = this.FORM[target].ref.current.value;
        //         validates.push(this.validate(target, value));
        //     }
        //     validates.push(this.validate(event.target.name, event.target.value));
        //     this.merge(validates);
        // }
    }

    handleFormSubmit(event)
    {
        event.preventDefault();
        if (this.beforeSubmit())
        {
            if (this.state.isReSignUp)
                this.resendSignUp();
            else
                this.signUp();
        }
    }

    signUpBySocial(code)
    {
        this.setState({isSocialSignUp: true});
        Toolkit.request(this,
            '/api/get_auth_data',
            {
                code: code,
            },
            _res =>
            {
                this.setState({
                    email: _res.email,
                    password: _res.pw,
                    password_confirmation: _res.pw,
                });
                this.signUp();
            },
            _err =>
            {
                console.log("error : " + _err);
                this.setState({isSocialSignUp: false});
                ErrorMessageProtocol.next("外部サービスのログイン有効期限が切れています");
            }
        )
    }

    signUp()
    {
        if (Number(this.props.is_account) === 0 && Number(this.state.customer_id) <= 0)
        {
            ErrorMessageProtocol.next("登録するサービスが見つかりません");
            return;
        }

        this.setState({loading: true});
        const param = {
            username: this.state.email,
            password: this.state.password,
            attributes: {
                email: this.state.email,
                'custom:customer_id': (this.state.customer_id).toString(),
                'custom:is_account': (this.props.is_account).toString(),
                'custom:option': this.props.sign_up_transaction_id,
            }
        };
        Auth.signUp(param)
            .then(() =>
            {
                Toolkit.setCookie(SharekanStatic.COOKIE_KEY_LOGIN_EMAIL, this.state.email);
                Toolkit.setCookie(SharekanStatic.COOKIE_KEY_LOGIN_PASSWORD, this.state.password);
                Toolkit.setCookie(SharekanStatic.COOKIE_KEY_LOGIN_REMEMBER, "true");
                location.href = this.props.action + "?email=" + this.state.email
            })
            .catch(err =>
            {
                if (err.code === 'UsernameExistsException')
                {
                    // メールアドレスが重複している場合は確認コードを再送する
                    this.setState({isReSignUp: true, button: 'resend'});
                    this.resendSignUp();
                }
                else
                {
                    ErrorMessageProtocol.next("error : " + err.message);
                    this.setState({loading: false, failure: true});
                }
            });
    }

    resendSignUp()
    {
        Auth.resendSignUp(this.state.email)
            .then(_data =>
            {
                Toolkit.setCookie(SharekanStatic.COOKIE_KEY_LOGIN_EMAIL, this.state.email);
                Toolkit.setCookie(SharekanStatic.COOKIE_KEY_LOGIN_PASSWORD, this.state.password);
                Toolkit.setCookie(SharekanStatic.COOKIE_KEY_LOGIN_REMEMBER, "true");
                location.href = this.props.action + "?email=" + this.state.email
            })
            .catch(err =>
            {
                if (err.code === 'InvalidParameterException')
                {
                    ErrorMessageProtocol.next("既に登録済みのメールアドレスです。");
                }
                else
                {
                    ErrorMessageProtocol.next("不明なエラー : " + err.message);
                }
                this.setState({loading: false, failure: true});
            });
    }

    render()
    {
        return (
            <MuiThemeProvider theme={theme}>
                {
                    this.props.customer &&
                    <div
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            backgroundColor: '#fff',
                        }}
                    >
                        <StorageImage
                            params={this.props.customer ? this.props.customer.service_logo : null}
                            default={'/images/sharekan.jpg'}
                            style={{
                                maxHeight: 100,
                                width: 'auto'
                            }}
                        />
                    </div>
                }
                <Container component="main" maxWidth="sm">
                    <CssBaseline/>
                    <div
                        style={{
                            textAlign: 'center'
                        }}
                    >
                        <Typography component="h1"
                                    variant="h5"
                                    style={{marginTop: '20px'}}>
                            <Trans i18nKey="welcome"/>
                        </Typography>
                        <form method="POST"
                              onSubmit={this.handleFormSubmit}
                              noValidate>
                            <input type="hidden" name="authenticity_token" value={this.props.authenticity_token}/>

                            <div>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    type="email"
                                    fullWidth
                                    id="email"
                                    label={i18n.t('mailaddress')}
                                    name="email"
                                    autoComplete='disabled'

                                    value={this.state.email}
                                    onChange={e =>
                                    {
                                        this.setState({email: e.target.value, update_input: true});
                                    }}
                                    error={this.state.error_status[this.FORM.email.id]}
                                    helperText={this.state.error_message[this.FORM.email.id]}
                                    inputRef={this.FORM.email.ref}
                                    disabled={this.state.isSocialSignUp}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label={i18n.t('password')}
                                    type="password"
                                    id="password"
                                    autoComplete='disabled'
                                    disabled={this.state.isReSignUp}
                                    style={{display: this.state.isSocialSignUp ? 'none' : 'block'}}

                                    onChange={e =>
                                    {
                                        this.setState({password: e.target.value, update_input: true});
                                    }}
                                    error={this.state.error_status[this.FORM.password.id]}
                                    helperText={this.state.error_message[this.FORM.password.id]}
                                    inputRef={this.FORM.password.ref}
                                />

                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password_confirmation"
                                    label={i18n.t('password_confirmation')}
                                    type="password"
                                    id="password_confirmation"
                                    autoComplete='disabled'
                                    disabled={this.state.isReSignUp}
                                    style={{display: this.state.isSocialSignUp ? 'none' : 'block'}}

                                    onChange={e =>
                                    {
                                        this.setState({password_confirmation: e.target.value, update_input: true});
                                    }}

                                    error={this.state.error_status[this.FORM.password_confirmation.id]}
                                    helperText={this.state.error_message[this.FORM.password_confirmation.id]}
                                    inputRef={this.FORM.password_confirmation.ref}
                                />
                                <FormControl required
                                             error={this.state.error_status[this.FORM.agreement.id]}
                                             component="fieldset">
                                    {/*<FormLabel component="legend"></FormLabel>*/}

                                    <FormGroup style={{display: this.state.isSocialSignUp ? 'none' : 'block'}}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox value="agree"
                                                          color="primary"
                                                          name="agreement"
                                                          required
                                                          label={i18n.t('agreement')}

                                                          onChange={e =>
                                                          {
                                                              this.setState({agreement: e.target.checked, update_input: true})
                                                          }}
                                                          inputRef={this.FORM.agreement.ref}
                                                />
                                            }
                                            label={
                                                <p>
                                                    <Button
                                                        color="primary"
                                                        style={{fontSize: '1rem'}}
                                                        onClick={e =>
                                                        {
                                                            this.setState({show_agreement: true});
                                                        }}
                                                    >
                                                        利用規約
                                                    </Button>
                                                    及び
                                                    <Button
                                                        color="primary"
                                                        style={{fontSize: '1rem'}}
                                                        onClick={e =>
                                                        {
                                                            this.setState({show_privacy: true});
                                                        }}
                                                    >
                                                        プライバシーポリシー
                                                    </Button>

                                                    に同意する
                                                </p>
                                            }
                                        />
                                    </FormGroup>
                                    <FormHelperText>{this.state.error_message[this.FORM.agreement.id]}</FormHelperText>
                                </FormControl>

                                <Button type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        style={{marginTop: '20px'}}
                                        disabled={!this.state.isSubmitEnabled || this.state.loading}>
                                    <Trans i18nKey={this.state.button}/>
                                    {this.state.loading && <CircularProgress size={24}/>}
                                </Button>
                            </div>
                            <Divider variant="middle"
                                     style={{marginTop: '10px', marginBottom: '10px'}}
                            />
                        </form>
                        {
                            this.state.failure &&
                            <Button type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{marginTop: '20px'}}
                                    onClick={e =>
                                    {
                                        location.href = this.props.sign_in_path
                                    }}
                            >
                                ログインページへ
                            </Button>
                        }
                        <div style={{display: this.state.isSocialSignUp ? 'none' : 'block'}}>
                            <GoogleLoginButton
                                onClick={e =>
                                {
                                    location.href = this.props.google_oauth2_omniauth_authorize_path;
                                }}
                                disabled={!this.state.isSubmitEnabled || this.state.loading}
                            />
                            <FacebookLoginButton
                                onClick={e =>
                                {
                                    location.href = this.props.facebook_omniauth_authorize_path;
                                }}
                                disabled={!this.state.isSubmitEnabled || this.state.loading}
                            />
                            <TwitterLoginButton
                                onClick={e =>
                                {
                                    location.href = this.props.twitter_omniauth_authorize_path;
                                }}
                                disabled={!this.state.isSubmitEnabled || this.state.loading}
                            />
                        </div>
                    </div>
                </Container>

                <Dialog
                    open={this.state.show_agreement}
                    fullWidth={true}
                    onClose={(e) =>
                    {
                        this.setState({show_agreement: false})
                    }}>

                    <DialogContent>
                        {
                            this.props.is_account ?
                                <iframe style={{border: "none"}} src={"/static/backend-agreement.htm"} width={'100%'} height={640}/> :
                                <iframe style={{border: "none"}} src={"/static/frontend-agreement.htm"} width={'100%'} height={640}/>
                        }
                    </DialogContent>

                    <DialogActions>
                        <Button size="small" color="secondary" onClick={(e) =>
                        {
                            this.setState({show_agreement: false})
                        }}>閉じる</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.show_privacy}
                    fullWidth={true}
                    onClose={(e) =>
                    {
                        this.setState({show_privacy: false})
                    }}>

                    <DialogContent>
                        <iframe style={{border: "none"}} src={"/static/privacy.htm"} width={'100%'} height={640}/>
                    </DialogContent>

                    <DialogActions>
                        <Button size="small" color="secondary" onClick={(e) =>
                        {
                            this.setState({show_privacy: false})
                        }}>閉じる</Button>
                    </DialogActions>
                </Dialog>
            </MuiThemeProvider>
        );
    }
}
