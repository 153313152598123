import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
// ------------------------------------------------------------------------------------------------------------------ //
// Theme
// ------------------------------------------------------------------------------------------------------------------ //
// ------------------------------------------------------------------------------------------------------------------ //
// initialize i18n
// ------------------------------------------------------------------------------------------------------------------ //
import i18n from "./../../../libs/i18n";

import ja from './locale/ja';
import en from './locale/en';

const style = {
};

i18n.addResourceBundle("en", "translation", en.translation);
i18n.addResourceBundle("ja", "translation", ja.translation);


class AddSignIn extends React.Component
{
    render()
    {
        return (
            <Grid item xs>
                <Link href="/auth/sign_in" variant="body2">
                    {i18n.t("sign_in")}
                </Link>
            </Grid>
        );
    }
}

class AddSignUp extends React.Component
{
    render()
    {
        return (
            <Grid item xs>
                <Link href="/auth/sign_up" variant="body2">
                    {i18n.t("sign_up")}
                </Link>
            </Grid>
        );
    }
}

class AddGoTop extends React.Component
{
    render() {
        return (
            <Grid item xs>
                <Link href="/" variant="body2">
                    {i18n.t("top")}
                </Link>
            </Grid>
        );
    }

}

// ------------------------------------------------------------------------------------------------------------------ //
// component
// ------------------------------------------------------------------------------------------------------------------ //
class DeviseNavigationFooter extends React.Component
{
    constructor(props)
    {
        super();
    }

    render()
    {
        return (
            <Grid container>
                {this.props.go_top && <AddGoTop /> }
                {this.props.sign_in && <AddSignIn /> }
                {this.props.sign_up && <AddSignUp /> }
            </Grid>
        );
    }
}

export default withStyles(style)(DeviseNavigationFooter);
