import React from 'react';
import {MuiThemeProvider, withStyles} from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import * as Observable from 'zen-observable';
// ------------------------------------------------------------------------------------------------------------------ //
// Theme
// ------------------------------------------------------------------------------------------------------------------ //
import theme from './../../../theme/theme';
// ------------------------------------------------------------------------------------------------------------------ //
// initialize i18n
// ------------------------------------------------------------------------------------------------------------------ //
import i18n from "./../../../libs/i18n";

import ja from './locale/ja';
import en from './locale/en';

const style = {
    icon: {
        verticalAlign: "middle",
        marginRight: 10
    },
};


i18n.addResourceBundle("en", "translation", en.translation);
i18n.addResourceBundle("ja", "translation", ja.translation);

// ------------------------------------------------------------------------------------------------------------------ //
// component
// ------------------------------------------------------------------------------------------------------------------ //
class ErrorMessage extends React.Component {
    constructor(props) {
        super();

        if (props.error_list && Object.keys(props.error_list).length > 0)
        {
            this.state = {
                open: true,
                error_list: props.error_list,
                error_message: this.error_message(props.error_list),
            };
        }
        else
        {
            this.state = {
                open: false,
                error_list: [],
                error_message: '',
            };
        }

        this.handleClose = this.handleClose.bind(this);

        ErrorMessageProtocol.getObservable()
            .subscribe({
                next: (message) => {
                    this.openSnackbar(message)
                }
            });
    }

    handleClose(event, reason) {
        if (reason === 'clickaway')
        {
            return;
        }

        this.setState({open: false});
    }

    error_message(error_list) {
        let messages = "";
        error_list.map(error => {
            try
            {
                let error_message = i18n.t(error[1]);
                console.log("[ERROR] target=" + error[1] + ", error_message=" + error_message);

                messages += error_message;
            } catch (e)
            {
                console.log("[ERROR] error occurs at : " + e);
            }
        });

        return messages;
    }

    openSnackbar = message => {
        this.setState({open: true, error_message: message});
    }

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.open}
                    autoHideDuration={6000}
                    onClose={this.handleClose}
                >
                    <SnackbarContent
                        style={{backgroundColor: theme.palette.error.dark}}
                        message={
                            <div>
                                <ErrorIcon className={this.props.classes.icon}/>
                                <span>
                                {this.state.error_message}
                            </span>
                            </div>
                        }
                        action={[
                            <IconButton key="close" aria-label="close" color="inherit" onClick={this.handleClose}>
                                <CloseIcon/>
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(style)(ErrorMessage);

export class ErrorMessageProtocol {
    static observable = null;
    static observer = null;

    static getObservable() {
        if (!(this.observable))
            this.observable = new Observable(observer => {
                this.observer = observer;
            });
        return this.observable;
    }

    static next(message){
        this.observer.next(message);
    }
}