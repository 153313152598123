import ReactOnRails from 'react-on-rails';

import SignUpForm from '../bundles/Devise/SignUpForm/SignUpForm';
import SignInForm from '../bundles/Devise/SignInForm/SignInForm';
import ResetPasswordForm from '../bundles/Devise/ResetPasswordForm/ResetPasswordForm'
import ChangePasswordForm from '../bundles/Devise/ChangePasswordForm/ChangePasswordForm'
import NotificationMessage from '../bundles/Devise/NotificationMessage/NotificationMessage'
import ConfirmCodeForm from '../bundles/Devise/ConfirmCodeForm/ConfirmCodeForm';

import RegistrationStep from '../bundles/RegistrationStep/RegistrationStep'

import ErrorMessage from '../bundles/base/ErrorMessage/ErrorMessage';
import SimpleMessageTile from '../bundles/base/SimpleMessageTile/SimpleMessageTile'
import BasicFooter from '../bundles/base/BasicFooter/BasicFooter'

import ReserveButton from '../bundles/Sharekan/ReserveButton/ReserveButton'

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
    RegistrationStep,
    SignUpForm,
    ErrorMessage,
    NotificationMessage,
    SimpleMessageTile,
    BasicFooter,
    ReserveButton,
    SignInForm,
    ResetPasswordForm,
    ChangePasswordForm,
    ConfirmCodeForm
});
