import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from 'react-i18next';


i18n//.use(XHR)  // 初期化がレンダリングに間に合ってない？
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({

        // ここで指定も可
        // resources: {
        //     "ja" : ja,
        //     "en" : en
        // },

        // we init with resources
        fallbackLng: "ja",

        debug: true,

        // jsonをhttp取得する場合
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
            allowMultiLoading: true,
        },

        interpolation: {
            escapeValue: false // not needed for react!!
        },

        react: {
            wait: true
        },

        // useSuspense: false

    });

export default i18n;
