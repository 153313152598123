import {createMuiTheme} from '@material-ui/core/styles';
import * as Colors from "@material-ui/core/colors";

var theme = createMuiTheme({
    palette: {
        reserved: {
            light: Colors.green[300],
            main: Colors.green[500],
            dark: Colors.green[900],
        },
        not_authorized: {
            light: Colors.orange[300],
            main: Colors.orange[500],
            dark: Colors.orange[900],
        },
        warning: {
            light: Colors.red[300],
            main: Colors.red[500],
            dark: Colors.red[900],
        },

        ok: {
            light: Colors.green[300],
            main: Colors.green[500],
            dark: Colors.green[900],
        },
        ng: {
            light: Colors.red[300],
            main: Colors.red[500],
            dark: Colors.red[900],
        },
        in_progress: {
            light: Colors.orange[300],
            main: Colors.orange[500],
            dark: Colors.orange[900],
        },

        primary: {
            light: "#0081a9",
            main: "#0081a9",
            dark: "#0081a9",
            contrastText: '#FFFFFF', // テキストの色
        },
        secondary: {
            light: Colors.red[200],
            main: Colors.red[700],
            dark: Colors.red[900],
            contrastText: '#000',
        },
    },
    overrides: {
        MuiTab: {
            'root': {
                minWidth: "75px !important",  // override
            },
        }
    }
});

export default theme