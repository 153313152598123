
// ------------------------------------------------------------------------------------------------------------------ //
// Redux events
// ------------------------------------------------------------------------------------------------------------------ //
// ビュー切り替え
export const FRONTEND_CHANGE_VIEW = "FRONTEND_CHANGE_VIEW";

// 左上ハンバーガメニューのクリック
export const FRONTEND_APP_BAR_ONCLICK_LEFT_TOP_MENU = "FRONTEND_APP_BAR_ONCLICK_LEFT_TOP_MENU";

// ログインユーザー情報の取得完了
export const ON_LOAD_USERINFO = "ON_LOAD_USERINFO";

export const RESET_NOTIFICATIONS = "RESET_NOTIFICATIONS";

// ------------------------------------------------------------------------------------------------------------------ //
// 画面
// ------------------------------------------------------------------------------------------------------------------ //
// マイ予約
export const FRONTEND_VIEW_MY_RESERVATION = "/frontend/reservations";
// 利用中
export const FRONTEND_VIEW_MY_USING       = "/frontend/rent";
// 利用履歴
export const FRONTEND_VIEW_MY_HISTORY     = "/frontend/history";
// 登録内容
export const FRONTEND_VIEW_MY_INFO        = "/frontend/profile";
// 本人確認書類
export const FRONTEND_VIEW_IDENTIFY       = "/frontend/identification";
// メッセージ
export const FRONTEND_VIEW_MY_MESSAGE     = "/frontend/messages";

// ------------------------------------------------------------------------------------------------------------------ //
// 定数
// ------------------------------------------------------------------------------------------------------------------ //
export const LEFT_MENU_WIDTH = 250;
export const RIGHT_CALENDAR_WIDTH = 280;

// ------------------------------------------------------------------------------------------------------------------ //
// API
// ------------------------------------------------------------------------------------------------------------------ //
// 予約登録
export const API_APPLY_EVENT          = "API_APPLY_EVENT";
// ユーザー情報取得
export const API_GET_MY_INFORMATION   = "API_GET_MY_INFORMATION";
// ユーザー予約情報
export const API_GET_USER_EVENT_LIST  = "API_GET_USER_EVENT_LIST";
// カスタマー情報の取得
export const API_GET_CUSTOMER_INFORMATION   = "API_GET_CUSTOMER_INFORMATION";
// クーポン利用可否の問い合わせ
export const API_CHECK_COUPON   = "API_CHECK_COUPON";

export const RESET_ITEMS   = "RESET_ITEMS";

export const RESET_CUSTOMER   = "RESET_CUSTOMER";