let ja = {
    "translation" : {
        "sign_in": "ログイン",
        "email_address": "メールアドレス",
        "password": "パスワード",
        "remember_me": "次回から入力を省略",
        "fogot_password": "パスワードを忘れた",
        "sign_up": "利用登録",

        "input_required": "入力は必須です。",
        "invalid_email_format": "メールアドレスの形式ではありません。",

        "alert": "メールアドレスまたはパスワードが違います。",
        "notice": "パスワード再設定のご案内を送信しました。メールをご確認ください。",
        "signed_out": "ログアウトしました。"
    }
};

export default ja;
