/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getReservation = /* GraphQL */ `
  query GetReservation($id: ID!) {
    getReservation(id: $id) {
      id
      customer_id
      order_id
      edit_account_id
    }
  }
`;
export const listReservations = /* GraphQL */ `
  query ListReservations(
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customer_id
        order_id
        edit_account_id
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      customer_id
      channel
      sender_type
      sender_id
      body
      message_type
      image_base64
      send_at
      createdAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customer_id
        channel
        sender_type
        sender_id
        body
        message_type
        image_base64
        send_at
        createdAt
      }
      nextToken
    }
  }
`;
export const reservationsByCustomerId = /* GraphQL */ `
  query ReservationsByCustomerId(
    $customer_id: Int
    $sortDirection: ModelSortDirection
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reservationsByCustomerId(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        order_id
        edit_account_id
      }
      nextToken
    }
  }
`;
export const messagesByChannel = /* GraphQL */ `
  query MessagesByChannel(
    $channel: String
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByChannel(
      channel: $channel
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        channel
        sender_type
        sender_id
        body
        message_type
        image_base64
        send_at
        createdAt
      }
      nextToken
    }
  }
`;
export const messagesByChannelSortedBySendAt = /* GraphQL */ `
  query MessagesByChannelSortedBySendAt(
    $channel: String
    $send_at: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByChannelSortedBySendAt(
      channel: $channel
      send_at: $send_at
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        channel
        sender_type
        sender_id
        body
        message_type
        image_base64
        send_at
        createdAt
      }
      nextToken
    }
  }
`;
export const messagesByChannelSortedByCreatedAt = /* GraphQL */ `
  query MessagesByChannelSortedByCreatedAt(
    $channel: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByChannelSortedByCreatedAt(
      channel: $channel
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        channel
        sender_type
        sender_id
        body
        message_type
        image_base64
        send_at
        createdAt
      }
      nextToken
    }
  }
`;
export const messagesByCustomerId = /* GraphQL */ `
  query MessagesByCustomerId(
    $customer_id: Int
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByCustomerId(
      customer_id: $customer_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer_id
        channel
        sender_type
        sender_id
        body
        message_type
        image_base64
        send_at
        createdAt
      }
      nextToken
    }
  }
`;
