import React from 'react';

import {MuiThemeProvider} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container';

import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider'
// ------------------------------------------------------------------------------------------------------------------ //
// Theme
// ------------------------------------------------------------------------------------------------------------------ //
import theme from './../../../theme/theme';
// ------------------------------------------------------------------------------------------------------------------ //
// initialize i18n
// ------------------------------------------------------------------------------------------------------------------ //
import i18n from "./../../../libs/i18n";

import ja from './locale/ja';
import en from './locale/en';
// ------------------------------------------------------------------------------------------------------------------ //
// import Validator
// ------------------------------------------------------------------------------------------------------------------ //
import ValidationForm from './../../../libs/ValidationForm';
// ------------------------------------------------------------------------------------------------------------------ //
// icon components
// ------------------------------------------------------------------------------------------------------------------ //
import Email from '@material-ui/icons/Email'
import Done from '@material-ui/icons/Done'
import DeviseNavigationForm from '../DeviseNavigationFooter/DeviseNavigationFooter';

// import * as icons from '@material-ui/icons/';

const style = {
    root: {
        height: '90vh',
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    social_buttons:{
        marginTop: 30
    },
    container: {
        marginTop: 30
    },
    instruction: {
        marginTop: 20
    }
};

i18n.addResourceBundle("en", "translation", en.translation);
i18n.addResourceBundle("ja", "translation", ja.translation);

const iconComponents = {
    email : Email,
    done : Done
};

// ------------------------------------------------------------------------------------------------------------------ //
// component
// ------------------------------------------------------------------------------------------------------------------ //
class NotificationMessage extends ValidationForm
{
    constructor(props)
    {
        super();
    }


    render()
    {
        const Icon = iconComponents[this.props.icon];

        return (
            <MuiThemeProvider theme={theme}>
                <Grid container component="main" className={this.props.classes.root}>
                    <CssBaseline />
                    <Grid item xs={false} sm={4} md={7} className={this.props.classes.image} />
                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>

                        <Container component="main" maxWidth="xs" className={this.props.classes.container}>
                            <Avatar className={this.props.classes.avatar}>
                                <Icon/>
                            </Avatar>

                            <Typography component="h1" variant="h5">
                                {i18n.t(this.props.title)}
                            </Typography>

                            <div className={this.props.classes.instruction}>{i18n.t(this.props.instruction)}</div>

                            <Divider variant="middle" style={{marginTop: '30px', marginBottom: '30px'}} />

                            <DeviseNavigationForm
                                sign_in={this.props.footer_navi.navi_sign_in}
                                sign_up={this.props.footer_navi.navi_sign_up}
                                go_top={this.props.footer_navi.navi_go_top}
                            />

                        </Container>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(style)(NotificationMessage);
