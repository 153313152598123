import moment from "moment";
import i18n from "./i18n";
import Axios from 'axios';
import ReactGA from 'react-ga';

import * as FrontendStatic from "../bundles/Frontend/FrontendStatic";
import * as BackendStatic from "../bundles/Backend/BackendStatic";
import * as SharekanStatic from "../bundles/SharekanStatic";
import * as Color from '@material-ui/core/colors';
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";

import {ErrorMessageProtocol} from "../bundles/base/ErrorMessage/ErrorMessage";

import * as HolidayJp from '@holiday-jp/holiday_jp';

import Amplify, {API, graphqlOperation, Storage} from 'aws-amplify';
import config from "../../../src/aws-exports";
import {messagesByChannelSortedByCreatedAt} from '../../../src/graphql/queries';
import {createMessage, createReservation, updateReservation} from '../../../src/graphql/mutations';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

Amplify.configure(config);

export const BOOKED_TYPE_INCLUDED = 1;
export const BOOKED_TYPE_AFTER_START = 2;
export const BOOKED_TYPE_BEFORE_END = 3;
export const BOOKED_TYPE_ALL = 4;

const priority_table = [
    Color.red[200], //pink[200],
    Color.purple[200], //deepPurple[200],
    Color.indigo[200], //blue[200],
    Color.lightBlue[200], //cyan[200],
    Color.teal[200], //green[200],
    Color.lightGreen[200], //lime[200],
    Color.yellow[200], //amber[200],
    Color.orange[200], //deepOrange[200],
    Color.brown[200], //grey[200],
    Color.blueGrey[200]
];

const colors = {
    amber: Color.amber[500],
    blue: Color.blue[500],
    blueGrey: Color.blueGrey[500],
    brown: Color.brown[500],
    common: Color.common[500],
    cyan: Color.cyan[500],
    deepOrange: Color.deepOrange[500],
    deepPurple: Color.deepPurple[500],
    green: Color.green[500],
    grey: Color.grey[500],
    indigo: Color.indigo[500],
    lightBlue: Color.lightBlue[500],
    lightGreen: Color.lightGreen[500],
    lime: Color.lime[500],
    orange: Color.orange[500],
    pink: Color.pink[500],
    purple: Color.purple[500],
    red: Color.red[500],
    teal: Color.teal[500],
    yellow: Color.yellow[500],
};

export default class Toolkit
{
    static FRONTEND_HOST = '';

    static deepCopy(obj)
    {
        let check = () =>
        {
            //　Object||ArrayならリストにINして循環参照チェック
            let checkList = [];
            return function (key, value)
            {
                // 初回用
                if (key === '')
                {
                    checkList.push(value);
                    return value;
                }
                // Node,Elementの類はカット
                if (value instanceof Node)
                {
                    return undefined;
                }
                // Object,Arrayなら循環参照チェック
                if (typeof value === 'object' && value !== null)
                {
                    return checkList.every(function (v, i, a)
                    {
                        return value !== v;
                    }) ? value : undefined;
                }
                return value;
            }
        };

        return JSON.parse(JSON.stringify(obj, check()));
    }

    static getWindowSize()
    {
        var w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            w = w.innerWidth || e.clientWidth || g.clientWidth,
            h = w.innerHeight || e.clientHeight || g.clientHeight;

        return {
            width: w,
            height: h
        };
    }

    static isSmartPhone()
    {
        const ua = window.navigator.userAgent.toLowerCase();
        return ua.indexOf('iphone') > 0 || ua.indexOf('ipod') > 0 || ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0;
    }

    static setCookie(key, value)
    {
        document.cookie = key + '=' + encodeURIComponent(value);
    }

    static getCookie(key)
    {
        console.log(document.cookie);
        for (let c of document.cookie.split(/;[ ]*/))
        {
            let cArray = c.split('=');
            if (cArray[0] === key)
                return decodeURIComponent(cArray[1]);
        }
        return null;
    }

    static deleteCookie(key)
    {
        if (document.cookie)
        {
            for (let c of document.cookie.split(/;[ ]*/))
            {
                let cArray = c.split('=');
                if (cArray[0] === key)
                {
                    document.cookie = c + ";  max-age=0";
                    return;
                }
            }
        }
    }

    static isDummyEmail(email)
    {
        return email.toString().match(/dummy_[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/)
    }

    static generateId()
    {
        var random = Math.floor(Math.random() * 100000);

        return random + new Date().getTime();
    }

    static getDisplayOrderId(event)
    {
        if (event.order.order_id && String(event.order.order_id).startsWith("_new_"))
            return '新規予約';
        let order_id = event.order.id;
        if (String(order_id).startsWith("_new_"))
            return order_id;
        if (Number(order_id) < 100000000)
            order_id = ('00000000' + order_id).slice(-8);
        return 'SH' + order_id;
    }

    static generateUuid()
    {
        let chars = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
        for (let i = 0, len = chars.length; i < len; i++)
        {
            switch (chars[i])
            {
                case "x":
                    chars[i] = Math.floor(Math.random() * 16).toString(16);
                    break;
                case "y":
                    chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
                    break;
            }
        }
        return chars.join("");
    }

    // -------------------------------------------------------------------------------------------------------------- //
    // Component系
    // -------------------------------------------------------------------------------------------------------------- //
    static hourmins(min = '00:00', max = '24:00')
    {
        let [min_hour, min_min] = min.split(":");
        let min_step = Number(min_hour) * 2 + (Number(min_min) === 30 ? 1 : 0);

        let [max_hour, max_min] = max.split(":");
        let max_step = Number(max_hour) * 2 + (Number(max_min) === 30 ? 1 : 0);

        let selectList = [];
        // for (let hour = 0; hour <= 24; hour++)
        // {
        //     let displayHour = hour < 10 ? "0" + hour : hour;
        //
        //     for (let min = 0; min < 60; min += 30)
        //     {
        //         if (hour !== 24 || min === 0)
        //         {
        //             let displayMin = min < 10 ? "0" + min : min;
        //
        //             selectList.push(
        //                 <MenuItem key={"hourmins_" + this.generateId()} value={displayHour + ":" + displayMin}>{displayHour + ":" + displayMin}</MenuItem>
        //             );
        //         }
        //     }
        // }

        for (let i = min_step; i <= max_step; i++)
        {
            let h = ('0' + Math.floor(i / 2)).slice(-2);
            let m = i % 2 === 0 ? '00' : '30';
            selectList.push(
                <MenuItem key={"hourmins_" + this.generateId()} value={h + ":" + m}>{h + ":" + m}</MenuItem>
            );
        }

        return selectList;
    }

    /**
     * @param datetime_str {string}
     * @returns {moment.Moment}
     */
    static getEndOfReservation(datetime_str)
    {
        let datetime = moment(datetime_str);
        if (datetime.hours() === 0 && datetime.minutes() === 0)
            datetime.subtract(1, 'days');
        return datetime;
    }

    /**
     * 予約終了日付設定
     * @param datetime {moment.Moment}
     */
    static setEndDateOfReservation(datetime)
    {
        if (datetime.hours() === 0 && datetime.minutes() === 0)
            return datetime.add(1, 'days');
        return datetime
    }

    /**
     * 予約終了時刻設定
     * @param origin {moment.Moment}
     * @param hour {int}
     * @param minute {int}
     */
    static setEndTimeOfReservation(origin, hour, minute)
    {
        if ((hour === 0 && minute === 0) || (origin.hours() === 0 && origin.minutes() === 0))
            origin.subtract(1, 'days');
        origin.hours(hour);
        origin.minutes(minute);
        return origin
    }

    static getItemDescriptionOneLine(item_description)
    {
        let _description = "";
        if (item_description)
            // _description = JSON.parse(item_description).blocks[0].text;
            _description = (item_description.split(/\n|\r|\n\r/))[0];
        return _description;
    }

    // -------------------------------------------------------------------------------------------------------------- //
    // 計算系
    // -------------------------------------------------------------------------------------------------------------- //

    static tax()
    {
        return 0.1;
    }

    static calculateTotal(event, item_list = null)
    {
        let bill = event.bill;

        // ---------------------------------------------------------------------------------------------------------- //
        // 支払
        // ---------------------------------------------------------------------------------------------------------- //
        // 元価格合計
        let totalPrice = 0;
        // 割引・割増し合計
        let totalPriceExtra = 0;
        // 割引・割増し適用後合計
        let totalPriceWithExtra = 0;

        // extra合計
        let totalExtra = 0;
        // extraクーポン合計
        let totalExtraPriceExtra = 0;

        console.log("************************** calculateTotal **************************");
        console.log(event);
        console.log(event.another_reservation);

        // ---------------------------------------------------------------------------------------------------------- //
        // 支払
        // ---------------------------------------------------------------------------------------------------------- //
        // 支払い金額合計
        let paidTotal = 0;
        // 払い戻し金額合計
        let refundedTotal = 0;
        // 請求中合計
        let billingTotal = 0;
        // 支払い後残金
        let paidAfterLeft = 0;

        // ---------------------------------------------------------------------------------------------------------- //
        // 主予約
        // ---------------------------------------------------------------------------------------------------------- //
        if (event.item_reservation)
        {
            let mainItemReservation = event.item_reservation;
            let price_extra = parseInt(mainItemReservation.price_extra ? mainItemReservation.price_extra : 0);
            totalPriceExtra += price_extra; // 割引・割増し合計

            let sum_price = 0;
            if (item_list != null)
            {
                let itemEntity = Toolkit.findSelectedItemById(item_list, mainItemReservation.item_id);
                let price = Toolkit.calculateItemReservationPrice(event, item_list, mainItemReservation, itemEntity);
                sum_price += price * mainItemReservation.amount;
            }
            else if (mainItemReservation.price_summary > 0)
            {
                sum_price = mainItemReservation.price_summary * mainItemReservation.amount;
            }

            totalPrice += sum_price; // 元価格合計
            totalPriceWithExtra += sum_price + price_extra; // 割引・割増し適用後合計
            console.log(" + main item (" + mainItemReservation.id + ") : " + totalPriceWithExtra);
        }

        // ---------------------------------------------------------------------------------------------------------- //
        // 副予約
        // ---------------------------------------------------------------------------------------------------------- //
        if (event.another_reservation)
        {
            event.another_reservation.map((subItemReservation) =>
            {
                console.log("  - has an another reservation : " + subItemReservation.item_reservation_id);

                if (subItemReservation.id !== event.item_reservation.id)
                {
                    let price_extra = parseInt(subItemReservation.price_extra ? subItemReservation.price_extra : 0);
                    totalPriceExtra += price_extra; // 割引・割増し合計

                    let sum_price = 0;
                    if (item_list != null)
                    {
                        let itemEntity = Toolkit.findSelectedItemById(item_list, subItemReservation.item_id);
                        let price = Toolkit.calculateItemReservationPrice(event, item_list, subItemReservation, itemEntity);
                        sum_price += price * subItemReservation.amount;
                    }
                    else if (subItemReservation.price_summary > 0)
                    {
                        sum_price = subItemReservation.price_summary * subItemReservation.amount;
                    }

                    totalPrice += sum_price; // 元価格合計
                    totalPriceWithExtra += sum_price + price_extra; // 割引・割増し適用後合計
                    console.log(" - sub item (" + subItemReservation.id + ") : " + totalPriceWithExtra);
                }
            });
        }
        else
        {
            console.log("  - No another reservation");
        }

        // ---------------------------------------------------------------------------------------------------------- //
        // Extra
        // ---------------------------------------------------------------------------------------------------------- //
        if (event.order.reservation_extra)
        {
            event.order.reservation_extra.map((reservationExtraEntity) =>
            {
                totalExtra += parseInt(reservationExtraEntity.price);

                console.log(" + extra : " + reservationExtraEntity.price);
            });
        }

        let invoiceTotal = parseInt(totalPriceWithExtra) + parseInt(totalExtra);
        if (invoiceTotal < 0)
            invoiceTotal = 0;
        let invoiceTax = Math.floor(invoiceTotal * this.tax());

        // ---------------------------------------------------------------------------------------------------------- //
        // 支払い
        // ---------------------------------------------------------------------------------------------------------- //
        if (bill && bill.payment)
        {
            bill.payment.map((paymentEntity) =>
            {
                switch (paymentEntity.payment_status)
                {
                    case BackendStatic.PAYMENT_STATUS_NOT_YET:
                    case BackendStatic.PAYMENT_STATUS_BILLING:
                    case BackendStatic.PAYMENT_STATUS_FAILED:
                    case BackendStatic.PAYMENT_STATUS_AUTHORIZE:
                    case BackendStatic.PAYMENT_STATUS_UNKNOWN:
                        billingTotal += parseInt(paymentEntity.price);
                        break;
                    case BackendStatic.PAYMENT_STATUS_PAID:
                        paidTotal += parseInt(paymentEntity.paid_price);
                        break;
                    case BackendStatic.PAYMENT_STATUS_REFUNDED:
                        refundedTotal += parseInt(paymentEntity.paid_price);
                        break;
                }
            });
        }

        // ---------------------------------------------------------------------------------------------------------- //
        // 未払い残金
        // ---------------------------------------------------------------------------------------------------------- //
        paidAfterLeft = invoiceTotal + invoiceTax - paidTotal - billingTotal;

        let ret = {
            // アイテム利用
            totalPrice: totalPrice,                   // 元価格合計
            totalPriceExtra: totalPriceExtra,         // 割引・割増し合計
            totalPriceWithExtra: totalPriceWithExtra, // 割引・割増し適用後合計

            // extra
            totalExtra: totalExtra,                   // Extra合計

            invoiceTotal: invoiceTotal, //請求総額
            invoiceTax: invoiceTax, // 税金

            paidTotal: paidTotal,                     // 支払い総額
            refundedTotal: refundedTotal,
            billingTotal: billingTotal,
            paidAfterLeft: paidAfterLeft,   // 残金
        };
        console.log(ret);

        return ret;
    }

    // -------------------------------------------------------------------------------------------------------------- //
    // 見積もりロジック
    // -------------------------------------------------------------------------------------------------------------- //

    /**
     * 予約の利用料金計算
     * @param event イベント
     * @param item_list アイテムリスト
     * @param from 計算上の開始日時指定
     * @param to　計算上の終了日時指定
     * @returns {number}
     */
    static calculateTotalPrice(event, item_list, from = null, to = null)
    {
        let total = 0;
        let detail = [];

        // ---------------------------------------------------------------------------------------------------------- //
        // 主予約の見積
        // ---------------------------------------------------------------------------------------------------------- //
        {
            let itemEntity = Toolkit.findSelectedItemById(item_list, event.item_reservation.item_id);
            if (itemEntity.item_id)
            {
                let price = Toolkit.calculateItemReservationPrice(event, item_list, event.item_reservation, itemEntity, from, to);
                let sum_price = price * event.item_reservation.amount;
                total += sum_price;

                detail.push({
                    type: itemEntity.reservation_type,
                    item_name: itemEntity.item_name,
                    price: price,
                    amount: event.item_reservation.amount,
                    sum_price: sum_price,
                })
            }
        }

        // ---------------------------------------------------------------------------------------------------------- //
        // 副予約の見積
        // ---------------------------------------------------------------------------------------------------------- //
        event.another_reservation.map((itemReservationEntity) =>
        {
            if (itemReservationEntity.id !== event.item_reservation.id)
            {
                let itemEntity = Toolkit.findSelectedItemById(item_list, itemReservationEntity.item_id);
                let price = Toolkit.calculateItemReservationPrice(event, item_list, itemReservationEntity, itemEntity, from, to);
                let sum_price = price * itemReservationEntity.amount;
                total += sum_price;

                detail.push({
                    type: itemEntity.reservation_type,
                    item_name: itemEntity.item_name,
                    price: price,
                    amount: itemReservationEntity.amount,
                    sum_price: sum_price,
                });
            }
        });

        return {
            total: total,
            detail: detail
        };
    }

    /**
     * クーポン割引計算
     * @param couponEntity 適用するクーポン
     * @param event イベント
     * @param item_list アイテムリスト
     * @returns {number}
     */
    static calculateDiscount(couponEntity, event, item_list)
    {
        console.log("calculate discount...");

        //最低利用時間の確認
        if (couponEntity.min_rent_hours > 0)
        {
            if (couponEntity.item_id > 0)
            {
                for (let itemReservation of Toolkit.findItemReservationsInEvent(event, couponEntity.item_id))
                {
                    if (moment(itemReservation.datetime_from).add(couponEntity.min_rent_hours, 'h') > moment(itemReservation.datetime_to))
                        return 0;
                }
            }
            else
            {
                if (moment(event.item_reservation.datetime_from).add(couponEntity.min_rent_hours, 'h') > moment(event.item_reservation.datetime_to))
                    return 0;
            }
        }

        let total = 0;
        let getFrom = _reservation_from =>
        {
            if (couponEntity.is_target)
            {
                let from = moment(couponEntity.target_datetime_from);
                if (from.isAfter(moment(_reservation_from)))
                    return couponEntity.target_datetime_from;
            }
            return null;
        };

        let getTo = _reservation_to =>
        {
            if (couponEntity.is_target)
            {
                let to = moment(couponEntity.target_datetime_to);
                if (to.isBefore(moment(_reservation_to)))
                    return couponEntity.target_datetime_to;
            }
            return null;
        };

        if (couponEntity.item_id)
        {
            for (let itemReservation of Toolkit.findItemReservationsInEvent(event, couponEntity.item_id))
            {
                let item = Toolkit.findSelectedItemById(item_list, itemReservation.item_id);
                total += Toolkit.calculateItemReservationPrice(event, item_list, itemReservation, item, getFrom(itemReservation.datetime_from), getTo(itemReservation.datetime_to)) * itemReservation.amount;
            }
        }
        else
        {
            {
                let itemEntity = Toolkit.findSelectedItemById(item_list, event.item_reservation.item_id);
                total += Toolkit.calculateItemReservationPrice(event, item_list, event.item_reservation, itemEntity, getFrom(event.item_reservation.datetime_from), getTo(event.item_reservation.datetime_to)) * event.item_reservation.amount;
            }

            event.another_reservation.map((itemReservation) =>
            {
                if (itemReservation.id !== event.item_reservation.id)
                {
                    let item = Toolkit.findSelectedItemById(item_list, itemReservation.item_id);
                    total += Toolkit.calculateItemReservationPrice(event, item_list, itemReservation, item, getFrom(itemReservation.datetime_from), getTo(itemReservation.datetime_to)) * itemReservation.amount;
                }
            });
        }

        switch (couponEntity.discount_type)
        {
            case BackendStatic.COUPON_DISCOUNT_TYPE_PRICE:
                return total > couponEntity.discount ? couponEntity.discount : total;
            case BackendStatic.COUPON_DISCOUNT_TYPE_RATIO:
                return Math.ceil(total * (couponEntity.discount / 100));
        }
        return 0;
    }

    /**
     * 割引適用後　税別合計金額
     * @param applied_coupon_list クーポン一覧
     * @param event イベント
     * @param item_list アイテム一覧
     * @returns {number}
     */
    static calculateTotalPriceWithDiscount(applied_coupon_list, event, item_list)
    {
        let totalPriceEntity = Toolkit.calculateTotalPrice(event, item_list);
        let total = totalPriceEntity.total;
        let discount = 0;
        applied_coupon_list.map((couponEntity) =>
        {
            discount += Toolkit.calculateDiscount(couponEntity, event, item_list);
        });

        return total - discount;
    }

    /**
     * 税金計算
     * @param applied_coupon_list クーポン一覧
     * @param event イベント
     * @param item_list アイテム一覧
     * @returns {number}
     */
    static calculateTax(applied_coupon_list, event, item_list)
    {
        let sum = Toolkit.calculateTotalPriceWithDiscount(applied_coupon_list, event, item_list);
        let tax = sum * Toolkit.tax();

        return parseInt(tax);
    }

    /**
     * 指定した item_reservation の価格計算
     * @param event
     * @param item_list
     * @param itemReservation
     * @param itemEntity
     * @param from 計算上の開始日時指定
     * @param to　計算上の終了日時指定
     * @returns {number}
     */
    static calculateItemReservationPrice(event, item_list, itemReservation, itemEntity, from = null, to = null)
    {
        let price = 0;
        if (!from)
            from = itemReservation.datetime_from;
        if (!to)
            to = itemReservation.datetime_to;

        let lentDayList = Toolkit.createDateList(from, to);

        let startDay = moment(lentDayList[0]);
        let startDateTime = moment(from);

        let endDay = lentDayList.length === 1 ? moment(lentDayList[0]) : moment(lentDayList[lentDayList.length - 1]);
        let endDateTime = moment(to);

        lentDayList.some((date) =>
        {
            // その日の課金ルールを取得
            let appliedPrice = Toolkit.findAppliedPrice(date, itemEntity.item_prices);
            let thisDay = moment(date);

            switch (appliedPrice.price_unit)
            {
                case BackendStatic.PRICE_UNIT_MINUTE:
                    if (startDay.isSame(thisDay, "day") && endDay.isSame(thisDay, "day"))
                    {
                        // 開始日と終了日が同一
                        let differenceMin = endDateTime.diff(startDateTime, "minutes");
                        price += appliedPrice.price * differenceMin;

                        console.log("[PRICE_UNIT_HOUR] (" + date + ") same day : differenceMin=" + differenceMin + ", price=" + (appliedPrice.price * differenceMin));
                    }
                    else if (startDay.isSame(thisDay, "day"))
                    {
                        // 時間割：startDateTime ~ 23:59まで
                        let endOfDay = thisDay.clone().endOf("day").add(1, "seconds");
                        let differenceMin = endOfDay.diff(startDateTime, "minutes");

                        price += appliedPrice.price * differenceMin;

                        console.log("[PRICE_UNIT_HOUR] (" + date + ") start day : differenceMin=" + differenceMin + ", price=" + (appliedPrice.price * differenceMin));
                    }
                    else if (endDay.isSame(thisDay, "day"))
                    {
                        // 時間割：0:00 ~ endDateTime
                        let startOfDay = thisDay.clone().startOf("day");
                        let differenceMin = endDateTime.diff(startOfDay, "hours");

                        price += appliedPrice.price * differenceMin;

                        console.log("[PRICE_UNIT_HOUR] (" + date + ") end day : differenceMin=" + differenceMin + ", price=" + (appliedPrice.price * differenceMin));
                    }
                    else
                    {
                        // 丸一日課金
                        price += appliedPrice.price * 24 * 60;

                        console.log("[PRICE_UNIT_HOUR] (" + date + ") a day : price=" + appliedPrice.price);
                    }
                    break;

                case BackendStatic.PRICE_UNIT_HOUR:
                    if (startDay.isSame(thisDay, "day") && endDay.isSame(thisDay, "day"))
                    {
                        // 開始日と終了日が同一
                        let differenceHour = endDateTime.diff(startDateTime, "hours");
                        price += appliedPrice.price * differenceHour;

                        console.log("[PRICE_UNIT_HOUR] (" + date + ") same day : differenceHour=" + differenceHour + ", price=" + (appliedPrice.price * differenceHour));
                    }
                    else if (startDay.isSame(thisDay, "day"))
                    {
                        // 時間割：startDateTime ~ 23:59まで
                        let endOfDay = thisDay.clone().endOf("day").add(1, "seconds");
                        let differenceHour = endOfDay.diff(startDateTime, "hours");

                        price += appliedPrice.price * differenceHour;

                        console.log("[PRICE_UNIT_HOUR] (" + date + ") start day : differenceHour=" + differenceHour + ", price=" + (appliedPrice.price * differenceHour));
                    }
                    else if (endDay.isSame(thisDay, "day"))
                    {
                        // 時間割：0:00 ~ endDateTime
                        let startOfDay = thisDay.clone().startOf("day");
                        let differenceHour = endDateTime.diff(startOfDay, "hours");

                        price += appliedPrice.price * differenceHour;

                        console.log("[PRICE_UNIT_HOUR] (" + date + ") end day : differenceHour=" + differenceHour + ", price=" + (appliedPrice.price * differenceHour));
                    }
                    else
                    {
                        // 丸一日課金
                        price += appliedPrice.price * 24;

                        console.log("[PRICE_UNIT_HOUR] (" + date + ") a day : price=" + appliedPrice.price);
                    }
                    break;

                case BackendStatic.PRICE_UNIT_DAY:        // 日単位で課金
                    price += appliedPrice.price;

                    console.log("[PRICE_UNIT_DAY] (" + date + ") add price : " + appliedPrice.price);
                    break;

                case BackendStatic.PRICE_UNIT_DAY_HOUR:   // 日単位で課金
                    if (startDay.isSame(thisDay, "day") && endDay.isSame(thisDay, "day"))
                    {
                        // 開始日と終了日が同一
                        let differenceHour = endDateTime.diff(startDateTime, "hours");
                        let priceRatio = differenceHour / 24;

                        price += appliedPrice.price * priceRatio;

                        console.log("[PRICE_UNIT_DAY_HOUR] (" + date + ") same day : differenceHour=" + differenceHour + ", priceRatio=" + priceRatio + ", price=" + (appliedPrice.price * priceRatio));
                    }
                    else if (startDay.isSame(thisDay, "day"))
                    {
                        // 時間割：startDateTime ~ 23:59まで
                        let endOfDay = thisDay.clone().endOf("day").add(1, "seconds");
                        let differenceHour = endOfDay.diff(startDateTime, "hours");
                        let priceRatio = differenceHour / 24;

                        price += appliedPrice.price * priceRatio;

                        console.log("[PRICE_UNIT_DAY_HOUR] (" + date + ") start day : differenceHour=" + differenceHour + ", priceRatio=" + priceRatio + ", price=" + (appliedPrice.price * priceRatio));
                    }
                    else if (endDay.isSame(thisDay, "day"))
                    {
                        // 時間割：0:00 ~ endDateTime
                        let startOfDay = thisDay.clone().startOf("day");
                        let differenceHour = endDateTime.diff(startOfDay, "hours");
                        let priceRatio = differenceHour / 24;

                        price += appliedPrice.price * priceRatio;

                        console.log("[PRICE_UNIT_DAY_HOUR] (" + date + ") end day : differenceHour=" + differenceHour + ", priceRatio=" + priceRatio + ", price=" + (appliedPrice.price * priceRatio));
                    }
                    else
                    {
                        // 丸一日課金
                        price += appliedPrice.price;

                        console.log("[PRICE_UNIT_DAY_HOUR] (" + date + ") a day : price=" + appliedPrice.price);
                    }
                    break;

                case BackendStatic.PRICE_UNIT_LENT:      // 貸した回数で課金（貸出日の価格が適用）
                    if (startDay.isSame(thisDay, "day"))
                    {
                        price = appliedPrice.price;
                        console.log("[PRICE_UNIT_LENT] (" + date + ") add price : " + appliedPrice.price);
                    }
                    break;
                case BackendStatic.PRICE_UNIT_RATIO:      // 貸出アイテム額の割合課金
                    if (itemEntity.reservation_type === BackendStatic.ITEM_RESERVATION_TYPE_OPTION)
                    {
                        let f = thisDay.clone().startOf("day");
                        let t = thisDay.clone().endOf("day").add(1, "seconds");

                        if (startDay.isSame(thisDay, "day") && endDay.isSame(thisDay, "day"))
                        {
                            // 開始日と終了日が同一
                            f = startDateTime;
                            t = endDateTime;
                        }
                        else if (startDay.isSame(thisDay, "day"))
                        {
                            // startDateTime ~ 23:59まで
                            f = startDateTime;
                        }
                        else if (endDay.isSame(thisDay, "day"))
                        {
                            // 0:00 ~ endDateTime
                            t = endDateTime;
                        }

                        for (let reservation of Toolkit.convertEventToReservations(event))
                        {
                            let item = Toolkit.findSelectedItemById(item_list, reservation.item_id);
                            if (item.reservation_type === BackendStatic.ITEM_RESERVATION_TYPE_ITEM)
                            {
                                let _from = f.clone();
                                let _to = t.clone();
                                let reservation_from = moment(reservation.datetime_from);
                                let reservation_to = moment(reservation.datetime_to);
                                if (_to.isBefore(reservation_from) || _from.isAfter(reservation_to)) continue;
                                if (_from.isSame(reservation_from, "day") && _from.isBefore(reservation_from)) _from = reservation_from;
                                if (_to.clone().subtract(1, 'second').isSame(reservation_to.clone().subtract(1, 'second'), "day") && _to.isAfter(reservation_to)) _to = reservation_to;
                                price += parseInt(Toolkit.calculateItemReservationPrice(event, item_list, reservation, item, _from, _to) * appliedPrice.price / 100);
                            }
                        }
                    }
                    break;
            }
        });

        return parseInt(price);
    }

    /**
     * 指定した item_reservation の価格内訳view
     * @param event
     * @param item_list
     * @param itemReservation
     * @param itemEntity
     * @returns Component
     */
    static calculateItemReservationDetail(event, item_list, itemReservation, itemEntity)
    {
        let details = [];
        let lentDayList = Toolkit.createDateList(itemReservation.datetime_from, itemReservation.datetime_to);

        let startDay = moment(lentDayList[0]);
        let startDateTime = moment(itemReservation.datetime_from);

        let endDay = lentDayList.length === 1 ? moment(lentDayList[0]) : moment(lentDayList[lentDayList.length - 1]);
        let endDateTime = moment(itemReservation.datetime_to);

        lentDayList.some((date) =>
        {
            // その日の課金ルールを取得
            let appliedPrice = Toolkit.findAppliedPrice(date, itemEntity.item_prices);
            let thisDay = moment(date);

            switch (appliedPrice.price_unit)
            {
                case BackendStatic.PRICE_UNIT_MINUTE:
                    let differenceMin = 0;
                    if (startDay.isSame(thisDay, "day") && endDay.isSame(thisDay, "day"))
                    {
                        // 開始日と終了日が同一
                        differenceMin = endDateTime.diff(startDateTime, "minutes");
                    }
                    else if (startDay.isSame(thisDay, "day"))
                    {
                        // 時間割：startDateTime ~ 23:59まで
                        let endOfDay = thisDay.clone().endOf("day").add(1, "seconds");
                        differenceMin = endOfDay.diff(startDateTime, "minutes");
                    }
                    else if (endDay.isSame(thisDay, "day"))
                    {
                        // 時間割：0:00 ~ endDateTime
                        let startOfDay = thisDay.clone().startOf("day");
                        differenceMin = endDateTime.diff(startOfDay, "hours");
                    }
                    else
                    {
                        // 一日
                        differenceMin = 24 * 60;
                    }

                    details.push(
                        <TableRow hover key={"im_row_" + date}>
                            <TableCell align={"center"}>{moment(date).format('YYYY/M/D') + " (" + differenceMin + "分)"}</TableCell>
                            <TableCell align={"center"}>{(appliedPrice.price).toLocaleString()}<br/>分単位</TableCell>
                            <TableCell align={"center"}>{parseInt(appliedPrice.price * differenceMin).toLocaleString()}</TableCell>
                        </TableRow>
                    );
                    break;

                case BackendStatic.PRICE_UNIT_HOUR:
                    let differenceHour = 0;

                    if (startDay.isSame(thisDay, "day") && endDay.isSame(thisDay, "day"))
                    {
                        // 開始日と終了日が同一
                        differenceHour = endDateTime.diff(startDateTime, "hours");
                    }
                    else if (startDay.isSame(thisDay, "day"))
                    {
                        // 時間割：startDateTime ~ 23:59まで
                        let endOfDay = thisDay.clone().endOf("day").add(1, "seconds");
                        differenceHour = endOfDay.diff(startDateTime, "hours");
                    }
                    else if (endDay.isSame(thisDay, "day"))
                    {
                        // 時間割：0:00 ~ endDateTime
                        let startOfDay = thisDay.clone().startOf("day");
                        differenceHour = endDateTime.diff(startOfDay, "hours");
                    }
                    else
                    {
                        // 丸一日課金
                        differenceHour = 24;
                    }

                    details.push(
                        <TableRow hover key={"im_row_" + date}>
                            <TableCell align={"center"}>{moment(date).format('YYYY/M/D') + " (" + differenceHour + "時間)"}</TableCell>
                            <TableCell align={"center"}>{(appliedPrice.price).toLocaleString()}<br/>時間単位</TableCell>
                            <TableCell align={"center"}>{parseInt(appliedPrice.price * differenceHour).toLocaleString()}</TableCell>
                        </TableRow>
                    );
                    break;

                case BackendStatic.PRICE_UNIT_DAY:        // 日単位で課金
                    details.push(
                        <TableRow hover key={"im_row_" + date}>
                            <TableCell align={"center"}>{moment(date).format('YYYY/M/D')}</TableCell>
                            <TableCell align={"center"}>{(appliedPrice.price).toLocaleString()}<br/>日単位</TableCell>
                            <TableCell align={"center"}>{(appliedPrice.price).toLocaleString()}</TableCell>
                        </TableRow>
                    );
                    break;

                case BackendStatic.PRICE_UNIT_DAY_HOUR:   // 日単位で課金
                {
                    let differenceHour = 0;
                    if (startDay.isSame(thisDay, "day") && endDay.isSame(thisDay, "day"))
                    {
                        // 開始日と終了日が同一
                        differenceHour = endDateTime.diff(startDateTime, "hours");
                    }
                    else if (startDay.isSame(thisDay, "day"))
                    {
                        // 時間割：startDateTime ~ 23:59まで
                        let endOfDay = thisDay.clone().endOf("day").add(1, "seconds");
                        differenceHour = endOfDay.diff(startDateTime, "hours");
                    }
                    else if (endDay.isSame(thisDay, "day"))
                    {
                        // 時間割：0:00 ~ endDateTime
                        let startOfDay = thisDay.clone().startOf("day");
                        differenceHour = endDateTime.diff(startOfDay, "hours");
                    }
                    else
                    {
                        // 丸一日課金
                        differenceHour = 24;
                    }

                    details.push(
                        <TableRow hover key={"im_row_" + date}>
                            <TableCell align={"center"}>{moment(date).format('YYYY/M/D') + " (" + differenceHour + "時間)"}</TableCell>
                            <TableCell align={"center"}>{(appliedPrice.price).toLocaleString()}<br/>日単位/時間割</TableCell>
                            <TableCell align={"center"}>{parseInt(appliedPrice.price * differenceHour / 24).toLocaleString()}</TableCell>
                        </TableRow>
                    );
                }
                    break;

                case BackendStatic.PRICE_UNIT_LENT:      // 貸した回数で課金（貸出日の価格が適用）
                    if (startDay.isSame(thisDay, "day"))
                    {
                        details.push(
                            <TableRow hover key={"im_row_" + date}>
                                <TableCell align={"center"}>{startDay.format('YYYY/M/D')}</TableCell>
                                <TableCell align={"center"}>{(appliedPrice.price).toLocaleString()}<br/>貸出回数</TableCell>
                                <TableCell align={"center"}>{(appliedPrice.price).toLocaleString()}</TableCell>
                            </TableRow>
                        );
                    }
                    break;
                case BackendStatic.PRICE_UNIT_RATIO:      // 貸出アイテム額の割合課金
                    if (itemEntity.reservation_type === BackendStatic.ITEM_RESERVATION_TYPE_OPTION)
                    {
                        let f = thisDay.clone().startOf("day");
                        let t = thisDay.clone().endOf("day").add(1, "seconds");

                        if (startDay.isSame(thisDay, "day") && endDay.isSame(thisDay, "day"))
                        {
                            // 開始日と終了日が同一
                            f = startDateTime;
                            t = endDateTime;
                        }
                        else if (startDay.isSame(thisDay, "day"))
                        {
                            // startDateTime ~ 23:59まで
                            f = startDateTime;
                        }
                        else if (endDay.isSame(thisDay, "day"))
                        {
                            // 0:00 ~ endDateTime
                            t = endDateTime;
                        }

                        let price = 0;
                        for (let reservation of Toolkit.convertEventToReservations(event))
                        {
                            let item = Toolkit.findSelectedItemById(item_list, reservation.item_id);
                            if (item.reservation_type === BackendStatic.ITEM_RESERVATION_TYPE_ITEM)
                            {
                                let _from = f.clone();
                                let _to = t.clone();
                                let reservation_from = moment(reservation.datetime_from);
                                let reservation_to = moment(reservation.datetime_to);
                                if (_to.isBefore(reservation_from) || _from.isAfter(reservation_to)) continue;
                                if (_from.isSame(reservation_from, "day") && _from.isBefore(reservation_from)) _from = reservation_from;
                                if (_to.clone().subtract(1, 'second').isSame(reservation_to.clone().subtract(1, 'second'), "day") && _to.isAfter(reservation_to)) _to = reservation_to;
                                price += parseInt(Toolkit.calculateItemReservationPrice(event, item_list, reservation, item, _from, _to) * appliedPrice.price / 100);
                            }
                        }
                        details.push(
                            <TableRow hover key={"im_row_" + date}>
                                <TableCell align={"center"}>{thisDay.format('YYYY/M/D')}</TableCell>
                                <TableCell align={"center"}>{appliedPrice.price}%<br/>貸出アイテム額の割合課金</TableCell>
                                <TableCell align={"center"}>{(price).toLocaleString()}</TableCell>
                            </TableRow>
                        );
                    }
                    break;
            }
        });

        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align={"center"}>期間</TableCell>
                        <TableCell align={"center"}>単価</TableCell>
                        <TableCell align={"center"}>小計</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {details}
                </TableBody>
            </Table>
        );
    }

    // -------------------------------------------------------------------------------------------------------------- //
    // イベントのステータス
    // -------------------------------------------------------------------------------------------------------------- //

    /**
     * 指定イベントの注文ステータス（ItemReservationの予約ステータスの合成）
     * @param event
     * @returns {number}
     */
    static currentOrderStatus(event)
    {
        let orderStatus = Toolkit.itemOrderStatus(event.item_reservation);

        event.another_reservation.map((itemReservationEntity) =>
        {
            let next = Toolkit.itemOrderStatus(itemReservationEntity);

            if (orderStatus > next)
                orderStatus = next;
        });

        return orderStatus;
    }

    /**
     * ItemReservation単体のの注文ステータス
     * @param itemReservationEntity
     * @returns {number}
     */
    static itemOrderStatus(itemReservationEntity)
    {
        if (itemReservationEntity)
        {
            switch (itemReservationEntity.reservation_status)
            {
                // 予約時間・アイテム・顧客の登録
                case BackendStatic.RESERVATION_STATUS_NOT_APPROVED: // 仮予約（承認待ち）
                case BackendStatic.RESERVATION_STATUS_RESCHEDULING: // リスケ依頼中
                    return BackendStatic.ORDER_STATUS_1_NEW_RESERVATION;

                // 利用料金の請求
                case BackendStatic.RESERVATION_STATUS_WAIT_PAYMENT: // 支払い待ち
                    return BackendStatic.ORDER_STATUS_2_PAYMENT;

                // 仮予約の承認
                case BackendStatic.RESERVATION_STATUS_APPROVED:     // 予約
                    return BackendStatic.ORDER_STATUS_3_APPROVE;

                // 貸渡
                case BackendStatic.RESERVATION_STATUS_USING:        // 利用中
                case BackendStatic.RESERVATION_STATUS_NO_SHOW:      // ノーショー
                    return BackendStatic.ORDER_STATUS_4_LENT;

                // 返却
                case BackendStatic.RESERVATION_STATUS_DONE:         // 利用終了
                case BackendStatic.RESERVATION_STATUS_REJECT:       // リジェクト
                case BackendStatic.RESERVATION_STATUS_CANCELED:     // キャンセル
                case BackendStatic.RESERVATION_STATUS_DELETED:      // 削除
                    return BackendStatic.ORDER_STATUS_5_RETURN;
            }
        }
        return BackendStatic.ORDER_STATUS_5_RETURN;
    }

    static showLentOperation(item_reservation)
    {
        if (!item_reservation)
            return false;

        switch (item_reservation.reservation_status)
        {
            case BackendStatic.RESERVATION_STATUS_NOT_APPROVED: // 仮予約（承認待ち）
            case BackendStatic.RESERVATION_STATUS_REJECT:       // リジェクト
            case BackendStatic.RESERVATION_STATUS_WAIT_PAYMENT: // 支払い待ち
            case BackendStatic.RESERVATION_STATUS_RESCHEDULING: // リスケ依頼中
            case BackendStatic.RESERVATION_STATUS_CANCELED:     // キャンセル
                return false;

            case BackendStatic.RESERVATION_STATUS_APPROVED:     // 予約
            case BackendStatic.RESERVATION_STATUS_USING:        // 利用中
            case BackendStatic.RESERVATION_STATUS_NO_SHOW:      // ノーショー
            case BackendStatic.RESERVATION_STATUS_DONE:         // 利用終了
                return true;

            case BackendStatic.RESERVATION_STATUS_DELETED:      // 削除
                return false;
        }
        return false;
    }

    static showReturnOperation(item_reservation)
    {
        if (!item_reservation)
            return false;

        switch (item_reservation.reservation_status)
        {
            case BackendStatic.RESERVATION_STATUS_NOT_APPROVED: // 仮予約（承認待ち）
            case BackendStatic.RESERVATION_STATUS_REJECT:       // リジェクト
            case BackendStatic.RESERVATION_STATUS_WAIT_PAYMENT: // 支払い待ち
            case BackendStatic.RESERVATION_STATUS_RESCHEDULING: // リスケ依頼中
            case BackendStatic.RESERVATION_STATUS_CANCELED:     // キャンセル
                return false;

            case BackendStatic.RESERVATION_STATUS_APPROVED:     // 予約
                return false;

            case BackendStatic.RESERVATION_STATUS_USING:        // 利用中
            case BackendStatic.RESERVATION_STATUS_NO_SHOW:      // ノーショー
            case BackendStatic.RESERVATION_STATUS_DONE:         // 利用終了
                return true;

            case BackendStatic.RESERVATION_STATUS_DELETED:      // 削除
                return false;
        }
        return false;
    }

    /**
     * payment の payment status から、bill の payment status の決定
     * @returns {number}
     */
    static billPaymentStatus(event, item_list)
    {
        let status = {};
        if (event && event.bill && event.bill.payment)
        {
            event.bill.payment.map((paymentEntity) =>
            {
                if (!status[paymentEntity.payment_status])
                {
                    status[paymentEntity.payment_status] = 1;
                }
                else
                {
                    status[paymentEntity.payment_status] += 1;
                }
            });
        }

        let result = BackendStatic.PAYMENT_STATUS_PAID;

        // 見積もり
        let calculate = Toolkit.calculateTotal(event, item_list);

        if (calculate.paidAfterLeft > 0 || status[BackendStatic.PAYMENT_STATUS_NOT_YET])
            result = BackendStatic.PAYMENT_STATUS_NOT_YET;
        else if (status[BackendStatic.PAYMENT_STATUS_FAILED])
            result = BackendStatic.PAYMENT_STATUS_FAILED;
        else if (status[BackendStatic.PAYMENT_STATUS_UNKNOWN])
            result = BackendStatic.PAYMENT_STATUS_UNKNOWN;
        else if (status[BackendStatic.PAYMENT_STATUS_BILLING])
            result = BackendStatic.PAYMENT_STATUS_BILLING;
        else if (status[BackendStatic.PAYMENT_STATUS_AUTHORIZE])
            result = BackendStatic.PAYMENT_STATUS_AUTHORIZE;

        return result;
    }


    // -------------------------------------------------------------------------------------------------------------- //
    // アイテムのステータス
    // -------------------------------------------------------------------------------------------------------------- //
    static isOptionItem(item_entity)
    {
        return item_entity.reservation_type === BackendStatic.ITEM_RESERVATION_TYPE_OPTION /* 予約選択不可機材（オプション）*/ &&
            item_entity.display === BackendStatic.ITEM_DISPLAY_ON /* 表示ON */;
    }

    static isLentItem(item_entity)
    {
        return item_entity.reservation_type === BackendStatic.ITEM_RESERVATION_TYPE_ITEM /* 予約選択可機材*/ &&
            item_entity.display === BackendStatic.ITEM_DISPLAY_ON /* 表示ON */;
    }


    // -------------------------------------------------------------------------------------------------------------- //
    // イベントの操作
    // -------------------------------------------------------------------------------------------------------------- //

    static isTemporaryUserEntity(entity)
    {
        return String(entity.user_id).startsWith("_new_");
    }


    /**
     * 指定イベントにオプションを追加・追加済の場合は削除
     */
    static addOrRemoveAnotherReservation(event, itemEntity, amount, append)
    {
        if (append)
        {
            var item_lent = {
                item_lent_id: 1,
                amount: 0,
                lent_type: 1,
                lent_account_id: null,
                lent_datetime: null,
                lent_done: 0,
                lent_remarks: "",
                send_with_another: 0,
                name: "",
                postcode: "",
                prefecture: "",
                city: "",
                address1: "",
                address2: "",
                phone_number: ""
            };

            var item_return = {
                item_return_id: 1,
                return_type: 1,
                return_account_id: null,
                return_datetime: null,
                return_done: 0,
                return_remarks: "",
            };

            event.another_reservation.push({
                "id": "_new_" + this.generateId(),
                'item_id': itemEntity.item_id,
                'order_id': event.order.order_id,
                'is_main': 0,
                "reservation_type": itemEntity.reservation_type,
                "reservation_status": BackendStatic.RESERVATION_STATUS_APPROVED,
                "datetime_from": event.item_reservation.datetime_from,
                'datetime_to': event.item_reservation.datetime_to,
                "amount": amount,
                "price_summary": 0,
                "remark": "",
                "user_request": "",

                item_lent: item_lent,
                item_return: item_return
            });
        }
        else
        {
            let idx = -1;
            event.another_reservation.map((itemReservationEntity, index) =>
            {
                if (itemReservationEntity.item_id === itemEntity.item_id)
                {
                    idx = index;
                }
            });

            if (idx !== -1 /* another reservation に登録済 */)
            {
                event.another_reservation.splice(idx, 1);
            }
        }

        return event;
    }

    static convertEventToReservations(event)
    {
        let reservations = [];
        reservations.push(event.item_reservation);
        for (let another of event.another_reservation)
        {
            if (event.item_reservation.item_id !== another.item_id)
                reservations.push(another);
        }
        return reservations;
    }

    /**
     * 主予約または副予約に指定したアイテムIDのものがあれば取得
     */
    static findItemReservationsInEvent(event, item_id)
    {
        // ---------------------------------------------------------------------------------------------------------- //
        // 主予約
        // ---------------------------------------------------------------------------------------------------------- //
        if (event.item_reservation.item_id === item_id)
        {
            return [event.item_reservation];
        }

        // ---------------------------------------------------------------------------------------------------------- //
        // 副予約
        // ---------------------------------------------------------------------------------------------------------- //
        let subReservations = event.another_reservation.filter((itemReservationEntity) => itemReservationEntity.item_id === item_id);
        if (subReservations.length > 0)
        {
            return subReservations;
        }

        return [];
    }


    // -------------------------------------------------------------------------------------------------------------- //
    // 表示デコレーション系
    // -------------------------------------------------------------------------------------------------------------- //

    static renderPrice(price, message = "")
    {
        if (price)
        {
            if (price > 0)
            {
                return (
                    <p style={{color: "#FF0000"}}>¥{price.toLocaleString()} {message}</p>
                );
            }
            else
            {
                return (
                    <p style={{color: "#0000FF"}}>¥{price.toLocaleString()} {message}</p>
                );
            }
        }

        return (
            <p>-</p>
        );
    }


    // -------------------------------------------------------------------------------------------------------------- //
    // ソート
    // -------------------------------------------------------------------------------------------------------------- //

    static sortEventList(event_list)
    {
        var arranged = new Map();

        // 開始時間でソート
        event_list.sort((a, b) => a.start > b.start);

        event_list.forEach(function (event)
        {
            let key = moment(event.start).format("YYYYMMDD");

            if (!arranged.has(key))
                arranged.set(key, {"date": event.start, "list": []});

            arranged.get(key).list.push(event);
        });

        return arranged;
    }

    static sortItemPriceInverse(item_prices)
    {
        item_prices.sort((a, b) => b.priority - a.priority);
        return item_prices;
    }

    static sortItemPrice(item_prices)
    {
        item_prices.sort((a, b) => a.priority - b.priority);
        return item_prices;
    }

    static getCurrentLanguage(i18n)
    {
        return i18n.language || window.localStorage.i18nextLng || 'ja';
    }

    static findSelectedItemById(item_list, item_id)
    {
        for (let item of item_list)
        {
            if (item.item_id === item_id)
                return item;
        }

        return {
            item_id: null,
            item_name: "N/A",
            description: "N/A",
            thumbnails: [],
            item_prices: [],
        };
    }

    static findBillByOrderId(bill_list, order_id)
    {
        for (let billEntity of bill_list)
        {
            if (billEntity.order_id === order_id)
                return billEntity;
        }
        return null;
    }

    static findSelectedEvent(event_list, id)
    {
        for (let event of event_list)
        {
            if (event.item_reservation && event.item_reservation.id === id)
                return event;
        }
        return null;
    }

    static findAnotherReservations(this_event, from_event_list)
    {
        return from_event_list.filter((eventEntity) =>
            this_event.another_reservation.filter((anotherReservationEntity) =>
                eventEntity.item_reservation && eventEntity.item_reservation.id === anotherReservationEntity.id
            ).length > 0 // contains
        );
    }

    static findCouponById(coupon_id, from_counpon_list)
    {
        for (let couponEntity of from_counpon_list)
        {
            if (couponEntity.id === coupon_id)
                return couponEntity;
        }
        return null;
    }

    static filterEvent(event_list, item_list,
                       reservation_status,
                       reservation_type = 1, /* アイテムカテゴリー　０：オプション　１：アイテム */
                       item_type = undefined, /* アイテムタイプ　０：未定義　１：貸出資産（重複不可）　２：オプション（重複可能） */
                       display = undefined, /* アイテム表示非表示　０：非表示　１：表示 */
                       available = undefined, /* アイテム利用可不可　０：利用不可　１：利用可 */
                       warning = undefined /* アイテム予約フラグ */)
    {
        var list = [];

        for (let event of event_list)
        {
            // 新規登録イベントの場合
            if (!event.item_reservation)
                continue;

            let itemEntity = Toolkit.findSelectedItemById(item_list, event.item_reservation.item_id);
            let add = true;

            if (reservation_status !== undefined && event.item_reservation.reservation_status !== reservation_status)
                add = false;
            if (reservation_type !== undefined && itemEntity.reservation_type !== reservation_type)
                add = false;
            if (item_type !== undefined && itemEntity.item_type !== item_type)
                add = false;
            if (display !== undefined && itemEntity.display !== display)
                add = false;
            if (available !== undefined && itemEntity.available !== available)
                add = false;
            if (warning !== undefined && event.item_reservation.warning !== warning)
                add = false;

            if (add)
                list.push(event);
        }

        return list;
    }

    static countItemAndOption(selected_event, event_list, item_list)
    {
        var itemCount = 0;
        var optionCount = 0;

        selected_event.another_reservation.map((another_reservation) =>
        {
            var event = Toolkit.findSelectedEvent(event_list, another_reservation.item_reservation_id);
            if (event)
            {
                var item = Toolkit.findSelectedItemById(item_list, event.item_reservation.item_id);
                if (item)
                {
                    switch (item.item_type)
                    {
                        case 1: /* 貸し出し資産 */
                            if (item.reservation_type === 0) /* 予約選択不可 */
                                optionCount++;  // オプション扱い
                            else
                                itemCount++;
                            break;

                        case 2: /* オプション */
                            optionCount++;
                            break;
                    }
                }
            }
        });

        return {
            item: itemCount,
            option: optionCount
        }
    }

    /**
     * チェックリストのカウント
     * @param selected_id_list
     * @returns {number}
     */
    static countChecked(selected_id_list = {})
    {
        let count = 0;
        for (let bill_id in selected_id_list)
        {
            if (selected_id_list[bill_id])
                count++;
        }
        return count;
    }

    /**
     * ２つのイベントリストのマージ
     * @param event_list
     * @param add_event_list
     * @param remove_temp
     * @returns {*}
     */
    static mergeEvent(event_list, add_event_list, remove_temp = true)
    {
        let new_event_list = [];
        event_list.map((event, index) =>
        {
            // マージ時に一時イベントを掃除する
            if (!remove_temp || !event.id.toString().match(/_new_/))
                new_event_list.push(event)
        });

        add_event_list.map(add_event =>
        {
            let same_event_index = -1;
            new_event_list.map((event, index) =>
            {
                if (event.id === add_event.id)
                    same_event_index = index;

                // 別イベントの共通項目の同期
                if (event.order && add_event.order && event.order.id === add_event.order.id)
                {
                    event.another_reservation.map((another_reservation, idx) =>
                    {
                        if (another_reservation.id === add_event.item_reservation.id)
                            event.another_reservation[idx] = add_event.item_reservation;
                    });
                }

                if (event.user_information && add_event.user_information && event.user_information.user_id === add_event.user_information.user_id)
                    event.user_information = add_event.user_information;
            });

            if (same_event_index >= 0)
            {
                // イベントの上書き更新
                new_event_list[same_event_index] = add_event
            }
            else
            {
                new_event_list.push(add_event);
            }
        });

        return new_event_list;
    }

    static removeEvent(event_list, remove_event_id)
    {
        let new_event_list = [];
        for (let _event of event_list)
        {
            if (remove_event_id !== _event.id)
                new_event_list.push(_event);
        }
        return new_event_list;
    }

    /**
     * DBから取得したanother_reservationは参照が切れている為、必要な時に主予約のイベントの値で上書きする
     */
    static updateItemReservationsByOtherEvent(target_event, event_list)
    {
        target_event.another_reservation.map((another_reservation, index) =>
        {
            event_list.map((event) =>
            {
                if (event.id === another_reservation.id)
                    target_event.another_reservation[index] = event.item_reservation
            })
        });

        return target_event;
    }

    // -------------------------------------------------------------------------------------------------------------- //
    // 予約管理系
    // -------------------------------------------------------------------------------------------------------------- //

    // -------------------------------------------------------------------------------------------------------------- //
    // 表示制御・判定
    // -------------------------------------------------------------------------------------------------------------- //

    /**
     * イベント編集可能かどうか？
     * @param item_reservation
     * @returns {boolean}
     */
    static editEnabled(item_reservation)
    {
        return Toolkit.editEnabledStatus(item_reservation.reservation_status);
    }

    static editEnabledStatus(reservation_status)
    {
        return reservation_status === BackendStatic.RESERVATION_STATUS_NOT_APPROVED ||
            reservation_status === BackendStatic.RESERVATION_STATUS_APPROVED ||
            reservation_status === BackendStatic.RESERVATION_STATUS_WAIT_PAYMENT;
    }

    /**
     *
     * @param item_reservation
     * @returns {boolean}
     */
    static reserveAvailable(item_reservation)
    {
        return item_reservation.reservation_status === BackendStatic.RESERVATION_STATUS_DELETED ||
            item_reservation.reservation_status === BackendStatic.RESERVATION_STATUS_CANCELED;

    }

    // -------------------------------------------------------------------------------------------------------------- //
    // アイテム追加
    // -------------------------------------------------------------------------------------------------------------- //

    /**
     * 新規イベント
     * @param user_information
     * @param reserve_from
     * @param reserve_to
     * @returns {*}
     */
    static createNewEvent(reserve_from, reserve_to, user_information)
    {
        let order_id = "_new_" + this.generateId();
        let bill = {
            bill_id: "_new_" + this.generateId(),
            customer_id: 1,
            user_id: user_information ? user_information.user_id : -1,
            order_id: order_id,
            bill_date: moment().toDate(),
            name: "請求書",
            price: 0,
            tax: 0,
            payment_type: BackendStatic.PAYMENT_TYPE_UNKNOWN,
            payment_date: moment().toDate(),
            payment_status: BackendStatic.PAYMENT_STATUS_NOT_YET,
            paid_at: null,

            payment: []
        };

        let newOrder = {
            order_id: order_id,
            order_datetime: moment().toDate(),
            reservation_status: BackendStatic.RESERVATION_STATUS_APPROVED,
            reservation_extra: [],
            change_histories: [],
        };

        return {
            id: "_new_" + this.generateId(),
            title: "新規イベント",
            start: reserve_from,
            end: reserve_to,
            user_information: user_information,
            order: newOrder,
            item_reservation: null,
            bill: bill,
            another_reservation: []
        };
    }

    static createNewUserInformation()
    {
        return {
            "user_id": "_new_" + this.generateId(),
            "name_sei": "",
            "name_mei": "",
            "furigana_sei": "",
            "furigana_mei": "",
            "birthday": "",
            "postcode": "",
            "prefecture": "東京都",
            "city": "",
            "address1": "",
            "address2": "",
            "mail_address": "",
            "phone_number": "",
            "identification_status": 0,
            "id_content": 0,
            "id_content_uploaded_at": "",
            "id_memo": "",

            "memo": "",
            "coupon": []
        };
    }

    static addNewExtra(selected_event, extra, for_event_list)
    {
        let newReservationExtra = {
            id: "_new_" + this.generateId(),
            order_id: selected_event.order.order_id,
            user_id: selected_event.user_information.user_id,
            name: extra.name,
            price: extra.price,
            remark: extra.remark,
            coupon_id: extra.coupon_id,
            price_extra: extra.price_extra,
            price_extra_reason: extra.price_extra_reason,
        };

        // 同じ order_id を持つものに追加
        for_event_list
            .filter((eventEntity) => eventEntity.order && eventEntity.order.order_id === selected_event.order.order_id)
            .map((eventEntity) =>
            {
                eventEntity.order.reservation_extra.push(newReservationExtra);
            });

        return for_event_list;
    }

    static newPayment(payment)
    {
        let newPayment = {
            id: "_new_" + this.generateId(),
            bill_id: payment.bill_id,
            payment_type: payment.payment_type,
            payment_date: payment.payment_date,
            payment_status: payment.payment_status,
            price: payment.price,
            paid_price: payment.paid_price,
            paid_at: payment.paid_at,
        };

        return newPayment;
    }

    /**
     * 指定イベントにアイテムを追加する
     * @param selected_event 主イベント
     * @param reserve_from 予約開始日時
     * @param reserve_to 予約開始日時
     * @param selected_item 選択アイテム
     * @param for_event_list イベントリスト
     * @returns {*}
     */
    static addNewItem(selected_event, reserve_from, reserve_to, selected_item, for_event_list)
    {
        var item_lent = {
            item_lent_id: 1,
            amount: 0,
            lent_type: 1,
            lent_account_id: null,
            lent_datetime: null,
            lent_done: 0,
            lent_remarks: "",
            send_with_another: 0,
            name: "",
            postcode: "",
            prefecture: "",
            city: "",
            address1: "",
            address2: "",
            phone_number: ""
        };

        var item_return = {
            item_return_id: 1,
            return_type: 1,
            return_account_id: null,
            return_datetime: null,
            return_done: 0,
            return_remarks: "",
        };

        var newItemReservation = {
            "id": "_new_" + this.generateId(),
            'item_id': selected_item.item_id,
            'order_id': selected_event.order.order_id,
            "reservation_type": selected_item.reservation_type,
            "reservation_status": BackendStatic.RESERVATION_STATUS_APPROVED,
            "datetime_from": reserve_from,
            'datetime_to': reserve_to,
            "amount": 1,
            "price_summary": 0,
            "remark": "",

            item_lent: item_lent,
            item_return: item_return
        };

        // すでにアイテムがイベントに紐づいてる時 → 追加のイベント作成
        if (selected_event.item_reservation !== null)
        {
            // 同じ order_id を持つものに追加
            newItemReservation['is_main'] = 0;
            for_event_list
                .filter((eventEntity) => eventEntity.order && eventEntity.order.order_id === selected_event.order.order_id)
                .map((eventEntity) => eventEntity.another_reservation.push(newItemReservation));

            // 追加のイベント
            var newEvent = {
                'id': "_new_" + this.generateId(),
                'title': selected_item.item_name,
                'start': reserve_from,
                'end': reserve_to,

                'user_information': selected_event.user_information,
                'order': selected_event.order,
                'item_reservation': newItemReservation,

                'another_reservation': selected_event.another_reservation.slice()  // 配列のコピー
            };

            for_event_list.push(newEvent);
        }
        else
        {
            // item_reservationの設定
            newItemReservation['is_main'] = 1;
            for_event_list.map((eventEntity) =>
            {
                if (eventEntity.id === selected_event.id)
                {
                    eventEntity.title = selected_item.item_name;
                    eventEntity.start = reserve_from;
                    eventEntity.end = reserve_to;
                    eventEntity.item_reservation = newItemReservation;
                    eventEntity.another_reservation = [
                        // { id : newItemReservation.id }
                    ]
                }
            });
        }

        return for_event_list;
    }

    /**
     * 指定したアイテム予約のステータスを変更する
     * @param new_status
     * @param item_reservation
     * @param for_event_list
     */
    static changeItemReservationStatus(new_status, item_reservation, for_event_list)
    {
        for_event_list.filter((eventEntity) => eventEntity.item_reservation.id === item_reservation.id)
            .map((eventEntity) =>
            {
                eventEntity.item_reservation.reservation_status = new_status;
            });

        return for_event_list;
    }

    /**
     * アイテムの利用不可期間設定リストから、利用不可となる日付のDateリストを作成
     * @param available_term_list
     * @returns {Array}
     */
    static createDateListFromAvailableTerms(available_term_list)
    {
        let dateList = [];

        if (available_term_list !== undefined)
        {
            available_term_list.map((availableTermEntity, index) =>
            {
                switch (availableTermEntity.apply_type)
                {
                    case BackendStatic.SCHEDULE_FOR_DAY:
                        break;

                    case BackendStatic.SCHEDULE_FOR_DATE:
                        break;

                    case BackendStatic.SCHEDULE_FOR_RANGE:
                        let addDates = this.createDateList(availableTermEntity.apply_date_from, availableTermEntity.apply_date_to);
                        addDates.map((addDateEntity) => dateList.push(addDateEntity));
                        break;
                }
            });
        }
        return dateList;
    }

    static isMatchDisAvailableDate(date, available_term_list)
    {
        let available = false;
        let disavailable = false;
        let holiday = false;
        let rangeAndPartial = false;

        if (available_term_list)
            available_term_list.map((availableTermEntity, index) =>
            {
                let isApply = false;
                let targetDate = moment(date);
                if (availableTermEntity.apply_day)
                {
                    let applyDays = JSON.parse(availableTermEntity.apply_day);
                    isApply = applyDays.length === 0;
                    for (let day of applyDays)
                    {
                        if (Number(day) === 7)
                        {
                            isApply |= HolidayJp.isHoliday(date);
                        }
                        else
                        {
                            isApply |= targetDate.day() === Number(day);
                        }
                    }
                }

                if (isApply)
                {
                    switch (availableTermEntity.apply_type)
                    {
                        case BackendStatic.SCHEDULE_FOR_DAY:
                            if (availableTermEntity.available === BackendStatic.SCHEDULE_AVAILABLE)
                                available = true;
                            else if (availableTermEntity.available === BackendStatic.SCHEDULE_DISAVAILABLE)
                                disavailable = true;
                            else if (availableTermEntity.available === BackendStatic.SCHEDULE_HOLIDAY)
                                holiday = true;

                            break;

                        case BackendStatic.SCHEDULE_FOR_DATE:
                            let settingDate = moment(availableTermEntity.apply_date);
                            if (availableTermEntity.is_every_years)
                                settingDate.year(targetDate.year());

                            if (targetDate.isSame(settingDate, "day"))
                            {
                                if (availableTermEntity.available === BackendStatic.SCHEDULE_AVAILABLE)
                                    available = true;
                                else if (availableTermEntity.available === BackendStatic.SCHEDULE_DISAVAILABLE)
                                    disavailable = true;
                                else if (availableTermEntity.available === BackendStatic.SCHEDULE_HOLIDAY)
                                    holiday = true;
                            }
                            break;

                        case BackendStatic.SCHEDULE_FOR_RANGE:
                            let settingDateFrom = moment(availableTermEntity.apply_date_from);
                            let settingDateTo = moment(availableTermEntity.apply_date_to).endOf('day');
                            if (availableTermEntity.is_every_years)
                            {
                                settingDateFrom.year(targetDate.year());
                                settingDateTo.year(targetDate.year());
                            }

                            if (targetDate.isBetween(settingDateFrom, settingDateTo))
                            {
                                if (availableTermEntity.available === BackendStatic.SCHEDULE_AVAILABLE)
                                {
                                    available = true;
                                }
                                else if (availableTermEntity.available === BackendStatic.SCHEDULE_DISAVAILABLE)
                                {
                                    disavailable = true;
                                    // if (this.isTimePartial(settingDateFrom, targetDate) ||
                                    //     this.isTimePartial(settingDateTo, targetDate))
                                    // {
                                    //     rangeAndPartial = true;
                                    // }
                                }
                                else if (availableTermEntity.available === BackendStatic.SCHEDULE_HOLIDAY)
                                    holiday = true;
                            }
                            break;
                    }
                }
            });

        if (available)
            return SharekanStatic.CALENDAR_STATUS_CAN_RESERVE;

        if (disavailable)
            return SharekanStatic.CALENDAR_STATUS_DISABLE;
        else if (holiday)
            return SharekanStatic.CALENDAR_STATUS_HOLIDAY;
        else if (rangeAndPartial)
            return SharekanStatic.CALENDAR_STATUS_PARTIAL

        return SharekanStatic.CALENDAR_STATUS_CAN_RESERVE;
    }

    static isTimePartial(moment, momentBase)
    {
        return moment.isSame(momentBase, "day") /* 同一日 */ &&
            moment.hour() !== 0 &&
            moment.minute() !== 0; /* 0:00 設定 */
    }

    static getDateStartMoment(moment)
    {
        moment.hour(0);
        moment.minute(0);
        moment.second(0);
        return moment;
    }

    static getDateEndMoment(moment)
    {
        moment.hour(23);
        moment.minute(59);
        moment.second(59);
        return moment;
    }


    static createDateList(datetime_start, datetime_end)
    {
        let dateList = [];
        let startMoment = this.getDateStartMoment(moment(datetime_start));
        let endMoment = this.getDateEndMoment(moment(datetime_end).subtract(1, 's')); //0時までの予約を前日〆で計算するため1秒引く

        while (startMoment.isBefore(endMoment))
        {
            dateList.push(startMoment.toDate());

            startMoment.add(1, 'days');
        }

        return dateList;
    }

    static howManyHours(datetime_start, datetime_end)
    {
        if (datetime_start === null || datetime_end === null)
            return 0;

        let start = moment(datetime_start);
        let end = moment(datetime_end);

        return end.diff(start, "hours", true).toFixed(1);
    }

    static howManyDays(datetime_start, datetime_end)
    {
        if (datetime_start === null || datetime_end === null)
            return 0;

        let start = moment(datetime_start);
        let end = moment(datetime_end);

        return end.diff(start, "days", true).toFixed(1);
    }

    static isDisabledDate(date, available_term_list)
    {
        let disabledDates = Toolkit.createDateListFromAvailableTerms(available_term_list);
        return disabledDates.filter((disabledDate) => moment(disabledDate).isSame(moment(date), "day")).length > 0;
    }

    // -------------------------------------------------------------------------------------------------------------- //
    // チャート
    // -------------------------------------------------------------------------------------------------------------- //
    static createDateLabel(datetime_start, datetime_end)
    {
        let labelList = [];

        let dateList = this.createDateList(datetime_start, datetime_end);
        dateList.map((date) =>
        {
            labelList.push(
                moment(date).format(i18n.t("date_format"))
            );
        });
        return labelList;
    }


    // -------------------------------------------------------------------------------------------------------------- //
    // 抽出系
    // -------------------------------------------------------------------------------------------------------------- //

    /**
     * 指定アイテムIDの予約イベントをすべて抽出
     * @param event_list
     * @param item_entity
     * @returns {*}
     */
    static findEventsHasItem(event_list, item_entity)
    {
        return event_list.filter((eventEntity) => eventEntity.item_reservation !== null && eventEntity.item_reservation.item_id === item_entity.item_id);
    }

    // -------------------------------------------------------------------------------------------------------------- //
    // イベント開始・終了時間取得（item_reservation未登録の場合は event.start/event.end から取得
    // -------------------------------------------------------------------------------------------------------------- //

    static getEventDatetimeFrom(eventEntity)
    {
        if (eventEntity.item_reservation === null)
            return eventEntity.start;
        return eventEntity.item_reservation.datetime_from;
    }

    static getEventDatetimeTo(eventEntity)
    {
        if (eventEntity.item_reservation === null)
            return eventEntity.end;
        return eventEntity.item_reservation.datetime_to;
    }


    /**
     * 渡したイベントリストから、指定アイテムの予約をさがし、指定イベントと被っているものがあればすべて抽出
     * @param item_entity
     * @param this_event
     * @param from_event_list
     * @returns {*}
     */
    static isBooked(item_entity, this_event,
                    show_items_from, show_items_to,
                    from_event_list)
    {

        return this.findEventsHasItem(from_event_list, item_entity)
            .filter((eventEntity) =>
                eventEntity.id !== this_event.id &&
                eventEntity.item_reservation &&
                eventEntity.item_reservation.reservation_status < BackendStatic.RESERVATION_STATUS_DONE &&
                (
                    moment(this.getEventDatetimeFrom(eventEntity)).isBetween(show_items_from, show_items_to) ||
                    moment(this.getEventDatetimeTo(eventEntity)).subtract(1, 'second').isBetween(show_items_from, show_items_to) ||
                    (
                        moment(this.getEventDatetimeFrom(eventEntity)).isBefore(show_items_from) &&
                        moment(this.getEventDatetimeTo(eventEntity)).subtract(1, 'second').isAfter(show_items_to)
                    )
                )
            );
    }

    /**
     * target_eventによって予約済であったイベントが、どう予約済であったのか？
     * @param range_from
     * @param range_to
     * @param target_event
     * @returns {number}
     */
    static bookedType(range_from, range_to,
                      target_event)
    {
        if (moment(target_event.item_reservation.datetime_from).isBetween(range_from, range_to) &&
            moment(target_event.item_reservation.datetime_to).isBetween(range_from, range_to))
            return BOOKED_TYPE_INCLUDED; // 前後のぞいて予約済　「datetime_fromまでか、datetime_to以降に利用可能です。」
        else if (moment(target_event.item_reservation.datetime_from).isBetween(range_from, range_to))
            return BOOKED_TYPE_AFTER_START; // datetime_from から利用されている「datetime_fromまで利用可能です。」
        else if (moment(target_event.item_reservation.datetime_to).isBetween(range_from, range_to))
            return BOOKED_TYPE_BEFORE_END; // datetime_to まで利用されている 「datetime_to以降に利用可能です。」
        else if (moment(target_event.item_reservation.datetime_from).isBefore(range_from) &&
            moment(target_event.item_reservation.datetime_to).isAfter(range_to))
            return BOOKED_TYPE_ALL; // 利用期間すべて予約済

        return 0;
    }

    static isIn(a_from, a_to, b_from, b_to)
    {
        if (moment(b_from).isBetween(a_from, a_to) &&
            moment(b_to).isBetween(a_from, a_to))
            return BOOKED_TYPE_INCLUDED; // 前後のぞいて予約済　「datetime_fromまでか、datetime_to以降に利用可能です。」
        else if (moment(b_from).isBetween(a_from, a_to))
            return BOOKED_TYPE_AFTER_START; // datetime_from から利用されている「datetime_fromまで利用可能です。」
        else if (moment(b_to).isBetween(a_from, a_to))
            return BOOKED_TYPE_BEFORE_END; // datetime_to まで利用されている 「datetime_to以降に利用可能です。」
        else if (moment(b_from).isBefore(a_from) &&
            moment(b_to).isAfter(a_to))
            return BOOKED_TYPE_ALL; // 利用期間すべて予約済

        return 0;
    }

    /**
     * 利用不可の理由チェック
     * @param item_entity
     * @param this_event
     * @param show_items_from
     * @param show_items_to
     * @param from_event_list
     * @returns {{message: string, status: number}|{booked_events: *, message: string, status: number}}
     */
    static unavailableBecause(item_entity, this_event,
                              show_items_from, show_items_to,
                              from_event_list)
    {

        // ---------------------------------------------------------------------------------------------------------- //
        // 利用設定不可
        // ---------------------------------------------------------------------------------------------------------- //
        if (item_entity.available === 0)
            return {status: 1, message: "利用不可設定のアイテムです。"};

        // ---------------------------------------------------------------------------------------------------------- //
        // 期間内に他のイベントで予約中
        // ---------------------------------------------------------------------------------------------------------- //
        let booked_events = this.isBooked(item_entity, this_event, show_items_from, show_items_to, from_event_list);
        if (booked_events.length > 0)
            return {status: 4, booked_events: booked_events, message: "指定期間内に予約済みのアイテムです。"};

        // ---------------------------------------------------------------------------------------------------------- //
        // このイベントで予約中
        // ---------------------------------------------------------------------------------------------------------- //
        if (this_event.item_reservation !== null &&
            this_event.item_reservation.item_id === item_entity.item_id && this_event.item_reservation.reservation_status)
            return {status: 2, message: "予約中です。"};

        // ---------------------------------------------------------------------------------------------------------- //
        // another events で予約中
        // ---------------------------------------------------------------------------------------------------------- //
        let another_events = this.findAnotherReservations(this_event, from_event_list);
        let booked_another_events = this.findEventsHasItem(another_events, item_entity);
        if (booked_another_events.length > 0)
            return {status: 3, booked_events: booked_another_events, message: "予約中です。"};

        return {status: 0, message: "利用可能です。"};
    }

    // -------------------------------------------------------------------------------------------------------------- //
    // 価格テーブル表示
    // -------------------------------------------------------------------------------------------------------------- //

    static itemColor(itemEntity)
    {
        if (!itemEntity.color)
        {
            return null
        }

        return colors[itemEntity.color];
    }

    static colors()
    {
        let colorList = [];
        for (let colorName in colors)
            colorList.push(colors[colorName]);

        return colorList;
    }

    static priorityColor(priority)
    {
        let index = priority;
        if (index > priority_table.length - 1)
            index = priority_table.length - 1;

        return {
            color: priority_table[index]
        }
    }

    static priorityColorTable()
    {
        return priority_table;
    }

    static priorities()
    {
        let priorities = [];
        priority_table.map((entity, index) => priorities.push(index));
        return priorities;
    }

    /**
     * 指定日の適用価格を取得
     * @param date
     * @param item_prices
     * @returns {*}
     */
    static findAppliedPrice(date, item_prices)
    {
        var appliedPrice = null;

        let priorityPrices = this.sortItemPriceInverse(item_prices);   // 優先度低い方からチェック
        priorityPrices.map((itemPriceEntity) =>
        {
            // 適用開始日指定
            let isApply = !itemPriceEntity.enable_from ||
                (itemPriceEntity.enable_from && moment(date).isSameOrAfter(itemPriceEntity.enable_from, "day"));

            let targetDate = moment(date);
            if (itemPriceEntity.apply_day)
            {
                let applyDays = JSON.parse(itemPriceEntity.apply_day);
                let _isApply = applyDays.length === 0;
                for (let day of applyDays)
                {
                    if (Number(day) === 7)
                    {
                        _isApply |= HolidayJp.isHoliday(date);
                    }
                    else
                    {
                        _isApply |= targetDate.day() === Number(day);
                    }
                }
                isApply &= _isApply;
            }

            if (isApply)
            {
                switch (itemPriceEntity.apply_type)
                {
                    case BackendStatic.PRICE_APPLY_TYPE_FOR_ALL: // 基本料金
                    case BackendStatic.PRICE_APPLY_TYPE_FOR_DAY: // 曜日
                        appliedPrice = itemPriceEntity;
                        break;

                    case BackendStatic.PRICE_APPLY_TYPE_FOR_DATE: // 特定日
                        let settingDate = moment(itemPriceEntity.apply_date);
                        if (itemPriceEntity.is_every_years)
                            settingDate.year(targetDate.year());
                        if (targetDate.isSame(settingDate, 'day'))
                            appliedPrice = itemPriceEntity;
                        break;

                    case BackendStatic.PRICE_APPLY_TYPE_FOR_RANGE: // 日付範囲
                        let settingDateFrom = moment(itemPriceEntity.apply_date_from);
                        let settingDateTo = moment(itemPriceEntity.apply_date_to).endOf('day');
                        if (itemPriceEntity.is_every_years)
                        {
                            settingDateFrom.year(targetDate.year());
                            settingDateTo.year(targetDate.year());
                        }

                        if (targetDate.isBetween(settingDateFrom, settingDateTo, null, '[]'))
                            appliedPrice = itemPriceEntity;
                        break;
                }
            }
        });

        return appliedPrice;
    }

    /**
     * 指定予約期間を日付で区切る
     * @param start_datetime
     * @param end_datetime
     * @returns {[]}
     */
    static splitDays(start_datetime, end_datetime)
    {
        let split_list = [];

        let start = moment(start_datetime);
        let end = moment(end_datetime);

        if (start.day() !== end.day())  // 開始と終了が異なる日の場合
        {
            while (start.day() !== end.day())
            {
                split_list.push({start: start.toDate(), end: start.endOf("day").toDate()});

                start = start.add(1, "day").startOf("day");
            }

            split_list.push({start: start.toDate(), end: end.toDate()});
        }
        else
        {
            split_list.push({start: start.toDate(), end: end.toDate()});
        }

        console.log("split_list-----------------");
        console.log(split_list);

        return split_list;
    }

    static latest_location_path = "";
    static force_location_path = "";

    static equalLatestPath(path)
    {
        return this.latest_location_path === path;
    }

    static replaceHistory(path)
    {
        this.latest_location_path = path;
        history.replaceState(null, null, path);
    }

    static pushHistory(path)
    {
        if (!path)
            path = BackendStatic.BACKEND_VIEW_HOME;
        if (this.latest_location_path === path)
            return "";
        if (this.force_location_path)
            path = this.force_location_path;
        if (path.match(/sign_out/))
            location.href = path;
        this.latest_location_path = path;
        history.pushState(null, null, path);
        this.force_location_path = "";
        this.viewPathGoogleAnalytics(path);
        return path;
    }

    static returnPage(state)
    {
        let return_page = Toolkit.pushHistory(state.return_page);
        switch (return_page)
        {
            case BackendStatic.BACKEND_VIEW_MESSAGE:
                return {
                    show_view: BackendStatic.BACKEND_VIEW_MESSAGE,
                    open_left_list: true,
                };
            case BackendStatic.BACKEND_VIEW_EDIT_USER:
                if (state.selected_event && state.selected_user)
                {
                    return {
                        show_view: BackendStatic.BACKEND_VIEW_EDIT_USER,
                        selected_user: state.selected_user,
                        return_page: BackendStatic.BACKEND_VIEW_USERS,
                    };
                }
                break;
            case BackendStatic.BACKEND_VIEW_EDIT_EVENT:
                if (state.selected_event && state.selected_user)
                {
                    return {
                        show_view: BackendStatic.BACKEND_VIEW_EDIT_EVENT,
                        selected_event: state.selected_event,
                        selected_user: state.selected_user,
                        return_page: BackendStatic.BACKEND_VIEW_RESERVATIONS,
                    };
                }
                break;
            case BackendStatic.BACKEND_VIEW_ROOT:
            case BackendStatic.BACKEND_VIEW_HOME:
            case null:
            case "":
                return {
                    show_view: null,
                    open_left_list: true,
                };
            default:
                return {
                    show_view: return_page,
                };
        }
        state.return_page = return_page;
        return Toolkit.returnPage(state);
    }

    static urlQuery()
    {
        var vars = {};
        var param = location.search.substring(1).split('&');
        for (var i = 0; i < param.length; i++)
        {
            var keySearch = param[i].search(/=/);
            var key = '';
            if (keySearch != -1) key = param[i].slice(0, keySearch);
            var val = param[i].slice(param[i].indexOf('=', 0) + 1);
            if (key != '') vars[key] = decodeURI(val);
        }
        return vars;
    }

    /**
     * カスタマ設定関係
     */
    static saveConfig(type, value, text, onSuccess = null, onError = null)
    {
        Toolkit.request(this,
            '/backend/api/edit_customer_config',
            {
                config_type: type,
                config_value: value,
                config_text: text,
            },
            (_configs) =>
            {
                // success
                console.log(_configs);
                if (onSuccess)
                    onSuccess(_configs);
            },
            (error) =>
            {
                console.log(error);
                if (onError)
                    onError(error);
            }
        )

    }

    static checkAccountPrivilege(account, type, value)
    {
        for (let _privilege of account.privileges)
        {
            if (_privilege.privilege_type === type)
                return _privilege.privilege >= value;
        }
        return false
    }


    /**
     * @deprecated
     * setStateForComponent の state に指定した設定値を取得してセットする
     */
    static loadConfig(component, configTypeList = [], onSuccess = null, onError = null)
    {
        if (!component.state.loaded_customer_configs)
        {
            component.setState({loaded_customer_configs: true});
            Toolkit.fetch(
                component,
                BackendStatic.API_GET_CUSTOMER_CONFIG_LIST,
                {
                    config_type: configTypeList
                },
                (res) =>
                {
                    // array -> map
                    let customer_config = component.state.customer_config ? component.state.customer_config : {};
                    res.customer_config.map((customerConfigEntity) =>
                    {
                        customer_config[customerConfigEntity.config_type] = customerConfigEntity;
                    });

                    component.setState({
                        customer_config: customer_config
                    });

                    if (onSuccess)
                        onSuccess(res);
                },
                (error) =>
                {
                    if (onError)
                        onError(error);
                }
            );
        }
    }

    static getConfigText(component, config_type)
    {
        let configEntity = Toolkit.getConfig(component, config_type);

        if (configEntity !== null)
            return configEntity.config_text;

        return '';
    }

    static getConfigValue(component, config_type)
    {
        let configEntity = Toolkit.getConfig(component, config_type);

        if (configEntity !== null)
            return configEntity.config_value;

        return '';
    }

    static isConfigAvailable(component, config_type)
    {
        let configEntity = Toolkit.getConfig(component, config_type);

        if (configEntity !== null)
            return configEntity.config_value === BackendStatic.CUSTOMER_CONFIG_VALUE_AVAILABLE;

        return false;
    }

    static getConfig(component, config_type)
    {
        if (component && component.state && component.state.customer_config && component.state.customer_config[config_type])
            return component.state.customer_config[config_type];
        else
        {
            if (component.props.customer_information && component.props.customer_information.config)
            {
                for (let _config of component.props.customer_information.config)
                    if (_config.config_type === config_type)
                        return _config;
            }

            if (!component.state.customer_config || !component.state.customer_config[config_type])
            {
                if (component.isMounted)
                    this.loadConfig(component, [config_type]);
            }
        }
        return null;
    }

    static createNewItem(successCallback)
    {
        Toolkit.fetch(this,
            BackendStatic.API_EDIT_ITEM,
            {
                item: {
                    display_item_id: null,
                    reservation_type: 1,
                    item_type: 1,
                    item_name: "新しいアイテム",
                    description: null,
                    available_num: 1,
                    display: 0,
                    available: 0,
                    color: "#f44336",
                    parent_item_id: null
                }
            },
            res =>
            {
                successCallback(res.item);
            }
        );
    }

    // -------------------------------------------------------------------------------------------------------------- //
    // API
    // -------------------------------------------------------------------------------------------------------------- //
    static fetch(component, api, param, successCallback = null, errorCallback = null)
    {
        console.log("------------------------------------------------------------------------------------------------");
        console.log("[API] start fetch ... " + api);
        console.log(param);
        console.log("------------------------------------------------------------------------------------------------");
        let is_backend = false;
        let path_split = location.pathname.split('/');
        if (path_split.length > 1)
            is_backend = path_split[1] === 'backend';

        switch (api)
        {
            case BackendStatic.API_GET_ORDER_INQUIRY:
            {
                let url = is_backend ? '/backend/api/get_inquiries' : '/frontend/api/get_inquiries';
                this.request(
                    component,
                    url,
                    param,
                    _res =>
                    {
                        successCallback({
                            order_inquiry_list: _res.inquiries,
                        });
                    },
                    errorCallback
                );
            }
                break;

            case BackendStatic.API_GET_CUSTOMER_CONFIG_LIST:
            {
                let url = is_backend ? '/backend/api/get_customer_configs' : '/frontend/api/get_customer_configs';
                this.request(
                    component,
                    url,
                    param,
                    _res =>
                    {
                        successCallback({
                            customer_config: _res.customer_configs,
                        });
                    },
                    errorCallback
                );
            }
                break;
            case BackendStatic.API_GET_ITEM_LIST:
            {
                let url = is_backend ? '/backend/api/get_items' : '/frontend/api/get_items';
                this.request(
                    component,
                    url,
                    param,
                    _res =>
                    {
                        successCallback({
                            item_list: _res.items,
                            total_list_size: _res.total
                        });
                    },
                    errorCallback
                );
            }
                break;
            case BackendStatic.API_GET_ACCOUNT_LIST:
                this.request(
                    component,
                    '/backend/api/get_accounts',
                    param,
                    _res =>
                    {
                        successCallback({
                            account_list: _res.accounts,
                        });
                    },
                    errorCallback
                );
                break;

            case BackendStatic.API_GET_CALENDAR_STATUS:
                this.request(
                    component,
                    '/api/get_calendar_status',
                    param,
                    _res =>
                    {
                        successCallback({
                            reservation_status_result: _res.status,
                            suspension_events: _res.suspension_events,
                        });
                    },
                    errorCallback
                );
                break;

            case BackendStatic.API_GET_KPI:
                this.request(
                    component,
                    '/backend/api/get_kpi',
                    param,
                    _res =>
                    {
                        successCallback({
                            data_list: _res,
                        });
                    },
                    errorCallback
                );
                break;

            case BackendStatic.API_GET_NOTIFICATION_LIST:
                this.request(
                    component,
                    '/backend/api/get_notifications',
                    param,
                    _res =>
                    {
                        successCallback({
                            notification_list: _res.notifications,
                            total_list_size: _res.total, //ないこともある
                        });
                    },
                    errorCallback
                );
                break;

            case BackendStatic.API_GET_MESSAGE_CHANNEL_LIST:
                this.request(
                    component,
                    '/backend/api/get_message_channels',
                    param,
                    _res =>
                    {
                        successCallback({
                            message_channel_list: _res.message_channels,
                        });
                    },
                    errorCallback
                );
                break;

            case BackendStatic.API_SEND_MESSAGE:
                let send = () =>
                {
                    let url = '/backend/api/send_message';
                    if (param['sender_type'] === BackendStatic.MAIL_FROM_USER)
                        url = '/frontend/api/send_message';

                    this.request(
                        component,
                        url,
                        {
                            user_id: param['user_id'],
                        },
                        _res =>
                        {
                            let message_channel = _res.message_channel;
                            API.graphql(graphqlOperation(createMessage, {
                                input: {
                                    customer_id: message_channel['customer_id'],
                                    channel: message_channel['channel_key'],
                                    sender_type: param['sender_type'],
                                    sender_id: _res.sender_id,
                                    body: param['body'],
                                    message_type: param['message_type'],
                                    image_base64: param['image_base64'],
                                    send_at: moment(new Date(), 'YYYY-MM-DD hh:mm:ss'),
                                }
                            }));
                            if (successCallback)
                                successCallback(_res);
                        },
                        errorCallback
                    );

                };

                if (param['image'])
                {
                    // 画像は圧縮する
                    const THUMBNAIL_WIDTH = 330; // 画像リサイズ後の横の長さの最大値
                    const THUMBNAIL_HEIGHT = 330; // 画像リサイズ後の縦の長さの最大値

                    let image = new Image();
                    image.onload = function ()
                    {
                        let width, height;
                        if (image.width > image.height)
                        {
                            let ratio = image.height / image.width;
                            width = THUMBNAIL_WIDTH;
                            height = THUMBNAIL_WIDTH * ratio;
                        }
                        else
                        {
                            let ratio = image.width / image.height;
                            width = THUMBNAIL_HEIGHT * ratio;
                            height = THUMBNAIL_HEIGHT;
                        }
                        let canvas = document.createElement("canvas")
                        canvas.setAttribute('width', width)
                        canvas.setAttribute('height', height);
                        let ctx = canvas.getContext('2d');
                        ctx.clearRect(0, 0, width, height);
                        ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, width, height);
                        param['image_base64'] = canvas.toDataURL('image/jpeg');

                        send();
                    };
                    image.src = param['image'];
                }
                else
                {
                    send();
                }
                break;

            case BackendStatic.API_GET_MESSAGE:
                let _messageList = [];
                API.graphql(graphqlOperation(messagesByChannelSortedByCreatedAt,
                    {
                        channel: param['channel'],
                        sortDirection: "DESC",
                        limit: 20,
                        nextToken: param['nextToken']
                    }
                )).then(res =>
                {
                    console.log("[API_GET_MESSAGE] DONE!");
                    console.log(res.data);

                    res.data.messagesByChannelSortedByCreatedAt.items.forEach((message) =>
                    {
                        _messageList.push({
                            id: message.id,
                            user_id: message.sender_id,
                            reply: message.sender_type,
                            message_type: message.message_type,
                            flag: false,
                            read: false,
                            content: message.body,
                            media: message.image_base64,
                            created_at: message.createdAt,
                            updated_at: ""
                        })
                    });
                    successCallback({
                        message_list: _messageList,
                        nextToken: res.data.messagesByChannelSortedByCreatedAt.nextToken,
                    });
                }).catch(e =>
                {
                    console.error(e);
                    errorCallback(e);
                });
                break;

            case BackendStatic.API_GET_USER_LIST:
                this.request(
                    component,
                    '/backend/api/get_users',
                    param,
                    _res =>
                    {
                        successCallback({
                            user_list: _res.users,
                            total_list_size: _res.total
                        });
                    },
                    errorCallback
                );
                break;

            case BackendStatic.API_GET_EVENT_LIST:
                this.request(
                    component,
                    '/backend/api/get_events',
                    param,
                    _res =>
                    {
                        successCallback({
                            event_list: _res.events,
                            total_list_size: _res.total,
                            count: _res.count,
                            account: _res.account,
                            customer_information: _res.customer_information,
                            paypal_client_id: _res.paypal_client_id,
                        });
                    },
                    errorCallback
                );
                break;

            case BackendStatic.API_GET_CUSTOMIZE_EVENT_LIST:
                this.request(
                    component,
                    '/backend/api/get_customized_events',
                    param,
                    _res =>
                    {
                        successCallback({
                            customized_event_list: _res.customized_events,
                        });
                    },
                    errorCallback
                );
                break;

            case BackendStatic.API_UPDATE_RESERVATION_STATUS:
                this.request(
                    component,
                    '/backend/api/update_reservation_status',
                    param,
                    successCallback,
                    errorCallback
                );
                break;

            case BackendStatic.API_GET_CHANGE_HISTORY:
                this.request(
                    component,
                    '/backend/api/get_change_histories',
                    param,
                    _change_history_list =>
                    {
                        successCallback({
                            change_history_list: _change_history_list,
                        });
                    },
                    errorCallback
                );
                break;

            case BackendStatic.API_GET_PAYMENT_LIST:
                this.request(
                    component,
                    '/backend/api/get_payments',
                    param,
                    _res =>
                    {
                        successCallback({
                            payment_list: _res.payments,
                            total_list_size: _res.total
                        });
                    },
                    errorCallback
                );
                break;

            case BackendStatic.API_GET_BILL_LIST:
                this.request(
                    component,
                    '/backend/api/get_bills',
                    param,
                    _res =>
                    {
                        successCallback({
                            bill_list: _res.bills,
                            total_list_size: _res.total
                        });
                    },
                    errorCallback
                );
                break;

            case BackendStatic.API_UPDATE_PAYMENT_STATUS:
                this.request(
                    component,
                    '/backend/api/update_payment_status',
                    param,
                    successCallback,
                    errorCallback
                );
                break;

            case BackendStatic.API_GET_COUPON_LIST:
                this.request(
                    component,
                    '/backend/api/get_coupons',
                    param,
                    _res =>
                    {
                        successCallback({
                            coupon_list: _res.coupons,
                            total_list_size: _res.total
                        });
                    },
                    errorCallback
                );
                break;

            case BackendStatic.API_EDIT_EVENT:
                // オブジェクトを削除
                if (param.event.eventTitle)
                    param.event.eventTitle = undefined;

                console.log("event : " + JSON.stringify(param));

                this.request(
                    component,
                    '/backend/api/edit_event',
                    param,
                    _res =>
                    {
                        if (_res.events.length > 0)
                        {
                            let variables = {
                                input: {
                                    id: _res.events[0].order.id,
                                    order_id: _res.events[0].order.id,
                                    customer_id: _res.events[0].order.customer_id,
                                    edit_account_id: _res.account.id,
                                }
                            };

                            if (param.event.id.toString().match(/_new_/))
                                API.graphql(graphqlOperation(createReservation, variables));
                            else
                                API.graphql(graphqlOperation(updateReservation, variables));
                        }

                        successCallback(_res)
                    },
                    errorCallback
                );
                break;

            case BackendStatic.API_EDIT_ITEM:
                this.request(
                    component,
                    '/backend/api/edit_item',
                    param,
                    successCallback,
                    errorCallback
                );
                break;

            case BackendStatic.API_EDIT_USER_INFORMATION:
                this.request(
                    component,
                    '/backend/api/edit_user_information',
                    param,
                    successCallback,
                    errorCallback
                );
                break;

            case BackendStatic.API_EDIT_COUPON:
                this.request(
                    component,
                    '/backend/api/edit_coupon',
                    param,
                    successCallback,
                    errorCallback
                );
                break;

            case BackendStatic.API_GRANT_USER_COUPON:
                this.request(
                    component,
                    '/backend/api/grant_user_coupon',
                    param,
                    successCallback,
                    errorCallback
                );
                break;

            case BackendStatic.API_DROP_USER_COUPON:
                this.request(
                    component,
                    '/backend/api/drop_user_coupon',
                    param,
                    successCallback,
                    errorCallback
                );
                break;

            case FrontendStatic.API_GET_USER_EVENT_LIST:
                this.request(
                    component,
                    '/frontend/api/get_events',
                    param,
                    _res =>
                    {
                        successCallback({
                            event_list: _res.events,
                            total_list_size: _res.total,
                            count: _res.count,
                            account: _res.account,
                            customer_information: _res.customer_information,
                        });
                    },
                    errorCallback
                );
                break;

            case FrontendStatic.API_GET_MY_INFORMATION:
                this.request(
                    component,
                    '/frontend/api/get_my_information',
                    param,
                    _res =>
                    {
                        successCallback({
                            customer_information: _res.customer_information,
                            user_information: _res.user_information,
                            message_channel: _res.message_channel,
                            paypal_client_id: _res.paypal_client_id,
                        });
                    },
                    errorCallback
                );
                break;

            case FrontendStatic.API_GET_CUSTOMER_INFORMATION:
                this.request(
                    component,
                    '/frontend/api/get_customer',
                    param,
                    _res =>
                    {
                        successCallback({
                            customer_information: _res.customer,
                        });
                    },
                    errorCallback
                );
                break;

            case FrontendStatic.API_APPLY_EVENT:
                this.request(
                    component,
                    '/frontend/api/apply_event',
                    param,
                    _res =>
                    {
                        API.graphql(graphqlOperation(createReservation, {
                            input: {
                                id: _res.events[0].order.id,
                                order_id: _res.events[0].order.id,
                                customer_id: _res.events[0].order.customer_id,
                            }
                        }));
                        successCallback(_res)
                    },
                    errorCallback
                );
                break;

            case FrontendStatic.API_CHECK_COUPON:
                this.request(
                    component,
                    '/frontend/api/check_coupon',
                    param,
                    _res =>
                    {
                        successCallback({
                            available: _res.available,
                            coupon: _res.coupon,
                            user_coupons: _res.user_coupons,
                        });
                    },
                    errorCallback
                );
                break;
        }
    }

    static request(component, url, params, successCallback = null, errorCallback = null)
    {
        console.log(url);

        if (component.setState) component.setState({loading: true});
        Axios.post(url, this.deepCopy(params))
            .then((response) =>
            {
                if (component.setState) component.setState({loading: false});
                if (successCallback) successCallback(response.data);
            })
            .catch((e) =>
            {
                if (component.setState) component.setState({loading: false});

                if (e.response && e.response.data)
                {
                    console.error('url=' + url + '\nerror : ' + e.response.data);
                    if (e.response.status === 440)
                    {
                        location.reload(true);
                        ErrorMessageProtocol.next('ログインセッションが切れました');
                        return;
                    }

                    if (errorCallback) errorCallback(e.response.data);
                    if (e.response.status === 400) ErrorMessageProtocol.next(e.response.data);
                    else ErrorMessageProtocol.next('エラーが発生しました');
                }
                else
                {
                    console.error(e);
                    if (e.message === 'Network Error')
                    {
                        ErrorMessageProtocol.next('現在オフラインです。');
                    }
                    else
                    {
                        if (errorCallback) errorCallback('エラーが発生しました');
                        else ErrorMessageProtocol.next('エラーが発生しました');
                    }
                }
            });
    }

    static noticeUpdateReservation(order_id, customer_id, account_id = -1)
    {
        API.graphql(
            graphqlOperation(
                updateReservation,
                {
                    input: {
                        id: order_id,
                        order_id: order_id,
                        customer_id: customer_id,
                        edit_account_id: account_id,
                    }
                }
            )
        );
    }

    static getZipAddress(zipcode, successCallback)
    {
        Axios.get('https://api.zipaddress.net/?', {params: {zipcode: zipcode}})
            .then(_res =>
            {
                console.log(_res);
                if (_res.data.code >= 400)
                {
                    successCallback({
                        pref: '存在しません',
                        city: '存在しません',
                        town: '存在しません',
                    });
                }
                else
                {
                    successCallback(_res.data.data);
                }
            })
            .catch(e =>
            {
                successCallback({
                    pref: '取得に失敗しました',
                    city: '取得に失敗しました',
                    town: '取得に失敗しました',
                });
            });
    }

    static notification(message)
    {
        let options = {
            body: message,
            icon: '/images/sharekan5.png',
            // vibrate: [200, 100, 200, 100, 200, 100, 200],
            // tag: 'vibration-sample'
        };

        try
        {
            if (Notification.permission === "granted")
                new Notification('シェアカン', options);
            else
                Notification.requestPermission(function (permission)
                {
                    if (permission === "granted")
                        new Notification('シェアカン', options);
                });
        }
        catch (e)
        {
            console.log(e);
        }
    }

    static getStorageContent(params_str, callback)
    {
        console.log('【getStorageContent】 ' + params_str);

        if (!params_str)
            return callback("");

        let params = params_str;
        if (typeof params_str === 'string')
            try
            {
                params = JSON.parse(params_str);
            }
            catch (e)
            {
                params = {};
            }
        let key = params.key;
        // delete params.key;
        Storage.get(key, params)
            .then(result =>
            {
                console.log(result);
                callback(result);
            })
            .catch(err => console.log(err));
    }


    /**
     * Google Analytics Tracking
     */
    static isInitializedGoogleAnalytics = false;

    static initializeGoogleAnalytics(trackingId)
    {
        if (trackingId)
        {
            ReactGA.initialize(trackingId);
            this.isInitializedGoogleAnalytics = true;
        }
        else
        {
            this.isInitializedGoogleAnalytics = false;
        }
    }

    static viewPathGoogleAnalytics(path)
    {
        if (this.isInitializedGoogleAnalytics)
        {
            ReactGA.set({page: path});
            ReactGA.pageview(path);
        }
    }

    static conversionGoogleAnalytics(send_to)
    {
        if (this.isInitializedGoogleAnalytics && send_to)
        {
            ReactGA.ga('event', 'conversion', {'send_to': send_to});
        }
    }
}